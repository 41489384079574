<template>
    <Head :title="`Service - ${service.id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="data.mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ service.id }}</span>
        </nav>
    </Teleport>

    <!-- Service Details Page Wrapper -->
    <div class="mx-auto max-w-screen-2xl text-base lg:text-lg pb-12">

        <div class="relative flex justify-between items-center my-2 z-30">
            <inertia-link :href="route('services.index')" class="inline-flex items-center gap-2 text-base font-normal text-gray-500 hover:text-gray-700 hover:gap-3 transition-all duration-200">
                <icon name="long-arrow-left" class="size-4 fill-current"/>
                Back to Services
            </inertia-link>

            <div class="flex flex-row items-center justify-between w-auto sm:shrink-0 gap-6">
                <div v-if="data.otherUsers.length > 0" class="flex -space-x-2 flex-grow sm:flex-grow-0">
                    <template v-for="user in data.otherUsers" :key="user.id">
                        <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.initials">
                        <span v-else class="inline-flex items-center justify-center size-10 rounded-full bg-gray-400 mr-2">
                            <span class="text-lg font-medium leading-none text-white uppercase">{{ user.initials }}</span>
                        </span>
                    </template>
                </div>

                <div class="relative inline-block text-left">
                    <div>
                        <button type="button" @click.prevent="data.editMenuActive = !data.editMenuActive" class="relative inline-flex items-center gap-x-2 rounded-md bg-white shadow-sm px-4 py-2 text-base font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 transition-all duration-200 hover:bg-gray-50 focus:z-10" id="menu-button" aria-expanded="true" aria-haspopup="true">
                            <span>Actions</span>
                            <icon :name="data.editMenuActive ? 'chevron-up' : 'chevron-down'" class="-mr-1 size-4 text-gray-400 fill-current" />
                        </button>
                    </div>

                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <div v-show="data.editMenuActive" class="absolute left-auto right-0 z-10 mt-2 w-56 sm:w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y" role="menu" aria-orientation="vertical" aria-labelledby="edit-menu-button" tabindex="-1">
                            <div class="py-1" role="none">
                                <inertia-link :href="route('services.edit', [service.id])" class="block px-6 py-4 sm:px-4 sm:py-2 text-base text-gray-700 hover:text-gray-900 hover:bg-gray-100" role="menuitem" tabindex="-1" id="edit-menu-item-0">
                                    Edit Service
                                </inertia-link>
                            </div>
                            <div class="py-1" role="none">
                                <inertia-link :href="route('incidents.create', {service_id: service.id})" class="block px-6 py-4 sm:px-4 sm:py-2 text-base text-gray-700 hover:text-gray-900 hover:bg-gray-100" role="menuitem" tabindex="-1" id="edit-menu-item-0">
                                    File Incident
                                </inertia-link>
                                <inertia-link :href="route('services.work-orders.create', [service.id])" class="block px-6 py-4 sm:px-4 sm:py-2 text-base text-gray-700 hover:text-gray-900 hover:bg-gray-100" role="menuitem" tabindex="-1" id="edit-menu-item-1">
                                    Open Work Order
                                </inertia-link>
                                <inertia-link
                                    :href="route('pickups.create', {
                                        service_id: service.id,
                                        location_id: service.location_id,
                                        material_type: service.bin.material_type,
                                        disposal_method: service.bin.disposal_method,
                                        container_size: `${service.bin.quantity * service.bin.size} ${service.bin.size_metric}`
                                    })"
                                    class="block px-6 py-4 sm:px-4 sm:py-2 text-base text-gray-700 hover:text-gray-900 hover:bg-gray-100"
                                    role="menuitem"
                                    tabindex="-1"
                                    id="edit-menu-item-2"
                                >
                                    Record Pickup
                                </inertia-link>
                            </div>
                            <div v-if="service.deleted_at === null" class="py-1" role="none">
                                <a @click.prevent="deleteService" class="block cursor-pointer px-6 py-4 sm:px-4 sm:py-2 text-base text-red-700 hover:text-red-900 hover:bg-red-50" role="menuitem" tabindex="-1" id="edit-menu-item-3">
                                    Delete Service
                                </a>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <div class="relative w-full rounded-t-lg overflow-hidden">
            <g-map-map :options="data.mapOptions" :center="mapCenter" style="width: 100%; height: 15rem">
                <g-map-marker
                    :key="data.currentServiceSnapshot.location.id"
                    :position="{ lat: parseFloat(data.currentServiceSnapshot.location?.address?.latitude), lng: parseFloat(data.currentServiceSnapshot.location?.address?.longitude) }"
                    :draggable="false"
                />
            </g-map-map>
            <div class="absolute inset-0 z-10 bg-gradient-to-b from-transparent from-30% to-70% to-white"></div>
            <div class="absolute top-0 left-0 z-20">
                <a :href="googleMapsLocationUrl" target="_blank" class="p-2 bg-d-gray-800 text-white text-base rounded-br-lg opacity-50 hover:opacity-100">
                    View Map
                </a>
            </div>
        </div>

        <!-- Service Header -->
        <div class="relative -mt-24 z-10 px-2 md:px-4 flex flex-col items-center gap-6 sm:justify-between sm:flex-row">
            <div class="flex items-center">
                <div class="flex flex-col gap-2">
                    <div class="flex items-baseline gap-3 ">
                        <h1 class="text-3xl font-bold text-gray-900">
                            <span>{{ service.id }}</span>
                        </h1>
                        <div class="self-center inline-flex items-center rounded-md px-2 py-0.5 text-base font-medium ring-1 ring-inset" :class="serviceStatusClasses">
                            {{ data.currentServiceSnapshot.status }}
                        </div>
                        <div v-if="service.deleted_at !== null" class="text-2xl font-bold text-red-700">
                            (Deleted)
                        </div>
                        <div v-if="service.external_reference_id" class="text-xl font-semi-bold text-d-orange-400">
                            {{ service.external_reference_id }}
                        </div>
                    </div>

                    <div class="text-lg text-d-blue-700">
                        <p v-if="!hasRequiredCustomDescriptionFields(data.currentServiceSnapshot)">{{ data.currentServiceSnapshot.description }}</p>
                        <div v-else class="flex flex-col gap-1">
                            <div>
                                ({{ data.currentServiceSnapshot.bin.quantity }}) {{ data.currentServiceSnapshot.bin.size }}
                                {{ formatMetric(data.currentServiceSnapshot.bin.size_metric, data.currentServiceSnapshot.bin.quantity) }}
                                {{ data.currentServiceSnapshot.bin.type }} {{ data.currentServiceSnapshot.bin.material_type }}
                            </div>
                            <div>
                                <span class="text-gray-400">picked up</span> {{ pickupFrequency() }}
                                <span class="text-gray-400">by</span> {{ data.currentServiceSnapshot.vendor.name }}
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col gap-2 my-2 xl:my-0 xl:flex-row xl:flex-wrap xl:gap-6">
                        <inertia-link class="link flex items-center gap-2 text-base" :href="route('locations.show', [data.currentServiceSnapshot.location.id])">
                            <icon name="building" class="size-4 fill-gray-600" />
                            {{ data.currentServiceSnapshot.location.name }}
                        </inertia-link>
                        <inertia-link class="link flex items-center gap-2 text-base" :href="googleMapsLocationUrl">
                            <icon name="map-marker-alt" class="size-4 fill-gray-600" />
                            {{ data.currentServiceSnapshot.location.address.city }}, {{ data.currentServiceSnapshot.location.address.state_code }}
                        </inertia-link>
                        <div class="flex items-center gap-2 text-base">
                            <icon name="clock" class="size-4 fill-gray-600" />
                            <span>{{ serviceDates() }}</span>
                        </div>
                        <!-- <div class="flex items-center gap-2 text-base text-gray-600">
                            <icon name="dumpster" class="size-4 fill-current" />
                            <span>
                                {{ data.currentServiceSnapshot?.service_account_type ?? '' }} {{ data.currentServiceSnapshot?.service_schedule_type ?? '' }} {{ props.service.serviceType?.name ?? data.currentServiceSnapshot.type }}
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- Service Alerts & Notifications -->
        <div v-if="isRebuildingHistory" class="rounded-md bg-blue-100 p-4 my-4 ring-1 ring-blue-200">
            <div class="flex items-center">
                <div class="shrink-0">
                    <icon name="info-circle" class="fill-current size-5 text-blue-700" />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-base text-blue-700">
                        This service is currently rebuilding its history and current state. Please wait until the rebuild is finished before making any changes.
                    </p>
                </div>
            </div>
        </div>

        <!-- Service Subnav -->
        <div class="mt-4">
            <div class="px-2 md:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500" @change="mobileNavChange($event)">
                    <option v-for="tab in availableTabs" :value="tab.key" :key="tab.key" :selected="data.currentTab === tab.key">
                        {{ tab.name }}
                    </option>
                </select>
            </div>
            <div class="hidden md:block">
                <div class="border-b border-gray-300">
                    <nav class="-mb-px flex space-x-1" aria-label="Tabs">
                        <a v-for="tab in availableTabs" @click="switchTab(tab)" :key="tab.key" :aria-current="data.currentTab === tab.key" class="whitespace-nowrap text-sm border-b-[3px] px-4 py-3 xl:text-base xl:px-6 xl:py-4 font-medium cursor-pointer" :class="tabClasses(tab.key)">
                            {{ tab.name }}
                            <span v-if="tab.badge" class="ml-1 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset" :class="tabBadgeClasses(tab.key)">{{ tab.badge }}</span>
                        </a>
                    </nav>
                </div>
            </div>
        </div>

        <!-- Service Details Wrapper -->
        <div class="mt-8 mx-auto grid grid-cols-1 gap-12 xl:grid-flow-col-dense xl:grid-cols-3">
            <!-- Left Column -->
            <article class="order-last space-y-6 xl:col-start-1 xl:col-span-2">
                <!-- Compactor Monitor Info -->
                <section v-if="compactorMonitor && data.currentTab === 'overview'" class="mb-6">
                    <div class="overflow-hidden bg-blue-200/10 sm:rounded-lg shadow p-2">
                        <div class="grid grid-cols-7 gap-6">
                            <div class="flex justify-center items-center">
                                <div class="inline-flex items-center gap-2">
                                    <div :class="[pioneerConnectivity[compactorMonitor.connectivity_status], 'flex-none rounded-full p-1']">
                                        <div class="h-3 w-3 rounded-full bg-current"/>
                                    </div>
                                    <logo name="pioneer-full-single-dark" class="h-8" />
                                </div>
                            </div>

                            <div class="col-span-2 inline-flex flex-col justify-center items-start leading-6 text-gray-900">
                                <span class="font-medium text-base text-gray-900/60">Monitor ID</span>
                                <inertia-link class="link inline-flex items-center gap-1" :href="route('compactor-monitors.show', compactorMonitor.id)">
                                    {{ compactorMonitor.id }} <icon v-if="data.pioneerNeedsAttention" name="exclamation-triangle" class="text-rose-400 fill-current size-4 inline"/>
                                </inertia-link>
                            </div>

                            <div class="col-span-2 inline-flex flex-col justify-center items-start leading-6 text-gray-900">
                                <div class="text-base font-medium text-gray-900/60">
                                    <template
                                        v-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'scheduled'">
                                        Scheduled Pickup
                                    </template>

                                    <template
                                        v-else-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'requested'">
                                        Requested Pickup
                                    </template>

                                    <template v-else>
                                        Forecasted Pickup
                                    </template>
                                </div>
                                <div class="text-gray-900" :content="predictedPickupHelpText" v-tippy>
                                    <template
                                        v-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'scheduled'"
                                        class="mt-1 bg-d-cyan-100 text-xl leading-9 font-semibold text-green-500">
                                        {{ upcomingPickupDateFormatted }}
                                    </template>

                                    <template
                                        v-else-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'requested'"
                                        class="mt-1 bg-d-cyan-100 text-xl leading-9 font-semibold text-yellow-500">
                                        {{ upcomingPickupDateFormatted }}
                                    </template>

                                    <template v-else-if="predictedPickupDateFormatted"
                                              class="mt-1 bg-d-cyan-100 text-xl font-semibold" >
                                        <div class="flex flex-col">
                                            {{ predictedPickupDateFormatted }}
                                        </div>
                                    </template>

                                    <template v-else class="mt-1 bg-d-cyan-100 text-xl leading-9 font-semibold">
                                        Estimate unavailable
                                    </template>
                                </div>
                            </div>

                            <div class="col-span-2 inline-flex flex-col justify-center items-start leading-6 text-gray-900">
                                <div class="text-base font-medium text-gray-900/60">
                                    Last Pickup
                                </div>
                                <div class="text-gray-900">
                                    <div class="grid">
                                        <inertia-link v-if="lastPickupWorkOrder" :href="route('work-orders.show', lastPickupWorkOrder.id)" class="font-semibold link">
                                            {{ formattedLastPickup }}
                                        </inertia-link>
                                        <span v-if="daysSinceLastPickup" class="text-sm text-gray-500">
                                            ({{ daysSinceLastPickup }} days ago)
                                        </span>
                                    </div>
                                    <template >
                                        No pickups recorded
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="p-6 mt-6">
                            <div class="flex items-center gap-x-12">
                                <div class="shrink-0">
                                    <div class="text-base font-medium text-gray-900/60">Current Fullness</div>
                                    <div>
                                        <div v-if="compactorMonitor.monitorConfiguration.percentage_full" class="mt-1 text-gray-900">
                                            <span class="inline-block bg-d-cyan-100 rounded py-1 px-2 font-semibold">{{ compactorMonitor.monitorConfiguration.percentage_full }}%</span>
                                            <span class="text-base ml-1 font-normal text-gray-900/70">({{ fullnessInTons }} tons <i>est.</i>)</span>
                                        </div>

                                        <div v-else class="mt-1 text-gray-900">
                                            N/A
                                        </div>
                                    </div>
                                </div>

                                <div v-if="compactorMonitor.monitorConfiguration.percentage_full && compactorMonitor.monitorConfiguration.tonnage_capacity" class="w-full">
                                    <div class="relative h-10 w-full bg-gray-200 rounded flex items-center">
                                        <div :style="`left:${compactorMonitor.monitorConfiguration.target_percentage_full}%`" class="w-1 h-full bg-d-orange-500 absolute items-center">
                                            <div class="w-px text-xs h-3 absolute bottom-12 right-10 transform -translate-y-1/2" content="If Predictive Pickup Scheduling is enabled, AutoPilot will aim for pickups to occur at the target" v-tippy>
                                                <span class="border-2 p-1 whitespace-nowrap">Target {{ compactorMonitor.monitorConfiguration.target_percentage_full }}%</span>
                                            </div>
                                        </div>
                                        <!-- tick for 25% -->
                                        <div style="left: 25%" class="w-px h-full bg-d-gray-600 absolute">
                                            <div class="w-px h-3 absolute text-xs text-d-gray-700 top-12 right-3 transform -translate-y-1/2">25%</div>
                                        </div>
                                        <!-- tick for 50% -->
                                        <div style="left: 50%" class="w-px h-full bg-d-gray-600 absolute">
                                            <div class="w-px h-3 absolute text-xs text-d-gray-700 top-12 right-3 transform -translate-y-1/2">50%</div>
                                        </div>
                                        <!-- tick for 75% -->
                                        <div style="left: 75%" class="w-px h-full bg-d-gray-600 absolute">
                                            <div class="w-px h-3 absolute text-xs top-12 text-d-gray-700 right-3 transform -translate-y-1/2">75%</div>
                                        </div>
                                        <div :style="`width:${compactorMonitor.monitorConfiguration.percentage_full}%`" class="h-full bg-d-cyan-500 rounded transition-all duration-5000 ease-in"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="border-t border-gray-900/10 p-6 mt-6">
                            <div class="grid grid-cols-2 text-base">
                                <div class="flex flex-col gap-1 justify-center items-center">
                                    <div>Predictive Pickup Scheduling:</div>
                                    <div class="flex items-center gap-1" :class="compactorMonitor.monitorConfiguration.create_work_orders ? 'text-green-600' : 'text-red-600'">
                                        <icon v-if="compactorMonitor.monitorConfiguration.create_work_orders" name="check" class="size-4 fill-current" />
                                        <icon v-else name="times" class="size-4 fill-current" />
                                        {{ compactorMonitor.monitorConfiguration.create_work_orders ? 'Enabled' : 'Disabled' }}
                                    </div>
                                </div>
                                <div class="flex flex-col gap-1 justify-center items-center">
                                    <div>Preventative Maintenance:</div>
                                    <div class="flex items-center gap-1" :class="compactorMonitor.monitorConfiguration.create_preventative_maintenance_work_orders ? 'text-green-600' : 'text-red-600'">
                                        <icon v-if="compactorMonitor.monitorConfiguration.create_preventative_maintenance_work_orders" name="check" class="size-4 fill-current" />
                                        <icon v-else name="times" class="size-4 fill-current" />
                                        {{ compactorMonitor.monitorConfiguration.create_preventative_maintenance_work_orders ? 'Enabled' : 'Disabled' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <!-- Service Overview -->
                <section v-if="data.currentTab === 'overview'">
                    <div class="px-4">
                        <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2">
                            <span>Service Information</span>
                        </div>

                        <dl class="flex flex-col divide-y divide-gray-100 mt-4">
                            <div class="grid grid-cols-6 px-3 py-4 group hover:bg-gray-50">
                                <dt class="col-span-2 font-normal leading-6 text-gray-500">Location</dt>
                                <dd class="col-span-4 leading-6 text-gray-700">
                                    <div class="flex items-center justify-between">
                                        <div class="flex flex-col gap-1">
                                            <inertia-link class="link" :href="route('locations.show', [data.currentServiceSnapshot.location.id])">
                                                {{ data.currentServiceSnapshot.location.name }}
                                            </inertia-link>

                                            <span class="block text-base text-gray-700">{{ service.location?.address?.full_address }}</span>
                                        </div>

                                        <div class="shrink-0 space-x-2">
                                            <button class="text-gray-500 rounded-md px-2 py-1 font-semibold border bg-white border-gray-200 hover:text-gray-900 hover:border-gray-700" ref="clickToCopy" @click="copyToClipboard(service.location?.address?.full_address)">
                                                <icon name="copy" class="size-4 fill-current" />
                                            </button>
                                            <button class="text-gray-600 rounded-md px-2 py-1 font-semibold border bg-white border-gray-200 hover:text-gray-900 hover:border-gray-700" ref="clickToMap" @click="window.open(googleMapsLocationUrl, '_blank')">
                                                <icon name="map-marker-alt" class="size-4 fill-current" />
                                            </button>
                                        </div>
                                    </div>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 px-3 py-4 group hover:bg-gray-50">
                                <dt class="col-span-2 font-normal leading-6 text-gray-500">Type</dt>
                                <div class="col-span-4 flex items-center justify-between">
                                    <dd class="leading-6 text-gray-900">
                                        {{ data.currentServiceSnapshot?.service_account_type ?? '' }} {{ data.currentServiceSnapshot?.service_schedule_type ?? '' }} {{ props.service.serviceType?.name ?? data.currentServiceSnapshot.type }}
                                    </dd>
                                </div>
                            </div>

                            <div class="grid grid-cols-6 px-3 py-4 group hover:bg-gray-50">
                                <dt class="col-span-2 font-normal leading-6 text-gray-500">Effective Date</dt>
                                <dd class="col-span-4 leading-6 text-gray-900">
                                    <div class="flex gap-1">
                                        <span>{{ $filters.format_date(data.currentServiceSnapshot.effective_date) }}</span>
                                        <span v-if="data.currentServiceSnapshot.termination_date">- {{ $filters.format_date(data.currentServiceSnapshot.termination_date) }}</span>
                                    </div>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 px-3 py-4 group hover:bg-gray-50">
                                <dt class="col-span-2 font-normal leading-6 text-gray-500">Material Type</dt>
                                <dd class="col-span-4 leading-6 text-gray-900">{{ data.currentServiceSnapshot?.bin?.material_type ?? '-' }}</dd>
                            </div>

                            <div class="grid grid-cols-6 px-3 py-4 group hover:bg-gray-50">
                                <dt class="col-span-2 font-normal leading-6 text-gray-500">Disposal Method</dt>
                                <dd class="col-span-4 leading-6 text-gray-900">{{ data.currentServiceSnapshot?.bin?.disposal_method ?? '-' }}</dd>
                            </div>

                            <div class="grid grid-cols-6 px-3 py-4 group hover:bg-gray-50" v-if="compactorMonitor?.monitoredService && compactorMonitor?.monitored_service_id !== service.id">
                                <dt class="col-span-2 font-normal leading-6 text-gray-500">Monitored Service</dt>
                                <dd class="col-span-4 leading-6 text-gray-700">
                                    <div class="flex items-center justify-between">
                                        <div class="flex flex-col gap-1">
                                            <inertia-link class="link" :href="route('services.show', [compactorMonitor.monitored_service_id])">
                                                {{ compactorMonitor.monitored_service_id }}
                                            </inertia-link>

                                            <span class="block text-base text-gray-700">{{ compactorMonitor.monitoredService.description }}</span>
                                        </div>
                                    </div>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 px-3 py-4 group hover:bg-gray-50" v-if="data.currentServiceSnapshot?.bin">
                                <dt class="col-span-2 font-normal leading-6 text-gray-500">Equipment</dt>
                                <dd class="flex flex-col gap-4 col-span-4 leading-6 text-gray-900">
                                    <div class="w-full" v-if="data.currentServiceSnapshot?.label">
                                        <div class="col-span-full" >
                                            {{ data.currentServiceSnapshot?.label }}
                                        </div>
                                    </div>

                                    <fieldset>
                                        <legend class="block w-full">
                                            <button class="w-full bg-gray-100 font-semibold text-d-blue-400 py-3 flex items-center justify-center gap-x-2" :class="data.collapsed ? 'rounded-lg' : 'rounded-t-lg'" @click="toggleAdvancedOptions">
                                                {{ data.collapsed ? 'Show' : 'Hide' }} All
                                                <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="data.collapsed ? 'chevron-right' : 'chevron-down'" />
                                            </button>
                                        </legend>

                                        <div class="overflow-hidden rounded-b-lg bg-gray-100 border-t" v-if="!data.collapsed">
                                            <div class="px-4 py-5 sm:p-6">
                                                <div class="grid gap-x-4 gap-y-6 grid-cols-2">
                                                    <div class="col-span-1" v-for="[key, value] in filteredBinData">
                                                        <div class="leading-normal font-medium text-gray-700">
                                                            {{ filters.snakeCaseToTitleCase(key) }}
                                                        </div>
                                                        <div class="text-base leading-normal text-gray-700">
                                                            {{ value }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </dd>
                            </div>

                            <!-- <div class="grid grid-cols-6 px-3 py-4 group hover:bg-gray-50">
                                <dt class="col-span-2 font-normal leading-6 text-gray-500">Equipment Accessories</dt>
                                <dd class="col-span-4 leading-6 text-gray-900">{{ data.currentServiceSnapshot?.label ?? '-' }}</dd>
                            </div> -->
                        </dl>
                    </div>
                </section>

                <!-- Documents -->
                <section v-if="data.currentTab === 'documents'">
                    <div class="px-4">
                        <!-- Service Documents -->
                        <div class="pt-8 sm:col-span-2">
                            <div class="font-medium leading-6 text-gray-900">
                                <input type="file" ref="filePendingDocuments" class="hidden" @change="addPendingFileUploads" multiple />
                                <span class="flex items-center justify-between bg-gray-100 rounded-md px-4 py-2">
                                        <span class="inline-flex gap-2 items-baseline">
                                            <span>Documents</span>
                                            <span class="text-gray-500 font-normal text-base">({{service.documents.length}})</span>
                                        </span>
                                        <span v-if="service.documents.length || data.pendingFileUploads.length">
                                            <button type="button" @click.prevent="filePendingDocuments.click()" class="btn btn-gray btn-sm">
                                                <icon name="plus" class="size-5 fill-current" />
                                            </button>
                                        </span>
                                    </span>
                            </div>
                            <div class="px-4 mt-3 text-gray-900">
                                <div v-if="!service.documents.length && !data.pendingFileUploads.length">
                                    <button  @click.prevent="filePendingDocuments.click()" type="button" class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-d-orange-500 focus:ring-offset-2">
                                        <icon name="file-alt" class="mx-auto size-12 text-gray-400/80 fill-current" />
                                        <span class="mt-2 block text-base font-medium text-gray-800">Click to upload a new document</span>
                                        <span class="mt-1 block text-sm text-gray-600">Supports most files up to 10MB</span>
                                    </button>
                                </div>
                                <ul v-else role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200 overflow-hidden">
                                    <li v-for="(upload, index) in data.pendingFileUploads" :key="index" class="flex flex-col gap-8 py-4 pl-4 pr-5 leading-6 bg-gray-50">
                                        <div class="flex flex-1 items-center space-x-4">
                                            <svg class="size-5 shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clip-rule="evenodd"/>
                                            </svg>
                                            <div class="flex flex-col space-y-1">
                                                <div class="flex min-w-0 flex-1 gap-2">
                                                    <span class="truncate font-medium text-gray-700">{{ upload.file.name }}</span>
                                                    <span class="shrink-0 text-base text-gray-400">
                                                        {{ filters.format_file_size(upload.file.size) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex items-start justify-between gap-12">
                                            <div class="flex flex-col gap-4">
                                                <div class="shrink-0">
                                                    <label :for="`document-category-${index}`" class="block text-sm font-medium leading-6 text-gray-700 shrink-0">Document Type</label>
                                                    <select
                                                        :id="`document-category-${index}`"
                                                        name="document-category[]"
                                                        class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-d-orange-600 sm:text-sm sm:leading-6"
                                                    >
                                                        <option value="" disabled selected>Please select...</option>
                                                        <option value="LOA">LOA</option>
                                                        <option value="COI">COI</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="flex-grow">
                                                <label :for="`document-notes-${index}`" class="block text-sm font-medium leading-6 text-gray-700 shrink-0">Notes</label>
                                                <textarea
                                                    :id="`document-notes-${index}`"
                                                    name="document-notes[]"
                                                    rows="2"
                                                    placeholder="Add a note about this document..."
                                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-d-orange-400 sm:text-sm sm:leading-6"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div class="flex items-center justify-between">
                                            <div class="shrink-0 text-base text-green-500">
                                                Ready for upload...
                                            </div>
                                            <div class="flex items-center gap-4">
                                                <div v-if="errors?.pendingFileUpload?.file" class="text-rose-500">
                                                    <icon name="times-circle" class="size-4 fill-current" /> An error occurred.
                                                </div>
                                                <template v-if="!upload.uploading">
                                                    <button type="button" @click.prevent="storePendingFileUpload(upload)" class="rounded-md font-medium text-d-blue-500 hover:text-d-orange-500">Upload Now</button>
                                                    <span class="text-gray-200" aria-hidden="true">|</span>
                                                    <button type="button" @click.prevent="removePendingFileUpload(upload)" class="rounded-md font-medium text-d-accent-600 hover:text-d-accent-700">Remove</button>
                                                </template>
                                                <template v-else>
                                                    <icon name="spinner" class="inline fill-current text-gray-400 w-5 h-5 animate-spin"/>
                                                </template>
                                            </div>
                                        </div>
                                    </li>

                                    <li v-for="document in service.documents" :key="document.id" class="flex items-center justify-between py-4 pl-4 pr-5 leading-6">
                                        <div class="flex flex-1 items-center space-x-4">
                                            <svg class="size-5 shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clip-rule="evenodd"/>
                                            </svg>
                                            <div class="flex flex-col space-y-1">
                                                <div class="flex min-w-0 flex-1 gap-2">
                                                    <a class="link truncate font-medium" target="_blank" :href="route('documents.preview', { path: document.file_path })" v-if="document.file_path">{{ document.file_name }}</a>
                                                    <span v-else class="truncate font-medium">{{ document.file_name }}</span>
                                                    <span v-if="document.file_size !== null" class="shrink-0 text-base text-gray-400">{{ filters.format_file_size(document.file_size) }}</span>
                                                </div>
                                                <div class="">
                                                    <span class="shrink-0 text-base text-gray-400">{{filters.format_date_time(document.created_at)}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ml-4 flex items-center shrink-0 divide-x">
                                            <a href="#" class="font-medium text-d-blue-600 hover:text-green-500 py-1 px-4">
                                                <icon name="download-arrow" class="size-5 fill-current" />
                                            </a>
                                            <button type="button" @click.prevent="removeDocument(document)" class="appearance-none font-medium text-gray-400 hover:text-red-500 py-1 px-4">
                                                <icon name="times" class="size-5 fill-current" />
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <!-- Billing Details -->
                <section v-if="data.currentTab === 'billing'" class="space-y-4">
                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2">
                        <span>Vendor</span>
                    </div>

                    <div class="flex flex-col sm:flex-row sm:items-start gap-8 px-4">
                        <div class="sm:w-1/3 flex flex-col items-center text-center gap-3 text-gray-900">
                            <div class="relative overflow-hidden inline-flex items-center justify-center size-16 rounded-full shadow">
                                <img v-if="data.useLogos" :src="`https://img.logo.dev/wm.com?size=45&token=pk_C9E8Q3CzT3CPVVaZv0vMvQ`" alt="img" class="object-none">
                                <img v-else :src="`https://placehold.co/128x128/075076/white?font=roboto&text=${data.currentServiceSnapshot.vendor.name?.substring(0,2)}`" alt="img" class="size-16">
                            </div>
                            <div>
                                <inertia-link class="link" :href="route('vendors.show', [data.currentServiceSnapshot.vendor_id])">
                                    {{ data.currentServiceSnapshot.vendor.name ?? '' }}
                                </inertia-link>
                            </div>
                            <div class="px-4">
                                <span class="text-base text-gray-500">{{ data.currentServiceSnapshot.vendor?.legal_mailing_address ?? data.currentServiceSnapshot.vendor?.physical_address?.full_address ?? '' }}</span>
                            </div>
                        </div>

                        <dl class="sm:w-2/3 flex flex-col divide-y divide-gray-100">
                            <div class="grid grid-cols-6 gap-4 py-3">
                                <dt class="col-span-2 text-gray-500">Account</dt>
                                <dd class="col-span-4 text-gray-900">
                                    <inertia-link v-if="data.currentServiceSnapshot?.vendorAccount?.account_number" class="link" :href="route('vendor-accounts.show', [data.currentServiceSnapshot.vendor_account_id])">
                                        {{ data.currentServiceSnapshot.vendorAccount.account_number }}
                                    </inertia-link>
                                    <span v-else>None</span>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 gap-4 py-3">
                                <dt class="col-span-2 text-gray-500">Contract</dt>
                                <dd class="col-span-4 text-gray-900">
                                    <inertia-link v-if="data.currentServiceSnapshot.vendor_contract_id" class="link" :href="route('vendor-contracts.show', [data.currentServiceSnapshot.vendor_contract_id])">
                                        {{ data.currentServiceSnapshot.vendorContract.display_id }}
                                    </inertia-link>
                                    <span v-else-if="!data.currentServiceSnapshot.vendor_contract_id && data.currentServiceSnapshot.has_vendor_contract">
                                        Missing vendor contract
                                    </span>
                                    <span v-else>
                                        No vendor contract
                                    </span>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 gap-4 py-3">
                                <dt class="col-span-2 text-gray-500">Equipment Manufacturer</dt>
                                <dd class="col-span-4 text-gray-900">
                                    <span v-if="data.currentServiceSnapshot.equipment_manufacturer">{{ data.currentServiceSnapshot.equipment_manufacturer }}</span>
                                    <span v-else>Unknown</span>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 gap-4 py-3">
                                <dt class="col-span-2 text-gray-500">Flat Charge</dt>
                                <dd class="col-span-4 text-gray-900">
                                    <span v-if="data.currentServiceSnapshot.vendor_base_charge">{{ filters.format_money(data.currentServiceSnapshot.vendor_base_charge) }}</span>
                                    <span v-else>-</span>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 gap-4 py-3">
                                <dt class="col-span-2 text-gray-500">Per Unit Charge</dt>
                                <dd class="col-span-4 text-gray-900">
                                    <span v-if="data.currentServiceSnapshot.vendor_per_unit_charge">{{ filters.format_money(data.currentServiceSnapshot.vendor_per_unit_charge) }}</span>
                                    <span v-else>-</span>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 gap-4 py-3">
                                <dt class="col-span-2 text-gray-500">Per Occurrence Charge</dt>
                                <dd class="col-span-4 text-gray-900">
                                    <span v-if="data.currentServiceSnapshot.vendor_per_occurrence_charge">{{ filters.format_money(data.currentServiceSnapshot.vendor_per_occurrence_charge) }}</span>
                                    <span v-else>-</span>
                                </dd>
                            </div>
                        </dl>
                    </div>

                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2">
                        <span>Client</span>
                    </div>

                    <div class="flex flex-col sm:flex-row sm:items-start gap-8 px-4">
                        <div v-if="data.currentServiceSnapshot?.location?.clientCompany?.name" class="sm:w-1/3 flex flex-col items-center text-center gap-3 text-gray-900">
                            <span class="relative overflow-hidden inline-flex items-center justify-center size-16 rounded-full shadow">
                                <img v-if="data.useLogos" :src="`https://img.logo.dev/livcor.com?size=45&token=pk_C9E8Q3CzT3CPVVaZv0vMvQ`" alt="img" class="object-none">
                                <img v-else :src="`https://placehold.co/128x128/075076/white?font=roboto&text=${data.currentServiceSnapshot?.location?.clientCompany?.name?.substring(0,2)}`" alt="img" class="size-16">
                            </span>
                            <inertia-link class="link" :href="route('client-companies.show', [data.currentServiceSnapshot?.location?.client_company_id])">
                                {{ data.currentServiceSnapshot?.location?.clientCompany?.name }}
                            </inertia-link>
                            <div class="flex flex-col gap-2">
                                <div class="text-base text-gray-500">{{ data.currentServiceSnapshot?.location?.clientCompany?.number_of_active_services ?? '' }} active services</div>
                                <div class="text-base text-gray-500">{{ data.currentServiceSnapshot?.location?.clientCompany?.number_of_active_locations ?? '' }} active locations</div>
                                <div class="text-base text-gray-500">{{ data.currentServiceSnapshot?.location?.clientCompany?.number_of_open_incidents ?? '' }} open incidents</div>
                            </div>
                        </div>
                        <div v-else class="sm:w-1/3 flex flex-col items-center text-center gap-3 text-gray-900">
                            <span class="relative overflow-hidden inline-flex items-center justify-center size-16 rounded-full shadow">
                                <img src="https://placehold.co/128x128?font=roboto&text=N/A" alt="img" class="size-16">
                            </span>
                            <span>No Client Company</span>
                        </div>

                        <dl class="sm:w-2/3 flex flex-col divide-y divide-gray-100">
                            <div class="grid grid-cols-6 gap-4 py-3">
                                <dt class="col-span-2 text-gray-500">Account</dt>
                                <dd class="col-span-4 text-gray-900">
                                    <inertia-link v-if="data.currentServiceSnapshot.location?.clientAccount" :href="route('client-accounts.edit', data.currentServiceSnapshot.location.clientAccount.id)" class="flex flex-col gap-1 link">
                                        <span class="font-medium">{{ data.currentServiceSnapshot.location.clientAccount.name }}</span>
                                        <span>#{{ data.currentServiceSnapshot.location.clientAccount.account_number }}</span>
                                    </inertia-link>
                                    <span v-else>Not Set</span>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 gap-4 py-3">
                                <dt class="col-span-2 text-gray-500">Contract</dt>
                                <dd class="col-span-4 text-gray-900">
                                    <inertia-link v-if="data.currentServiceSnapshot.client_contract_id"  class="link" :href="route('client-contracts.show', [data.currentServiceSnapshot.client_contract_id])">
                                        {{ data.currentServiceSnapshot.client_contract_id }}
                                    </inertia-link>
                                    <span v-else>Not Set</span>
                                </dd>
                            </div>

                            <div class="grid grid-cols-6 gap-4 py-3">
                                <dt class="col-span-2 text-gray-500">Pass Through</dt>
                                <dd class="col-span-4 text-gray-900">
                                    <span>{{ data.currentServiceSnapshot.is_pass_through ? 'Yes' : 'No' }}</span>
                                </dd>
                            </div>

                            <template v-if="page.props.permissions.accessRevenueManagement">
                                <div class="grid grid-cols-6 gap-4 py-3">
                                    <dt class="col-span-2 text-gray-500">Flat Charge</dt>
                                    <dd class="col-span-4 text-gray-900">
                                        <span v-if="data.currentServiceSnapshot.client_base_charge" >{{ filters.format_money(data.currentServiceSnapshot.client_base_charge) }}</span>
                                        <span v-else>-</span>
                                    </dd>
                                </div>

                                <div class="grid grid-cols-6 gap-4 py-3">
                                    <dt class="col-span-2 text-gray-500">Per Unit Charge</dt>
                                    <dd class="col-span-4 text-gray-900">
                                        <span v-if="data.currentServiceSnapshot.client_per_unit_charge">{{ filters.format_money(data.currentServiceSnapshot.client_per_unit_charge) }}</span>
                                        <span v-else>-</span>
                                    </dd>
                                </div>

                                <div class="grid grid-cols-6 gap-4 py-3">
                                    <dt class="col-span-2 text-gray-500">Per Occurrence Charge</dt>
                                    <dd class="col-span-4 text-gray-900">
                                        <span v-if="data.currentServiceSnapshot.client_per_occurrence_charge">{{ filters.format_money(data.currentServiceSnapshot.client_per_occurrence_charge) }}</span>
                                        <span v-else>-</span>
                                    </dd>
                                </div>

                                <div v-if="data.currentServiceSnapshot.units_included_per_occurrence" class="grid grid-cols-6 gap-4 py-3">
                                    <dt class="col-span-2 text-gray-500">Units Included Per Occurrence</dt>
                                    <dd class="col-span-4 text-gray-900">
                                        {{ data.currentServiceSnapshot.units_included_per_occurrence }}
                                    </dd>
                                </div>

                                <div class="grid grid-cols-6 py-3">
                                    <dt class="col-span-2 text-gray-500">Billing Frequency</dt>
                                    <dd class="col-span-4 text-gray-900">
                                        {{ data.currentServiceSnapshot.client_billing_frequency ?? '-' }}
                                    </dd>
                                </div>

                                <div class="grid grid-cols-6 py-3">
                                    <dt class="col-span-2 text-gray-500">Avg. Pickups Per Week</dt>
                                    <dd class="col-span-4 text-gray-900">
                                        {{ data.currentServiceSnapshot.average_pickups_per_week ?? '-' }}
                                    </dd>
                                </div>
                            </template>
                        </dl>
                    </div>
                </section>

                <!-- Vendor Service Fees !-->
                <section v-if="data.currentTab === 'billing'">
                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2 cursor-pointer" @click.prevent="toggleVendorServiceFees($event)">
                        <div class="flex items-center justify-between">
                            <span class="inline-flex gap-2 items-baseline">
                                <span>Vendor Service Fees</span>
                                <span class="text-gray-500 font-normal text-base">({{vendorServiceFees.length}})</span>
                            </span>
                            <span class="inline-flex gap-2 items-center">
                                <template v-if="vendorServiceFees.length">
                                    <inertia-link :href="route('services.vendor-service-fees.create', [service.id])" class="btn btn-gray btn-sm">
                                        <icon name="plus" class="size-3 fill-current mr-1" /> Create
                                    </inertia-link>
                                    <inertia-link :href="route('vendor-service-fees.index', {search: service.id})" class="btn btn-gray btn-sm">
                                        View All
                                    </inertia-link>
                                </template>
                                <span class="ml-3 text-gray-900">
                                    <icon :name="data.vendorServiceFeeOpen ? 'chevron-up' : 'chevron-down'" class="size-4 fill-current" />
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="px-4 py-5 sm:px-6" v-show="data.vendorServiceFeeOpen">
                        <div v-if="vendorServiceFees.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3 pr-3 pl-4 text-left text-sm uppercase font-medium text-gray-500">
                                                    Name
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Percentage
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Amount
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Restriction
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="fee in vendorServiceFees" :key="fee.id" class="group text-base hover:bg-gray-100 cursor-pointer" @click="routeToVendorServiceFee($event, fee.id)">
                                                <td class="whitespace-nowrap px-3 py-5 link truncate max-w-60" :title="fee.name">{{ fee.name }}</td>
                                                <td class="whitespace-nowrap px-3 py-5" :class="{'text-gray-500 text-sm': !fee.percentage}">
                                                    {{ fee.percentage ?? 'N/A' }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5" :class="{'text-gray-500 text-sm': !fee.amount}">
                                                    {{ fee.amount ? filters.format_money(fee.amount) : 'N/A' }}
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5" >{{ fee.restriction }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <inertia-link  :href="route('services.vendor-service-fees.create', [service.id])" class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-d-orange-500 focus:ring-offset-2">
                                <icon name="badge-dollar" class="mx-auto size-12 text-gray-400/80 fill-current" />
                                <span class="mt-2 block text-base font-medium text-gray-800">No Vendor Service Fees</span>
                                <span class="mt-1 block text-sm text-gray-600"><span class="link font-medium">Click here</span> to add  a new vendor service fee.</span>
                            </inertia-link>
                        </div>
                    </div>
                </section>

                <!-- Client Service Fees !-->
                <section v-if="page.props.permissions.accessRevenueManagement && data.currentTab === 'billing'">
                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2 cursor-pointer" @click.prevent="toggleClientServiceFees($event)">
                        <div class="flex items-center justify-between">
                            <span class="inline-flex gap-2 items-baseline">
                                <span>Client Service Fees</span>
                                <span class="text-gray-500 font-normal text-base">({{clientServiceFees.length}})</span>
                            </span>
                            <span class="inline-flex gap-2 items-center">
                                <template v-if="clientServiceFees.length">
                                    <inertia-link :href="route('services.client-service-fees.create', [service.id])" class="btn btn-gray btn-sm">
                                        <icon name="plus" class="size-3 fill-current mr-1" /> Create
                                    </inertia-link>
                                    <inertia-link :href="route('client-service-fees.index', {search: service.id})" class="btn btn-gray btn-sm">
                                        View All
                                    </inertia-link>
                                </template>
                                <span class="ml-3 text-gray-900">
                                    <icon :name="data.clientServiceFeeOpen ? 'chevron-up' : 'chevron-down'" class="size-4 fill-current" />
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="px-4 py-5 sm:px-6" v-show="data.clientServiceFeeOpen">
                        <div v-if="clientServiceFees.length" class="flex flex-col">
                            <div class="-mx-4 overflow-x-auto sm:-mx-6">
                                <div class="inline-block min-w-full align-middle px-6">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3 pr-3 pl-4 text-left text-sm uppercase font-medium text-gray-500">
                                                    Label
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Billing Style
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Category
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Effective Dates
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Frequency
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="fee in clientServiceFees" :key="fee.id" class="group text-base hover:bg-gray-100 cursor-pointer" @click="routeToClientServiceFee($event, fee.id)">
                                                <td class="whitespace-nowrap px-3 py-5 link truncate max-w-40" :title="fee.label">{{ fee.label }}</td>
                                                <td class="whitespace-nowrap px-3 py-5">{{ fee.billing_style }}</td>
                                                <td class="whitespace-nowrap px-3 py-5">{{ fee.category.display_name }}</td>
                                                <td class="whitespace-nowrap px-3 py-5">{{ feeDates(fee) }}</td>
                                                <td class="whitespace-nowrap px-3 py-5">{{ fee.frequency }}</td>
                                                <td class="whitespace-nowrap px-3 py-5">{{ fee.billing_style === 'Fixed' ? filters.format_money(fee.amount) : 'N/A' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <inertia-link  :href="route('services.client-service-fees.create', [service.id])" class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-d-orange-500 focus:ring-offset-2">
                                <icon name="envelope-open-dollar" class="mx-auto size-12 text-gray-400/80 fill-current" />
                                <span class="mt-2 block text-base font-medium text-gray-800">No Client Service Fees</span>
                                <span class="mt-1 block text-sm text-gray-600"><span class="link font-medium">Click here</span> to add a new client service fee.</span>
                            </inertia-link>
                        </div>
                    </div>
                </section>

                <!-- Incidents !-->
                <section v-if="data.currentTab === 'incidents'">
                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2">
                        <div class="flex items-center justify-between">
                            <span class="inline-flex gap-2 items-baseline">
                                <span>Latest Incidents</span>
                                <span class="text-gray-500 font-normal text-base">({{incidents.length}})</span>
                            </span>
                            <span v-if="incidents.length" class="inline-flex gap-2 items-center">
                                <inertia-link :href="route('incidents.create', {service_id: service.id})" class="btn btn-gray btn-sm">
                                    <icon name="plus" class="size-3 fill-current mr-1" /> Create
                                </inertia-link>
                                <inertia-link :href="route('incidents.index', {service_id: service.id, statuses: incidentStatuses})" class="btn btn-gray btn-sm">
                                    View All
                                </inertia-link>
                            </span>
                        </div>
                    </div>

                    <div class="px-4 py-5 sm:px-6">
                        <div v-if="incidents.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-3 pr-3 pl-4 text-left text-sm uppercase font-medium text-gray-500">
                                                    Incident
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Priority
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Reported
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Assigned To
                                                </th>
                                                <th scope="col" class="whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm uppercase font-medium text-gray-500">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="incident in incidents.slice(0, 15)" :key="incident.id" :class="{'bg-gray-50 text-gray-500 opacity-80 hover:opacity-100': incident.status === 'Closed'}" class="group text-base hover:bg-gray-100 cursor-pointer" @click="routeToIncident($event, incident.id)">
                                                <td class="py-5 pl-4 pr-3">
                                                    <div class="flex flex-col gap-1">
                                                        <span>{{ incident.reason.name }}</span>
                                                        <span class="text-base">
                                                            <inertia-link class="link" :href="route('incidents.show', [incident.id])">
                                                                #{{ incident.display_id }}
                                                            </inertia-link>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div>
                                                        <span v-if="incident.status !== 'Closed'" class="inline-flex items-center rounded-m px-2 py-1 text-sm font-medium ring-1 ring-inset" :class="incidentPriorityIndicators[incident.priority]">
                                                            {{ incident.priority }}
                                                        </span>
                                                        <span v-else class="inline-flex items-center rounded-m px-2 py-1 text-sm font-medium ring-1 ring-inset bg-gray-50 text-gray-500 ring-gray-600/20">
                                                            {{ incident.priority }}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col gap-1">
                                                        <span class="text-base">{{ filters.format_date_time(incident.reported_at) }}</span>
                                                        <span class="text-sm text-gray-500">via {{ incident.source }}</span>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex items-center">
                                                        <img v-if="incident.assignedTo?.avatar_thumbnail_url" :src="incident.assignedTo?.avatar_thumbnail_url" class="inline border border-gray-400 rounded-full mr-4 size-6"/>
                                                        <span v-if="incident.assignedTo" class="inline-flex items-center justify-center size-6 rounded-full bg-gray-400 mr-2">
                                                            <span class="text-xs font-medium leading-none text-white uppercase">
                                                                {{ incident.assignedTo?.initials }}
                                                            </span>
                                                        </span>
                                                        <span class="text-base">{{ incident?.assignedTo?.name ?? 'Unassigned' }}</span>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col items-end gap-1">
                                                        <span class="flex-none rounded-md px-2 py-0.5 text-base font-medium ring-1 ring-inset" :class="incidentStatusIndicators[incident.status]">
                                                            {{ incident.status }}
                                                        </span>
                                                        <span class="flex-initial text-sm font-normal">
                                                            {{ getIncidentStatusDate(incident) }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <inertia-link  :href="route('incidents.create', {service_id: service.id})" class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-d-orange-500 focus:ring-offset-2">
                                <icon name="tasks" class="mx-auto size-12 text-gray-400/80 fill-current" />
                                <span class="mt-2 block text-base font-medium text-gray-800">No Incidents Filed</span>
                                <span class="mt-1 block text-sm text-gray-600"><span class="link font-medium">Click here</span> to file an incident now.</span>
                            </inertia-link>
                        </div>
                    </div>
                </section>

                <!-- Work Orders !-->
                <section v-if="data.currentTab === 'work-orders'">
                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2">
                        <div class="flex items-center justify-between">
                            <span class="inline-flex gap-2 items-baseline">
                                <span>Latest Work Orders</span>
                                <span class="text-gray-500 font-normal text-base">({{workOrders.length}})</span>
                            </span>
                            <span v-if="workOrders.length" class="inline-flex gap-2 items-center">
                                <inertia-link :href="route('services.work-orders.create', [service.id])" class="btn btn-gray btn-sm">
                                    <icon name="plus" class="size-3 fill-current mr-1" /> Create
                                </inertia-link>
                                <inertia-link :href="route('work-orders.index', {search: service.id})" class="btn btn-gray btn-sm">
                                    View All
                                </inertia-link>
                            </span>
                        </div>
                    </div>

                    <div class="px-4 py-5 sm:px-6">
                        <div v-if="workOrders.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Request
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Location
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Scheduled For
                                                </th>
                                                <th scope="col" class="whitespace-nowrap py-3 pr-3 pl-4 text-left text-sm uppercase font-medium text-gray-500">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="workOrder in workOrders.slice(0, 15)" :key="workOrder.id" :class="{'bg-gray-50 text-gray-500 opacity-80 hover:opacity-100': workOrderIsClosedOrComplete(workOrder.decorated_status)}" class="group text-base hover:bg-gray-100 cursor-pointer" @click="routeToWorkOrder($event, workOrder.id)">
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col">
                                                        <span>{{ workOrder.requested_action }}</span>
                                                        <span v-if="workOrder.requestedBy" class="text-sm text-gray-500">by {{ workOrder.requestedBy.name }}</span>
                                                        <span v-else class="text-sm text-gray-500 italic">via {{ workOrder.source }}</span>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col">
                                                        <inertia-link :href="route('locations.show', [service.location_id])" class="link">
                                                            {{ service.location.name }}
                                                        </inertia-link>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    {{ filters.format_date(workOrder.scheduled_with_vendor_date) ?? '-' }}
                                                </td>
                                                <td class="px-3 py-5 text-base">
                                                    {{ workOrder.decorated_status }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <inertia-link  :href="route('services.work-orders.create', [service.id])" class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-d-orange-500 focus:ring-offset-2">
                                <icon name="clipboard" class="mx-auto size-12 text-gray-400/80 fill-current" />
                                <span class="mt-2 block text-base font-medium text-gray-800">No Open Work Orders</span>
                                <span class="mt-1 block text-sm text-gray-600"><span class="link font-medium">Click here</span> to open a work order now.</span>
                            </inertia-link>
                        </div>
                    </div>
                </section>

                <!-- Pickups !-->
                <section v-if="data.currentTab === 'pickups'">
                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2">
                        <div class="flex items-center justify-between">
                            <span class="inline-flex gap-2 items-baseline">
                                <span>Latest Pickups</span>
                                <span class="text-gray-500 font-normal text-base">({{pickups.length}})</span>
                            </span>
                            <span v-if="pickups.length" class="inline-flex gap-2 items-center">
                                <inertia-link
                                    class="btn btn-gray btn-sm"
                                    :href="route('pickups.create', {
                                        service_id: service.id,
                                        location_id: service.location_id,
                                        material_type: service.bin.material_type,
                                        disposal_method: service.bin.disposal_method,
                                        container_size: `${service.bin.quantity * service.bin.size} ${service.bin.size_metric}`
                                    })"
                                >
                                    <icon name="plus" class="size-3 fill-current mr-1" /> Create
                                </inertia-link>
                                <inertia-link :href="route('pickups.index', {search: service.id})" class="btn btn-gray btn-sm">
                                    View All
                                </inertia-link>
                            </span>
                        </div>
                    </div>

                    <div class="px-4 py-5 sm:px-6">
                        <div v-if="pickups.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Source
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Date
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Weight
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Material
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-right text-sm uppercase font-medium text-gray-500">
                                                    Est. Percent Full
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="pickup in pickups.slice(0, 15)" :key="pickup.id" class="group text-base hover:bg-gray-100 cursor-pointer" @click="routeToPickup($event, pickup.id)">
                                                <td class="whitespace-nowrap px-3 py-5">{{ pickup.source }}</td>
                                                <td class="whitespace-nowrap px-3 py-5">{{ filters.format_date(pickup.start_date) }}</td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col text-base">
                                                        <span>{{ filters.format_number(pickup.weight_value, 6) }} {{ pickup.weight_unit }}</span>
                                                        <span v-if="pickup.weight_value_is_estimated" class="text-sm text-gray-500">(estimated)</span>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5 text-base">
                                                    <div class="flex flex-col">
                                                        <span>{{pickup?.materialType?.name}}</span>
                                                        <span class="text-sm text-gray-500">disposal via {{ pickup.disposal_method }}</span>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5 text-base text-right">{{ pickup.estimated_percent_full ?? "-" }}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <inertia-link
                                :href="route('pickups.create', {
                                    service_id: service.id,
                                    location_id: service.location_id,
                                    material_type: service.bin.material_type,
                                    disposal_method: service.bin.disposal_method,
                                    container_size: `${service.bin.quantity * service.bin.size} ${service.bin.size_metric}`
                                })"
                                class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-d-orange-500 focus:ring-offset-2"
                            >
                                <icon name="truck" class="mx-auto size-12 text-gray-400/80 fill-current" />
                                <span class="mt-2 block text-base font-medium text-gray-800">No Pickups Detected</span>
                                <span class="mt-1 block text-sm text-gray-600"><span class="link font-medium">Click here</span> to record a pickup.</span>
                            </inertia-link>
                        </div>
                    </div>
                </section>

                <!-- Vendor Invoices !-->
                <section v-if="data.currentTab === 'invoicing'">
                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2">
                        <div class="flex items-center justify-between">
                            <span class="inline-flex gap-2 items-baseline">
                                <span>Vendor Invoices</span>
                                <span class="text-gray-500 font-normal text-base">({{vendorInvoices.length}})</span>
                            </span>
                            <span v-if="vendorInvoices.length" class="inline-flex gap-2 items-center">
                                <inertia-link :href="route('vendor-invoices.index', {search: service.id})" class="btn btn-gray btn-sm">
                                    View All
                                </inertia-link>
                            </span>
                        </div>
                    </div>

                    <div class="px-4 py-5 sm:px-6">
                        <div v-if="vendorInvoices.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <span class="sr-only">Actions</span>
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Invoice
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Fiscal Period
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-right text-sm uppercase font-medium text-gray-500">
                                                    Total
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-right text-sm uppercase font-medium text-gray-500">
                                                    Adjusted Total
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white text-base">
                                            <tr v-for="vendorInvoice in vendorInvoices.slice(0, 15)" :key="vendorInvoice.id" class="group text-base hover:bg-gray-100 cursor-pointer" @click="routeToVendorInvoice($event, vendorInvoice.id)">
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <a :href="route('vendor-invoices.pdf', vendorInvoice.id)" class="link" target="_blank">
                                                        <icon name="file-pdf" class="inline fill-current text-red-500 hover:text-red-500 w-5 h-5 mb-1 mr-3"/> <span class="sr-only">View PDF</span>
                                                    </a>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col gap-1">
                                                        <span class="link">{{ vendorInvoice.invoice_number }}</span>
                                                        <span class="text-sm text-gray-500">{{ filters.format_date(vendorInvoice.invoice_date) }}</span>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">{{ filters.format_date(vendorInvoice.fiscal_period) }}</td>
                                                <td class="whitespace-nowrap text-right px-3 py-5">{{ filters.format_money(vendorInvoice.total_current_charges) }}</td>
                                                <td class="whitespace-nowrap text-right px-3 py-5">{{ filters.format_money(vendorInvoice.adjusted_total_current_charges) }}</td>

                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex justify-start">
                                                        <span class="inline-flex items-center rounded-m px-2 py-1 text-sm font-medium ring-1 ring-inset" :class="vendorInvoiceStatusIndicators[vendorInvoice.status]">
                                                            {{ vendorInvoice.status }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-invoice" class="mx-auto size-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Vendor Invoices</h3>
                            <p class="mt-1 text-sm text-gray-500">
                                Discovery hasn't received any vendor invoices for this service yet.
                            </p>
                        </div>
                    </div>
                </section>

                <!-- Client Invoices !-->
                <section v-if="page.props.permissions.accessRevenueManagement && data.currentTab === 'invoicing'">
                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2">
                        <div class="flex items-center justify-between">
                            <span class="inline-flex gap-2 items-baseline">
                                <span>Client Invoices</span>
                                <span class="text-gray-500 font-normal text-base">({{clientInvoices.length}})</span>
                            </span>
                            <span v-if="clientInvoices.length" class="inline-flex gap-2 items-center">
                                <inertia-link :href="route('client-invoices.index', {search: service.id})" class="btn btn-gray btn-sm">
                                    View All
                                </inertia-link>
                            </span>
                        </div>
                    </div>

                    <div class="px-4 py-5 sm:px-6">
                        <div v-if="clientInvoices.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Invoice #
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Account
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Billed
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Adjustments
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Due
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="invoice in clientInvoices" :key="invoice.id" class="group text-base hover:bg-gray-100 cursor-pointer" @click="routeToClientInvoice($event, invoice.id)">
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex gap-2 items-center">
                                                        <div class="flex flex-col items-start gap-1">
                                                            <div class="leading-none">
                                                                <span class="link">
                                                                    {{ invoice.invoice_number }}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span class="text-sm text-gray-500">
                                                                    {{ filters.format_date(invoice.invoice_date) }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col items-start gap-1">
                                                        <div class="leading-none">
                                                            <inertia-link :href="route('client-accounts.show', [invoice.clientAccount.id])" class="link">
                                                                {{ invoice.clientAccount.account_number }}
                                                            </inertia-link>
                                                        </div>
                                                        <div>
                                                            <span class="text-sm text-gray-500">
                                                                {{ invoice.clientAccount.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col items-start gap-1">
                                                        <div class="leading-none">
                                                            <span>
                                                                {{ filters.format_money(invoice.total_amount_billed) }}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span class="text-sm text-gray-500">
                                                                {{ invoice.lineItems.length }} line items
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col items-start gap-1">
                                                        <div class="leading-none">
                                                            <span>
                                                                {{ filters.format_money(add_money(invoice.total_amount_credit_memo,invoice.total_amount_write_off)) }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex items-center gap-2">
                                                        <div class="flex flex-col items-start gap-1">
                                                                <div class="leading-none">
                                                                <span>
                                                                    {{ filters.format_money(invoice.total_amount_due) }}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span class="text-sm text-gray-500">
                                                                    {{ filters.format_date(invoice.due_at) }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex items-center gap-2">
                                                        <div class="flex flex-col items-start gap-1">
                                                            <div class="leading-none">
                                                                <span class="inline-flex items-center rounded-m px-2 py-1 text-sm font-medium ring-1 ring-inset" :class="lineItemStatusIndicators[invoice.status]">
                                                                    {{ invoice.status }}
                                                                </span>
                                                            </div>
                                                            <div v-if="invoice.status === 'Sent'">
                                                                <span class="text-sm text-gray-500">
                                                                    {{ filters.format_date(invoice.last_emailed_at) }}
                                                                </span>
                                                            </div>
                                                            <div v-if="invoice.status === 'Paid'">
                                                                <span class="text-sm text-gray-500">
                                                                    {{ filters.format_money(invoice.total_amount_paid) }}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div v-if="!invoice.approved_at && invoice.latest_approval_attempt.length && invoice.latest_approval_attempt_at" :content="`Last Approval Attempt Failed on ${filters.format_date(invoice.latest_approval_attempt_at)}`" v-tippy>
                                                            <icon name="exclamation-triangle" class="inline fill-current size-5 text-red-500" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>
                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Client Invoices</h3>
                            <p class="mt-1 text-sm text-gray-500">Discovery hasn't generated any client invoices for this service yet.</p>
                        </div>
                    </div>
                </section>

                <!-- Client Credit Memos !-->
                <section v-if="page.props.permissions.accessRevenueManagement && data.currentTab === 'invoicing'">
                    <div class="font-medium leading-6 text-gray-900 bg-gray-100 rounded-md px-4 py-2">
                        <div class="flex items-center justify-between">
                            <span class="inline-flex gap-2 items-baseline">
                                <span>Client Credit Memos</span>
                                <span class="text-gray-500 font-normal text-base">({{clientCreditMemos.length}})</span>
                            </span>
                            <span v-if="clientCreditMemos.length" class="inline-flex gap-2 items-center">
                                <inertia-link :href="route('client-credit-memos.index', {search: service.id})" class="btn btn-gray btn-sm">
                                    View All
                                </inertia-link>
                            </span>
                        </div>
                    </div>

                    <div class="px-4 py-5 sm:px-6">
                        <div v-if="clientCreditMemos.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Document #
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Account
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Amount
                                                </th>
                                                <th scope="col" class="whitespace-nowrap p-3 text-left text-sm uppercase font-medium text-gray-500">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="memo in clientCreditMemos" :key="memo.id" class="group text-base hover:bg-gray-100 cursor-pointer" @click="routeToClientCreditMemo($event, memo.id)">
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex gap-2 items-center">
                                                        <div class="flex flex-col items-start gap-1">
                                                            <div class="leading-none">
                                                                <span class="link">
                                                                    {{ memo.document_number }}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span class="text-sm text-gray-500">
                                                                    {{ filters.format_date(memo.document_date) }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col items-start gap-1">
                                                        <div class="leading-none">
                                                            <inertia-link :href="route('client-accounts.show', [memo.clientAccount.id])" class="link">
                                                                {{ memo.clientAccount.account_number }}
                                                            </inertia-link>
                                                        </div>
                                                        <div>
                                                            <span class="text-sm text-gray-500">
                                                                {{ memo.clientAccount.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex flex-col items-start gap-1">
                                                        <div class="leading-none">
                                                            <span>
                                                                {{ filters.format_money(memo.total_amount) }}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span class="text-sm text-gray-500">
                                                                {{ filters.format_money(memo.applied_amount) }} applied
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-5">
                                                    <div class="flex items-center gap-2">
                                                        <div class="flex flex-col items-start gap-1">
                                                            <div class="leading-none">
                                                                <span class="inline-flex items-center rounded-m px-2 py-1 text-sm font-medium ring-1 ring-inset" :class="lineItemStatusIndicators[memo.status]">
                                                                    {{ memo.status }}
                                                                </span>
                                                            </div>
                                                            <div v-if="memo.status === 'Void'">
                                                                <span class="text-sm text-gray-500">
                                                                    {{ filters.format_date(memo.voided_at) }}
                                                                </span>
                                                            </div>
                                                            <div v-if="memo.status === 'Refund'">
                                                                <span class="text-sm text-gray-500">
                                                                    {{ filters.format_money(memo.refunded_at) }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>
                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Client Credit Memos</h3>
                            <p class="mt-1 text-sm text-gray-500">There are no credit memos for this service yet.</p>
                        </div>
                    </div>
                </section>
            </article>

            <!-- Right Column -->
            <aside class="px-2 md:px-4 space-y-6 xl:px-0 xl:col-start-3 xl:col-span-1">
                <!-- Sticky Note !-->
                <sticky-note
                    :content="data.stickyNoteValue"
                    :hideLabel="true"
                    :savingRoute="route('services.sticky-notes-changes.store', [service.id])"
                    @saved="data.stickyNoteValue = $event"
                />

                <!-- Timeline -->
                <section aria-labelledby="timeline-title" v-if="['overview', 'billing'].includes(data.currentTab)">
                    <div class="px-2 md:px-4 space-y-3">
                        <div class="flex flex-row justify-between gap-4 items-center">
                            <div>
                                <h2 id="timeline-title" class="text-lg font-semibold text-gray-900">Service History</h2>
                                <p class="text-base mt-1 max-w-2xl text-gray-500">
                                    Click the date to view the details of the change.
                                </p>
                            </div>
                            <div v-if="serviceActivities.length <= serviceHistoriesPreview.length">
                                <button type="button" @click="serviceHistorySlideOver.show()" class="btn btn-gray inline-flex items-center justify-end shrink-0 text-nowrap">
                                    View All
                                </button>
                            </div>
                        </div>

                        <template v-if="scheduledChanges.length">
                            <ul id="service-timeline" role="list" class="space-y-6 opacity-60 pb-6 border-b border-gray-400">
                                <li class="relative flex gap-x-4" v-for="(activity, index) in scheduledChanges" :key="activity.id">
                                    <div v-if="index !== scheduledChanges.length - 1" class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>

                                    <img v-if="activity.scheduledByEmployee && activity.scheduledByEmployee.avatar_thumbnail_url" :src="activity.scheduledByEmployee.avatar_thumbnail_url" :alt="activity.scheduledByEmployee.name" class="relative h-6 w-6 flex-none rounded-full bg-gray-50 shrink-0">
                                    <div v-else-if="activity.scheduledByEmployee && !activity.scheduledByEmployee.avatar_thumbnail_url" class="relative text-xs h-6 w-6 flex items-center justify-center rounded-full bg-gray-400 text-white uppercase shrink-0">
                                        {{ activity.scheduledByEmployee.initials }}
                                    </div>
                                    <div v-else-if="activity.change_type === 'Activation'" class="relative flex h-6 w-6 items-center justify-center bg-white shrink-0">
                                        <!-- using this icon for now, our checkmark is too thin to be this small -->
                                        <svg class="h-6 w-6 text-d-blue-400" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div v-else class="relative flex h-6 w-6 items-center justify-center bg-white shrink-0">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>

                                    <p class="text-base flex-auto py-0.5 leading-5 text-gray-500">
                                        <span v-if="activity.scheduledByEmployee" class="font-medium text-gray-900">{{ activity.scheduledByEmployee.name }}</span> {{ translateChangeEvent(activity.change_type, activity.scheduledByEmployee) }}.
                                    </p>
                                    <inertia-link :href="route('service-scheduled-changes.show', [activity.id])"
                                       class="text-base flex flex-col text-right py-0.5 leading-5 text-d-blue-500 hover:text-blue-500"
                                       title="View Scheduled Change"
                                    >
                                        Pending:
                                        <time :datetime="activity.actual_date" >
                                            {{ filters.format_date(activity.scheduled_for) }}
                                        </time>
                                    </inertia-link>
                                </li>
                            </ul>
                        </template>

                        <template v-if="serviceActivities.length">
                            <ul id="service-timeline" role="list" class="space-y-6">
                                <li class="relative flex gap-x-4" v-for="(activity, index) in serviceHistoriesPreview" :key="activity.id">
                                    <div v-if="index !== serviceHistoriesPreview.length - 1" class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>

                                    <img v-if="activity.changedBy && activity.changedBy.avatar_thumbnail_url" :src="activity.changedBy.avatar_thumbnail_url" :alt="activity.changedBy.name" class="relative h-6 w-6 flex-none rounded-full bg-gray-50 shrink-0">
                                    <div v-else-if="activity.changedBy && !activity.changedBy.avatar_thumbnail_url" class="relative text-xs h-6 w-6 flex items-center justify-center rounded-full bg-gray-400 text-white uppercase shrink-0">
                                        {{ activity.changedBy.initials }}
                                    </div>
                                    <div v-else-if="activity.change_type === 'Activation'" class="relative flex h-6 w-6 items-center justify-center bg-white">
                                        <!-- using this icon for now, our checkmark is too thin to be this small -->
                                        <svg class="h-6 w-6 text-d-blue-400" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div v-else class="relative flex h-6 w-6 items-center justify-center bg-white shrink-0">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>

                                    <p class="text-base flex-auto py-0.5 leading-5 text-gray-500">
                                        <span v-if="activity.changedBy" class="font-medium text-gray-900">{{ activity.changedBy.name }}</span> {{ translateChangeEvent(activity.change_type, activity.changedBy) }}.
                                    </p>
                                    <a href=""
                                       class="text-base flex-none py-0.5 leading-5 text-d-blue-500 hover:text-blue-500"
                                       :title="`Recorded Date of Change - ${filters.format_date_time(activity.recorded_at)} `"
                                       @click.prevent="changeServiceSnapshot(activity.serviceSnapshot)"
                                    >
                                        <time :datetime="activity.actual_date" >
                                            {{ filters.format_date(activity.actual_date) }}
                                        </time>
                                    </a>
                                </li>
                            </ul>
                            <div class="relative group py-3 hover:cursor-pointer" v-if="serviceActivities.length > serviceHistoriesPreview.length" @click="serviceHistorySlideOver.show()">
                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div class="w-full border-t border-gray-300 group-hover:border-gray-400"></div>
                                </div>
                                <div class="relative flex justify-center text-blue-500">
                                    <span class="bg-white px-2 text-sm">View More Events</span>
                                </div>
                            </div>
                        </template>

                        <template v-else>No Revisions Made Yet</template>
                    </div>
                </section>

                <!-- Incidents Totals -->
                <section aria-labelledby="incidents-totals-title" v-if="data.currentTab === 'incidents'">
                    <div class="overflow-hidden rounded-lg bg-white border border-gray-200">
                        <div class="px-4 py-5 lg:px-6 lg:py-3 space-y-2">
                            <div class="flex flex-row justify-between gap-4 items-center">
                                <div>
                                    <h2 id="incidents-totals-title" class="text-lg font-semibold text-gray-900">Incident Stats</h2>
                                </div>
                            </div>
                            <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-3">
                                <div v-for="(value, key) in props.incidentTotals" :key="key" class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-2 sm:px-6 xl:px-8">
                                    <dt class="text-sm font-medium leading-6 text-gray-500">
                                        {{ filters.capitalize(key) }}
                                    </dt>
                                    <dd v-if="key === 'active' && value > 10" class="text-rose-600">
                                        <icon name="exclamation-triangle" class="inline fill-current size-3" content="Active Incidents" v-tippy />
                                    </dd>
                                    <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                        {{ value }}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>
            </aside>
        </div>
    </div>

    <slide-over ref="serviceHistorySlideOver" max-width="max-w-3xl">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <div class="space-y-1">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">Service History</h2>
                        <p class="text-sm text-white w-3/4 lg:w-full">Click the dates to see the changes <span class="font-bold underline ">temporarily</span> reflected to the left</p>
                    </div>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-orange-500">
                            <span class="sr-only">Close panel</span>
                            <icon name="times" class="size-6 fill-current" />
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <div class="flex flex-1 flex-col justify-between">
                <div class="divide-y divide-gray-200 px-4 lg:px-0">
                    <div class="space-y-6 p-4">
                        <toggle-switch-input label="Sort By Recorded Date" class="my-2" v-model="data.serviceChangesToggle"></toggle-switch-input>
                    </div>
                    <div class="space-y-6 pt-6 pb-5 relative overflow-auto">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th title="Date that the activity actually occurred" class="!pl-0 font-semibold text-gray-900">Actual Date</th>
                                    <th title="Date that the activity was recorded" class="font-semibold text-gray-900">Recorded Date</th>
                                    <th title="Who initiated this activity" class="font-semibold text-gray-900">Changed By</th>
                                    <th title="Type of activity that occurred" class="font-semibold text-gray-900">Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="history in data.serviceChanges" :key="history.id">
                                    <td class="!p-0">
                                        <button
                                            @click.prevent="changeServiceSnapshot(history.serviceSnapshot)"
                                            class="btn btn-orange"
                                            :title="`Actual Date of Change - ${filters.format_date(history.actual_date)} `">
                                            {{ filters.format_date(history.actual_date) }}
                                        </button>
                                    </td>
                                    <td :title="filters.format_date_time(history.recorded_at)">
                                        {{ filters.format_date_time(history.recorded_at) }}
                                    </td>
                                    <td>{{ getEmployeeName(history.changed_by_employee_id) }}</td>
                                    <td>{{ history.change_type }}</td>
                                    <td class="text-right">
                                        <span v-if="history.change_type === 'Activation'">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.service-activation-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="['Creating Service', 'Service Created via Bulk Import'].includes(history.change_type)">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.service-creation-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="['Client Billing Change', 'Client annual price increase'].includes(history.change_type)">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.client-billing-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="['Client Contract Change', 'Attached to client contract'].includes(history.change_type)">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.client-contract-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="history.change_type === 'Level of Service Change'">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.level-of-service-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="history.change_type === 'Pickup Days Change'">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.pickup-days-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="history.change_type === 'Terminating Service'">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.service-termination-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="history.change_type === 'Termination Date Changed'">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.service-termination-date-changes.show', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray px-3 font-bold">View</inertia-link>
                                            <button v-else disabled class="btn btn-gray px-3 disabled">View</button>
                                        </span>
                                        <span v-else-if="['Vendor account replacement', 'Vendor Account Change'].includes(history.change_type)">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.vendor-account-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="history.change_type === 'Vendor Contract Change'">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.vendor-contract-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="history.change_type === 'Vendor Pricing Change'">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.vendor-pricing-changes.edit', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray font-bold">Edit</inertia-link>
                                            <button v-else disabled class="btn btn-gray disabled">Edit</button>
                                        </span>
                                        <span v-else-if="Object.keys(history.changes ?? {}).length">
                                            <inertia-link v-if="data.state === 'passive'" :href="route('services.service-updates.show', {serviceId: service.id, serviceActivityId: history.id})" class="btn btn-gray px-3 font-bold">View</inertia-link>
                                            <button v-else disabled class="btn btn-gray px-3 disabled">View</button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </slide-over>
</template>

<script setup>
    // Vue/Inertia Imports
    import { router, usePage } from '@inertiajs/vue3';
    import { ref, inject, onMounted, onBeforeUnmount, reactive, computed, watch } from 'vue';

    // Package imports
    import axios from 'axios';
    import { useTippy } from 'vue-tippy';
    import { cloneDeep } from 'lodash-es';
    import {differenceInCalendarDays, format, parseISO} from "date-fns";

    // Components
    import Logo from '@/Shared/Logo.vue';
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from '@/Shared/SlideOver.vue';
    import StickyNote from '@/Shared/StickyNote.vue';
    import { filters } from '@/Shared/Utils/Filters';
    import { add_money } from "@/Shared/Utils/Money.js";
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';

    // Inject global variables
    const page = usePage();
    const route = inject('route'); // previously this.$route
    const toast = inject('toast'); // previously this.$toast

    // Props
    const props = defineProps({
        service: {
            type: Object,
            required: true
        },
        clientServiceFees: {
            type: Object,
            required: true
        },
        vendorServiceFees: {
            type: Object,
            required: true
        },
        assetOwners: {
            type: Object,
            required: true
        },
        compactorMonitor: {
            type: Object,
            default: () => ({})
        },
        lastPickupWorkOrder: {
            type: Object,
        },
        daysSinceLastPickup: {
            required: false
        },
        monitorSummary: {
            type: Object,
            required: false
        },
        deviceService: {
            type: Object,
            default: () => ({})
        },
        isRebuildingHistory: {
            type: Boolean,
            required: true
        },
        scheduledChanges: {
            type: Array,
            required: true
        },
        serviceActivities: {
            type: Array,
            required: true
        },
        vendorInvoices: {
            type: Array,
            required: true,
        },
        clientInvoices: {
            type: Array,
            required: true,
        },
        clientCreditMemos: {
            type: Array,
            required: true,
        },
        clientLineItems: {
            type: Array,
            required: true,
        },
        workOrders: {
            type: Array,
            required: true,
        },
        employees: {
            type: Array,
            required: true,
        },
        incidents: {
            type: Array,
            required: true,
        },
        incidentTotals: {
            type: Object,
            required: true,
        },
        incidentStatuses: {
            type: Array,
            required: true,
        },
        pickups: {
            type: Array,
            required: true
        },
        errors: {
            type: Object,
            default: () => ({})
        },
    });

    // $refs
    const clickToMap = ref(null);
    const clickToCopy = ref(null);
    const filePendingDocuments = ref(null);
    const serviceHistorySlideOver = ref(null);

    // Page Data & States
    const data = reactive({
        mounted: false,
        otherUsers: [],
        useLogos: false,
        collapsed: false,
        panelOpen: false,
        savingNote: false,
        editMenuActive: false,
        pendingFileUploads: [],
        currentTab: 'overview',
        serviceChangesToggle: false,
        vendorServiceFeeOpen: true,
        clientServiceFeeOpen: true,
        pioneerNeedsAttention: false,
        serviceChangesSort: 'actual_date',
        stickyNoteValue: props.service.sticky_notes,
        currentServiceSnapshot: cloneDeep(props.service),
        serviceChanges: cloneDeep(props.serviceActivities),
        state: props.isRebuildingHistory ? 'rebuilding' : 'passive',
        monitorConfiguration: props.compactorMonitor ? {...props.compactorMonitor.monitorConfiguration} : null,
        monitorConfigurationForm: props.compactorMonitor ? cloneDeep(props.compactorMonitor.monitorConfiguration) : null,

        mapOptions: {
            zoom: 15,
            mapTypeId: 'roadmap',
            disableDefaultUI: true,
            styles: [
                {
                    featureType: 'poi.business',
                    stylers: [{ visibility: 'off' }]
                },
            ]
        },
    });

    // Subnav tabs
    const tabs = ref([
        {name: 'Overview', href: '#overview', key: 'overview', badge: null},
        {name: 'Billing', href: '#billing', key: 'billing', badge: null},
        {name: 'Invoicing', href: '#invoicing', key: 'invoicing', badge: null},
        {name: 'Incidents', href: '#incidents', key: 'incidents', badge: null},
        {name: 'Work Orders', href: '#work-orders', key: 'work-orders', badge: null},
        {name: 'Pickups', href: '#pickups', key: 'pickups', badge: null},
        {name: 'Documents', href: '#documents', key: 'documents', badge: null},
    ]);

    // Classes for status indicators
    const statusIndicators = reactive({
        'Active': 'text-green-400 bg-green-400/10',
        'Terminated': 'text-rose-400 bg-rose-400/10',
        'Pending Activation': 'text-gray-400 bg-gray-400/10',
    });
    const pioneerConnectivity = reactive({
        'online': 'animate-pulse text-green-400 bg-green-400/20',
        'offline': 'animate-pulse text-rose-400 bg-rose-400/20',
        'inactive': 'text-gray-400 bg-gray-400/20',
    });
    const incidentPriorityIndicators = reactive({
        'Low': 'bg-gray-50 text-gray-700 ring-gray-600/20',
        'Medium': 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
        'High': 'bg-red-50 text-red-700 ring-red-600/20',
    });
    const incidentStatusIndicators = reactive({
        'New': 'bg-blue-50 text-blue-700 ring-blue-600/20',
        'Open': 'bg-green-50 text-green-700 ring-green-600/20',
        'Awaiting Execution': 'bg-purple-50 text-purple-700 ring-purple-600/20',
        'In Progress': 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
        'On Hold': 'bg-orange-50 text-orange-700 ring-orange-600/20',
        'Escalated': 'bg-red-50 text-red-700 ring-red-600/20',
        'Closed': 'bg-gray-50 text-gray-500 ring-gray-600/20',
    });
    const vendorInvoiceStatusIndicators = reactive({
        'Matched': 'bg-gray-50 text-gray-700 ring-gray-600/20',
        'Allocated': 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
        'Invalid Allocation': 'bg-red-50 text-red-700 ring-red-600/20',
        'Audited': 'bg-purple-50 text-purple-700 ring-purple-600/20',
        'Approved': 'bg-cyan-50 text-cyan-700 ring-cyan-600/20',
        'Posted': 'bg-blue-50 text-blue-700 ring-blue-600/20',
        'Paid': 'bg-green-50 text-green-700 ring-green-600/20',
    });
    const lineItemStatusIndicators = reactive({
        // Invoices
        'Not Approved': 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
        'Delinquent': 'bg-red-50 text-red-700 ring-red-600/20',
        'Approved': 'bg-cyan-50 text-cyan-700 ring-cyan-600/20',
        'Paid': 'bg-green-50 text-green-700 ring-green-600/20',
        'Write-Off Debt': 'bg-pink-50 text-pink-700 ring-pink-600/20',
        'Write-Off Review': 'bg-purple-50 text-purple-700 ring-purple-600/20',
        'Sent To Collections': 'bg-orange-50 text-orange-700 ring-orange-600/20',
        // Memos
        'Not Sent': 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
        'Voided': 'bg-red-50 text-red-700 ring-red-600/20',
        'Sent': 'bg-cyan-50 text-cyan-700 ring-cyan-600/20',
        'Fully Applied': 'bg-green-50 text-green-700 ring-green-600/20',
    });

    const openWorkOrderStatuses = [
        'New',
        'Requested',
        'Scheduled Awaiting Completion',
        'Awaiting Confirmation',
    ];

    // Computed
    const mapCenter = computed(() => {
        return {
            lat: parseFloat(data.currentServiceSnapshot.location?.address?.latitude) - parseFloat('0.001'),
            lng: parseFloat(data.currentServiceSnapshot.location?.address?.longitude)
        }
    });

    const googleMapsLocationUrl = computed(() => {
        let lat = data.currentServiceSnapshot.location?.address?.latitude;
        let lng = data.currentServiceSnapshot.location?.address?.longitude;
        let place_id = data.currentServiceSnapshot.location?.address?.place_id;

        let query = `&query=${lat},${lng}`;

        if(place_id) {
            query += `&query_place_id:${place_id}`;
        }

        return `https://www.google.com/maps/search/?api=1${query}`;
    });


    const monitoredServiceHasVendor = computed(() => {
        return props.compactorMonitor?.monitoredService?.vendorContract?.vendor ?? false;
    });

    const vendorHasWorkOrderEmailAddress = computed(() => {
        return (
            monitoredServiceHasVendor &&
            props.compactorMonitor.monitoredService.vendorContract.vendor.work_order_request_email_address
        );
    });

    const serviceHistoriesPreview = computed(() => {
        return props.serviceActivities.slice(0, 6);
    });

    const predictedPickupDateFormatted = computed(() => {
        if (!props.compactorMonitor.monitorConfiguration.projected_pickup_date) {
            return '';
        }

        let predictedPickupDate = parseISO(props.compactorMonitor.monitorConfiguration.projected_pickup_date);
        let daysOut = differenceInCalendarDays(predictedPickupDate, new Date());
        let dayText = daysOut + ' days';

        if (daysOut === 0) {
            dayText = 'Today';
        } else if (daysOut === -1) {
            dayText = 'Yesterday';
        } else if (daysOut === 1) {
            dayText = 'Tomorrow';
        }

        return `${format(predictedPickupDate, 'iii, MMM do')} (${dayText})`;
    });

    const upcomingPickupDateFormatted = computed(() => {
        if (!props.monitorSummary.upcoming_pickup_date) {
            return '';
        }

        let daysOut = differenceInCalendarDays(parseISO(props.monitorSummary.upcoming_pickup_date), new Date());
        let dayText = daysOut + ' days';

        if (daysOut === 0) {
            dayText = 'Today';
        } else if (daysOut === -1) {
            dayText = 'Yesterday';
        } else if (daysOut === 1) {
            dayText = 'Tomorrow';
        }

        return `${format(parseISO(props.monitorSummary.upcoming_pickup_date), 'iii, MMM do')} (${dayText})`;
    });

    const predictedPickupHelpText = computed(() => {
        if (props.monitorSummary.projected_pickup_type === 'max_days' && props.monitorSummary.projected_pickup_date) {
            return 'Max days expected to be met prior to compactor reaching target fullness.';
        } else if (props.monitorSummary.projected_pickup_type === 'forecast' && props.monitorSummary.projected_pickup_date) {
            return 'The forecasted date of the next pickup.';
        }
    });

    const fullnessInTons = computed(() =>  {
        return ((props.compactorMonitor.monitorConfiguration.percentage_full / 100) * props.compactorMonitor.monitorConfiguration.tonnage_capacity).toFixed(1);
    });

    const formattedLastPickup = computed(() => {
        if (!props.compactorMonitor.monitorConfiguration.last_picked_up && !props.lastPickupWorkOrder?.occurred_at) {
            return '';
        }

        let lastPickedUp = parseISO(props.lastPickupWorkOrder.occurred_at ?? props.compactorMonitor.monitorConfiguration.last_picked_up);
        return `${format(lastPickedUp, 'iii, MMM do')}`;
    });

    const availableTabs = computed(() => {
        return tabs.value.filter(tab => {
            // Filter out tabs based on tenant available features here
            return true;
        });
    });

    const serviceStatusClasses = computed(() => {
        let statuses = {
            'Active': 'bg-green-50 text-green-700 ring-green-600/20',
            'Terminated': 'bg-red-50 text-red-700 ring-red-600/20',
        }

        return statuses[data.currentServiceSnapshot.status]
            ?? 'bg-gray-50 text-gray-500 ring-gray-600/20';
    });

    const filteredBinData = computed(() => {
        // filter out compaction method and compaction ratio if the bin is not compacted or baled
        if(data.currentServiceSnapshot.bin.is_compacted_or_baled !== true) {
            delete data.currentServiceSnapshot.bin.compaction_ratio;
            delete data.currentServiceSnapshot.bin.compaction_method;
        }

        // Filter everything where their value is null, undefined, an empty string, or 'Unsure'
        return Object.entries(data.currentServiceSnapshot.bin)
            .filter(([key, value]) => {
                return value !== null && value !== undefined && value !== '' && value !== 'Unsure';
            }).map(([key, value]) => {
                value = value === true ? 'Yes' : value === false ? 'No' : value;
                return [key, value];
            });
    });

    // Watchers
    // watch(() => 'data.monitorConfigurationForm.send_external_notifications', function (newValue) {
    //     if (props.compactorMonitor?.id) {
    //         if (vendorHasWorkOrderEmailAddress) {
    //             data.monitorConfigurationForm.notify_vendor_notification_contacts = newValue;
    //         }
    //     }
    // });

    // watch(() => data.serviceChangesToggle, function (newValue) {
    //     if (newValue) {
    //         data.serviceChangesSort = 'recorded_date';
    //         data.serviceChanges.sort((a, b) => new Date(b.recorded_at) - new Date(a.recorded_at));
    //     } else {
    //         data.serviceChangesSort = 'actual_date';
    //         data.serviceChanges.sort((a, b) => (new Date(b.actual_date) - new Date(a.actual_date)));
    //     }
    // });

    // Mounted hook
    onMounted(() => {
        // Register listeners
        registerWebhookListeners(props.service.id);

        // Run some checks
        checkURLForTab();
        checkCompactorMonitor();

        // Build the badges for some tabs
        countIncidentsForBadge();
        countWorkOrdersForBadge();

        // Initialize tippy for the copy button
        useTippy(clickToCopy.value, {
            content: 'Copy Address',
            placement: 'top',
            onHidden(instance) {
                instance.setContent('Copy Address');
            },
        });
        useTippy(clickToMap.value, {
            content: 'View Map',
            placement: 'top',
            onHidden(instance) {
                instance.setContent('View Map');
            },
        });


        // Make sure we show in state that the component has been mounted
        data.mounted = true;
    });

    // Unmounted hook
    onBeforeUnmount(() => {
        unregisterWebhookListeners(props.service.id);
    });

    // Methods
    function registerWebhookListeners(serviceId) {
        Echo.join(`services.${serviceId}`)
            .here((users) => {
                data.otherUsers = users;
            })
            .joining((user) => {
                data.otherUsers.push(user);
            })
            .leaving((user) => {
                data.otherUsers = data.otherUsers.filter(otherUser => otherUser.id !== user.id);
            })
            .listen('.App\\Events\\Service\\ReplayServiceProjectorJobHasStarted', (e) => {
                toast.success('Rebuilding service history, please wait a moment.');
                data.state = 'rebuilding';
            })
            .listen('.App\\Events\\Service\\ReplayServiceProjectorJobHasFinished', (e) => {
                toast.success('Service history has been rebuilt.');
                data.state = 'passive';
                router.get(route('services.show', serviceId));
            });
    }

    function unregisterWebhookListeners(serviceId) {
        Echo.leave(`services.${serviceId}`);
    }

    function checkURLForTab() {
        // If the URL has a fragment, set the current tab to that fragment
        if (window.location.hash) {
            data.currentTab = window.location.hash.replace('#', '');
        }
    }

    function checkCompactorMonitor() {
        if (props.compactorMonitor?.id) {
            checkPioneerAttention();
        }
    }

    function countIncidentsForBadge() {
        if (props.incidents.length) {
            tabs.value.find(tab => tab.key === 'incidents').badge = props.incidents.filter(function (i) {
                return i.status !== 'Closed';
            }).length;
        }
    }

    function countWorkOrdersForBadge() {
        if (props.workOrders.length) {
            tabs.value.find(tab => tab.key === 'work-orders').badge = props.workOrders.filter(function (w) {
                return openWorkOrderStatuses.includes(w.decorated_status);
            }).length;
        }
    }

    function feeDates(fee) {
        return fee.end_date
            ? filters.format_date(fee.start_date) + '-' + filters.format_date(fee.end_date)
            : filters.format_date(fee.start_date);
    }

    function serviceDates() {
        let snapshot = data.currentServiceSnapshot;
        let status = snapshot.status;
        let effective_date = snapshot.effective_date;
        let termination_date = snapshot.termination_date;

        return {
            'Terminated': 'Terminated ' + filters.format_date(termination_date),
            'Pending Activation': 'Activating on ' + filters.format_date(effective_date),
            'Active': 'Active since ' + filters.format_date(effective_date),
        }[status];

        // let snapshotDate = snapshot.recorded_at ?? format(new Date(), 'yyyy-MM-dd');
        // console.log(snapshotDate, snapshot.effective_date);
        // return snapshot.termination_date
        //     ? filters.format_date(snapshot.effective_date) + ' - ' + filters.format_date(snapshot.termination_date)
        //     : (snapshot.effective_date <= snapshotDate ? 'Active since ' :  'Activating on ') + filters.format_date(snapshot.effective_date);
    }

    function getIncidentStatusDate(incident) {
        let statusChanges = incident.history.filter(h => h.what === 'Status Changed');
        return filters.format_date(statusChanges[statusChanges.length - 1].when);
    }

    function hasRequiredCustomDescriptionFields(snapshot) {
        let fields = [
            snapshot.bin.material_type,
            snapshot.bin.quantity,
            snapshot.bin.size_metric,
            snapshot.bin.size,
            snapshot.bin.type,
            snapshot.pickup_rrule_text,
            snapshot.vendor.name,
        ];

        return fields.every(field => field);
    }

    function pickupFrequency() {
        let snapshot = data.currentServiceSnapshot;
        let freq = snapshot.pickup_rrule_text;
        let plural = snapshot.average_pickups_per_week === 1 ? ' time ' : ' times ';

        return freq === 'weekly'
            ? snapshot.average_pickups_per_week + plural + freq
            : freq;
    }

    function formatMetric(metric, qty) {
        const pluralRule = new Intl.PluralRules();
        const map = {
            'yards': 'yard',
            'gallons': 'gallon',
        };

        metric = metric.toLowerCase();
        let plural = pluralRule.select(qty);
        return plural === 'one' && map.hasOwnProperty(metric) ? map[metric] : metric;
    }

    function tabClasses(key) {
        return key === data.currentTab
            ? 'border-d-orange-500 text-d-orange-600'
            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-800';
    }

    function tabBadgeClasses(key) {
        return key === data.currentTab
            ? 'bg-gray-50 text-d-orange-500 ring-d-orange-500/20'
            : 'bg-gray-50 text-gray-500 ring-gray-500/10';
    }

    function addPendingFileUploads(e) {
        [...e.target.files].forEach(file => {
            let pendingFileUpload = {
                file: file,
                name: file.name,
                notes: '',
                uploading: false
            };

            data.pendingFileUploads.push(pendingFileUpload);
            filePendingDocuments.value = null;
        });
    }

    function deleteService() {
        if (window.confirm('Are you sure you want to delete this service? This will delete all pickups associated with this service.')) {
            router.delete(route('services.destroy', props.service.id));
        }
    }

    function storePendingFileUpload(pendingDocument) {
        if (pendingDocument.uploading !== true) {
            let formData = new FormData();
            formData.append('file', pendingDocument.file);
            formData.append('notes', pendingDocument.notes);
            pendingDocument.uploading = true;

            router.post(route('services.service-documents.store', props.service.id), formData, {
                errorBag: 'pendingFileUpload',
                preserveScroll: true,
                onSuccess: () => {
                    removePendingFileUpload(pendingDocument);
                },
                onFinish: () => pendingDocument.uploading = false,
            });
        }
    }

    function removePendingFileUpload(pendingFileUpload) {
        data.pendingFileUploads.splice(data.pendingFileUploads.indexOf(pendingFileUpload), 1);
    }

    function removeDocument(serviceDocument) {
        let confirmed = confirm("Are you sure you wish to delete this document?");

        if (confirmed) {
            router.delete(route('service-documents.destroy', serviceDocument.id), {
                preserveScroll: true,
                onSuccess: () => {
                    // We may want to put a toast notification here or something
                },
                onFinish: () => {
                    // We may want to have some sort of completion to a loading state here
                },
            });
        }
    }

    function getEmployeeName(id) {
        let name = 'System';
        props.employees.forEach(employee => {
            if (employee.id === id) {
                name = employee.first_name.slice(0,1) + '.' + employee.last_name;
            }
        });
        return name;
    }

    function changeServiceSnapshot(snapshot) {
        data.currentServiceSnapshot = snapshot;
        if (screen.width <= 760) {
            window.scrollTo(0, 0);
        }
    }

    function translateChangeEvent(change, employeeMade) {
        if(!employeeMade) return change;

        // These are one-to-one with the ServiceChanges class
        let mappings = {
            'Activation': 'activated the service',
            'Client Billing Change': 'made a change to client billing',
            'Client Contract Change': 'changed the client contract',
            'Level of Service Change': 'made a change to the level of service',
            'Pickup Days Change': 'made a change to the pickup days',
            'Creating Service': 'created the service',
            'Service Created via Bulk Import': 'created the service via bulk import',
            'Terminating Service': 'terminated the service',
            'Termination Date Changed': 'changed the termination date',
            'Vendor Account Change': 'changed the vendor account',
            'Vendor Change': 'changed the vendor',
            'Vendor Contract Change': 'changed the vendor contract',
            'Vendor Pricing Change': 'made a change to vendor pricing',
            // Backfill changes
            'Service Updated': 'updated the service',
            'Attached to client contract (backfill)': 'backfilled the client contract',
            'Vendor account replacement (backfill)': 'backfilled the vendor account',
            'Client annual price increase (backfill)': 'backfilled the client annual price increase',
            'Marked as not having a vendor account (backfill)': 'backfilled no vendor account',
            'Vendor annual price increase (backfill)': 'backfilled the vendor annual price increase',
            'Vendor contract replaced (backfill)': 'backfilled the vendor contract',
            'Level of Service changed (backfill)': 'backfilled the level of service',
        }

        return mappings[change] ?? change;
    }

    function switchTab(tab) {
        let scrollPosition = window.scrollY;

        data.currentTab = tab.key;
        window.location.hash = `${tab.key}`;

        window.scroll(0, scrollPosition);
    }

    function mobileNavChange(e) {
        data.currentTab = e.target.value;
        window.location.hash = `${data.currentTab}`;
    }

    function copyToClipboard(textData) {
        navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
                navigator.clipboard.writeText(textData).then(
                    () => {
                        console.log('copied to clipboard');
                        clickToCopy.value._tippy.setContent('Copied to Clipboard');
                        clickToCopy.value._tippy.show();
                    },
                    () => {
                        clickToCopy.value._tippy.setContent('COPY FAILED');
                        clickToCopy.value._tippy.show();
                        console.error('failed to copy');
                    },
                );
            }
        });
    }

    function checkPioneerAttention() {
        axios.get(route('compactor-monitors.needs-attention.index', props.compactorMonitor.id)).then((response) => {
            data.pioneerNeedsAttention = response.data.needs_attention;
        })
    }

    function workOrderIsClosedOrComplete(status) {
        return !openWorkOrderStatuses.includes(status);
    }

    function routeToIncident(e, incidentId) {
        if(e.target.tagName !== 'A') {
            router.visit(route('incidents.show', incidentId));
        }
    }

    function routeToWorkOrder(e, workOrderId) {
        if(e.target.tagName !== 'A') {
            router.visit(route('work-orders.show', workOrderId));
        }
    }

    function routeToPickup(e, pickupId) {
        if(e.target.tagName !== 'A') {
            router.visit(route('pickups.show', pickupId));
        }
    }

    function routeToVendorServiceFee(e, feeId) {
        if(e.target.tagName !== 'A') {
            router.visit(route('vendor-service-fees.edit', feeId));
        }
    }

    function routeToClientServiceFee(e, feeId) {
        if(e.target.tagName !== 'A') {
            router.visit(route('client-service-fees.edit', feeId));
        }
    }

    function routeToVendorInvoice(e, invoiceId) {
        let target = e.target.tagName.toLowerCase();
        if(!['a', 'svg', 'path'].includes(target)) {
            router.visit(route('vendor-invoices.edit', invoiceId));
        }
    }

    function routeToClientInvoice(e, clientInvoiceId) {
        let target = e.target.tagName.toLowerCase();
        if(!['a'].includes(target)) {
            console.log(route('client-invoices.show', [clientInvoiceId]));
            router.visit(route('client-invoices.show', [clientInvoiceId]));
        }
    }

    function routeToClientCreditMemo(e, creditMemoId) {
        let target = e.target.tagName.toLowerCase();
        if(!['a'].includes(target)) {
            router.visit(route('client-credit-memos.edit', creditMemoId));
        }
    }

    function toggleVendorServiceFees(e) {
        if(e.target.tagName !== 'A') {
            data.vendorServiceFeeOpen = !data.vendorServiceFeeOpen;
        }
    }

    function toggleClientServiceFees(e) {
        if(e.target.tagName !== 'A') {
            data.clientServiceFeeOpen = !data.clientServiceFeeOpen;
        }
    }

    function toggleAdvancedOptions() {
        data.collapsed = !data.collapsed;
    }
</script>
