<template>
    <Head :title="`Edit Level of Service Change - ${serviceActivity.service_id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">
                {{ serviceActivity.service_id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Level of Service Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg">
        <div class="px-12">
            <div class="flex justify-between items-center my-6">
                <h1 class="text-2xl font-bold">Edit Level of Service Change</h1>

                <div class="flex items-center gap-x-4">
                    <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="btn btn-gray gap-x-2">
                        Cancel Edit
                    </inertia-link>
                    <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
                </div>
            </div>

            <fieldset class="border-t border-gray-300 mt-8">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Change Details
                </legend>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                    <revert-input v-model="form.actual_date" class="col-span-1 md:col-span-2" label="Effective Date" mark-as-required>
                        <date-input :min="serviceCreationActivity.actual_date" v-model="form.actual_date" help-text="Date of service change" :errors="errors['actual_date']"/>
                    </revert-input>

                    <revert-input v-model="form.change_requester" class="col-span-1 md:col-span-2" label="Requested By" mark-as-required>
                        <select-input v-model="form.change_requester" :errors="errors['change_requester']">
                            <option value="vendor">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                            <option value="client">{{ serviceActivity.serviceSnapshot.location.clientCompany.name }}</option>
                            <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.change_reason" class="col-span-1 md:col-span-2" label="Reason for Change" mark-as-required>
                        <select-input v-model="form.change_reason">
                            <option>Other</option>
                            <option>Savings</option>
                        </select-input>
                    </revert-input>
                </div>
            </fieldset>

            <fieldset class="border-t border-gray-300 mt-12">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Service
                </legend>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                    <revert-input v-model="form.vendor_account_id" class="col-span-1 md:col-span-2" label="Vendor Account">
                        <select-input v-model="form.vendor_account_id" :errors="errors['vendor_account_id']">
                            <option v-if="!vendorAccounts.length" :value="null">No accounts available for this Vendor</option>
                            <option v-for="vendorAccount in vendorAccounts" :value="vendorAccount.id">
                                {{ vendorAccount.account_number + " - " + vendorAccount.status }}
                            </option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.vendor_contract_selection" class="col-span-1 md:col-span-2" label="Vendor Contract">
                        <select-input v-model="form.vendor_contract_selection" :errors="errors['vendor_contract_selection']">
                            <option value='missing-contract'>Missing contract</option>
                            <option :value=null>No contract</option>
                            <option v-for="vendorContract in vendorContracts" :value="vendorContract.id" :key="vendorContract.id">
                                {{ vendorContract.display_id + " - " + vendorContract.status + " - Effective " + vendorContract.effective_date ?? " - " }}
                            </option>
                        </select-input>
                    </revert-input>

                    <revert-input v-if="$page.props.permissions.accessRevenueManagement" v-model="form.client_contract_id" class="col-span-1 md:col-span-2" label="Client Contract">
                        <select-input v-model="form.client_contract_id" :errors="errors['client_contract_id']">
                            <option v-if="clientContracts.length === 0" :value="null">No contracts available for this Client</option>
                            <option v-for="clientContract in clientContracts" :key="clientContract.id" :value="clientContract.id">
                                {{ clientContract.id + " - Integration " + clientContract.integration_date ?? " - " }}
                            </option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.service_account_type" class="col-span-1 md:col-span-2" label="Service Account Type" mark-as-required>
                        <select-input v-model="form.service_account_type" :errors="errors['service_account_type']">
                            <option>Permanent</option>
                            <option>Temporary</option>
                        </select-input>
                    </revert-input>

                    <revert-input
                        v-if="serviceHasScheduleInfo"
                        v-model="form.service_schedule_type"
                        class="col-span-1 md:col-span-2"
                        label="Service Schedule Type"
                        mark-as-required
                    >
                        <select-input v-model="form.service_schedule_type" :errors="errors['service_schedule_type']">
                            <option>Scheduled</option>
                            <option>On Call</option>
                            <option v-if="form.service_account_type==='Temporary'">One Time</option>
                        </select-input>
                    </revert-input>

                    <div v-if="serviceHasScheduleInfo && form.service_schedule_type === 'Scheduled'" class="col-span-1 md:col-span-6">
                        <div class="flex flex-col items-start gap-6 lg:gap-12 mt-2 md:flex-row">
                            <revert-input v-model="form.pickup_rrule" label="Schedule" mark-as-required>
                                <div class="md:flex-shrink-0" :class="[form?.pickup_rrule?.freq === 'WEEKLY' ? 'md:max-w-48' : 'md:max-w-full']">
                                    <div class="flex flex-col gap-3">
                                        <span>{{ rrule_description }}</span>
                                        <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500 text-sm">
                                            The pickup schedule is invalid
                                        </div>
                                        <a href="#" class="" @click.prevent="showUpdatePickupRRuleModal">
                                            Edit Schedule <icon name="edit" class="inline fill-current w-5 h-6"></icon>
                                        </a>
                                    </div>
                                </div>
                            </revert-input>

                            <div class="w-full md:flex-1" v-if="form?.pickup_rrule?.freq === 'WEEKLY'">
                                <div class="w-full overflow-hidden rounded-lg grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                                    <div v-for="(day, id) in daysOfWeek" :key="id" class="w-full group relative text-center px-2">
                                        <div class="text-base border-b pb-1">{{ day }}</div>
                                        <div class="flex justify-center items-center p-4 pb-8 lg:pb-0">
                                            <icon name="check" class="text-green-500 fill-current size-4" v-if="form?.pickup_rrule?.byday.includes(id)"/>
                                            <icon name="times" class="text-gray-300 fill-current size-4" v-else />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="border-t border-gray-300 mt-12">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Billing
                </legend>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                    <revert-input
                        v-if="serviceHasBaseCharge"
                        v-model="form.vendor_base_charge"
                        class="col-span-1 md:col-span-2"
                        label="Vendor Base Charge"
                        mark-as-required
                    >
                        <money-input
                            v-model="form.vendor_base_charge"
                            :defaultCurrency="$page.props.currency"
                            :errors="errors['vendor_base_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="serviceHasPerUnitCharge"
                        v-model="form.vendor_per_unit_charge"
                        class="col-span-1 md:col-span-2"
                        :label="`Vendor Per Unit Charge${perTonLabel}`"
                        mark-as-required
                    >
                        <money-input
                            v-model="form.vendor_per_unit_charge"
                            :defaultCurrency="$page.props.currency"
                            :errors="errors['vendor_per_unit_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="serviceHasPerOccurrenceCharge"
                        v-model="form.vendor_per_occurrence_charge"
                        class="col-span-1 md:col-span-2"
                        label="Vendor Per Occurrence Charge (Haul Amount)"
                        mark-as-required
                    >
                        <money-input
                            v-model="form.vendor_per_occurrence_charge"
                            :defaultCurrency="$page.props.currency"
                            :errors="errors['vendor_per_occurrence_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="serviceHasUnitsIncludedPerOccurrence"
                        v-model="form.units_included_per_occurrence"
                        class="col-span-1 md:col-span-2"
                        label="Units Included Per Occurrence (Tons Included Per Haul)"
                        mark-as-required
                    >
                        <text-input
                            v-model="form.units_included_per_occurrence"
                            class="col-span-1 md:col-span-2"
                            type="number"
                            min="0"
                            :errors="errors['units_included_per_occurrence']"
                            mark-as-required
                        />
                    </revert-input>

                    <template v-if="$page.props.permissions.accessRevenueManagement && serviceActivity.serviceSnapshot.is_pass_through === false && serviceActivity.serviceSnapshot.client_billing_frequency !== 'Not Billable'">
                        <revert-input
                            v-if="serviceHasBaseCharge"
                            v-model="form.client_base_charge"
                            class="col-span-1 md:col-span-2"
                            label="Client Base Charge"
                            mark-as-required
                        >
                            <money-input
                                v-model="form.client_base_charge"
                                :defaultCurrency="$page.props.currency"
                                :errors="errors['client_base_charge']"
                            />
                        </revert-input>

                        <revert-input
                            v-if="serviceHasPerUnitCharge"
                            v-model="form.client_per_unit_charge"
                            class="col-span-1 md:col-span-2"
                            :label="`Client Per Unit Charge${perTonLabel}`"
                            mark-as-required
                        >
                            <money-input
                                v-model="form.client_per_unit_charge"
                                :defaultCurrency="$page.props.currency"
                                :errors="errors['client_per_unit_charge']"
                            />
                        </revert-input>

                        <revert-input
                            v-if="serviceHasPerOccurrenceCharge"
                            v-model="form.client_per_occurrence_charge"
                            class="col-span-1 md:col-span-2"
                            label="Client Per Occurrence Charge (Haul Amount)"
                            mark-as-required
                        >
                            <money-input
                                v-model="form.client_per_occurrence_charge"
                                :defaultCurrency="$page.props.currency"
                                :errors="errors['client_per_occurrence_charge']"
                            />
                        </revert-input>
                    </template>
                </div>
            </fieldset>

            <fieldset v-if="serviceHasBin" class="border-t border-gray-300 mt-12">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Bin
                </legend>

                <div class="w-full mt-4 px-4">
                    <revert-input class="md:w-1/3" label="Equipment Label" v-model="form.label">
                        <text-input v-model="form.label" maxlength="50" :errors="errors['label']"/>
                    </revert-input>
                </div>

                <div class="px-4">
                    <revertible-bin
                        :property-type="serviceActivity.serviceSnapshot.location?.property_type"
                        :bin-types="binTypes"
                        :material-types="materialTypes"
                        :asset-owners="assetOwners"
                        v-model="form.bin"
                        :fields-are-required="true"
                        :errors="errors"
                    />
                </div>
            </fieldset>

            <div class="relative mt-12">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
                </div>
            </div>

            <div class="px-4 mt-4 mb-8">
                <revert-input v-model="form.internal_change_notes" label="Internal Change Notes">
                    <textarea-input rows="4" v-model="form.internal_change_notes"/>
                </revert-input>
            </div>

            <loading-button class="btn btn-orange" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">
                Submit Change
            </loading-button>
        </div>
    </div>

    <!-- Pickup RRule Modal -->
    <modal
        v-if="serviceActivity.service.serviceType.enabled_type_fields.includes('service_schedule_info')"
        class="max-w-2xl"
        ref="updatePickupRRuleModal"
        tabindex="-1"
        role="dialog"
    >
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl">
                    Update Schedule
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon name="times" class="fill-current inline w-6 h-6"/>
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <r-rule-input
                    ref="rRuleInput"
                    v-model:modelValue="form.pickup_rrule"
                    v-model:weeklyAverage="form.average_pickups_per_week"
                    v-model:description="rrule_description"
                    start-date-label="First Pickup"
                    weekly-average-label="Average Pickups Per Week"
                />
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4">
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
            </div>
        </template>
    </modal>
</template>

<script setup>
/**
 * This page is a Wizard to help user along with service changes
 */
import DateInput from '@/Shared/DateInput.vue';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import MoneyInput from '@/Shared/MoneyInput.vue';
import RevertInput from '@/Shared/RevertInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import Modal from "@/Shared/Modal.vue";
import RRuleInput from "@/Shared/RRuleInput.vue";
import TextInput from "@/Shared/TextInput.vue";
import RevertibleBin from "@/Shared/Services/RevertibleBin.vue";
import axios from "axios";
import {format, startOfToday} from "date-fns";
import {reactive, ref, inject, computed, onMounted, watch} from 'vue';
import {Head, router, usePage} from '@inertiajs/vue3';

/**
 * Props
 */
const props = defineProps({
    assetOwners: {
        type: Array,
        required: true
    },

    binTypes: {
        type: Array,
        required: true
    },

    clientContracts: {
        type: Array,
        required: true
    },

    errors: {
        type: Object,
        default: () => ({})
    },

    materialTypes: {
        type: Array,
        required: true
    },

    serviceActivity: {
        type: Object,
        required: true
    },

    serviceCreationActivity: {
        type: Object,
        required: true
    },

    vendorAccounts: {
        type: Array,
        required: true
    },

    vendorContracts: {
        type: Array,
        required: true
    },
});

/**
 * Injected
 */
const route = inject('route');
const page = usePage();

onMounted(() => {
    mounted.value = true;
});

/**
 * Data
 */
const saving = ref(false);
const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
const mounted = ref(false);
const rrule_description = ref(null);
const updatePickupRRuleModal = ref(null);
const priorPeriodAdjustment = ref(null);
const form = reactive({
    actual_date: props.serviceActivity.actual_date,
    change_reason: props.serviceActivity.reason,
    change_requester: props.serviceActivity.change_requester,
    internal_change_notes: props.serviceActivity.internal_change_notes,
    vendor_account_id: props.serviceActivity.serviceSnapshot.vendor_account_id,
    vendor_contract_id: props.serviceActivity.serviceSnapshot.vendor_contract_id,
    vendor_contract_selection: props.serviceActivity.serviceSnapshot.vendor_contract_selection,
    client_contract_id: props.serviceActivity.serviceSnapshot.client_contract_id,
    is_pass_through: props.serviceActivity.serviceSnapshot.is_pass_through,
    service_account_type: props.serviceActivity.serviceSnapshot.service_account_type,
    service_schedule_type: props.serviceActivity.serviceSnapshot.service_schedule_type,
    pickup_rrule: props.serviceActivity.serviceSnapshot.pickup_rrule,
    average_pickups_per_week: props.serviceActivity.serviceSnapshot.average_pickups_per_week,
    vendor_base_charge: props.serviceActivity.serviceSnapshot.vendor_base_charge ?? null,
    vendor_per_unit_charge: props.serviceActivity.serviceSnapshot.vendor_per_unit_charge ?? null,
    vendor_per_occurrence_charge: props.serviceActivity.serviceSnapshot.vendor_per_occurrence_charge ?? null,
    units_included_per_occurrence: props.serviceActivity.serviceSnapshot.units_included_per_occurrence ?? null,
    client_billing_frequency: props.serviceActivity.serviceSnapshot.client_billing_frequency,
    client_base_charge: props.serviceActivity.serviceSnapshot.client_base_charge ?? null,
    client_per_unit_charge: props.serviceActivity.serviceSnapshot.client_per_unit_charge ?? null,
    client_per_occurrence_charge: props.serviceActivity.serviceSnapshot.client_per_occurrence_charge ?? null,
    label: props.serviceActivity.serviceSnapshot.label,
    bin: {...props.serviceActivity.serviceSnapshot.bin},
});

const daysOfWeek = reactive({
    'SU': 'Sunday',
    'MO': 'Monday',
    'TU': 'Tuesday',
    'WE': 'Wednesday',
    'TH': 'Thursday',
    'FR': 'Friday',
    'SA': 'Saturday',
});

/**
 * Methods
 */
function submitChange()
{
    saving.value = true;

    router.put(route('services.level-of-service-changes.update', [props.serviceActivity.service_id, props.serviceActivity.id]), {
        ...form,
        ppa_amount: showPriorPeriodAdjustment ? priorPeriodAdjustment.value?.proratedAmount : null,
        ppa_description: showPriorPeriodAdjustment ? priorPeriodAdjustment.value?.description : null,
    }, {
        onFinish: () => saving.value = false
    });
}

function showUpdatePickupRRuleModal()
{
    updatePickupRRuleModal.value.show();
}

function deleteServiceChange()
{
    if (window.confirm('Are you sure you want to delete this level of service change?')) {
        router.delete(route('services.level-of-service-changes.destroy', {
            serviceId: props.serviceActivity.service_id,
            serviceActivityId: props.serviceActivity.id
        }));
    }
}

function suggestPriorPeriodAdjustment() {
    if (!page.props.permissions.accessRevenueManagement) {
        return false;
    }

    if (form.is_pass_through) {
        return false;
    }

    if (!['Monthly', 'Quarterly', 'Yearly'].includes(form.client_billing_frequency)) {
        return false;
    }

    if (props.serviceActivity.serviceSnapshot.service_schedule_type === 'One Time') {
        return false;
    }

    if (!props.serviceActivity.service.serviceType.enabled_type_fields.includes('base_charges')) {
        return false;
    }

    if (props.serviceActivity.serviceSnapshot.client_base_charge === form.client_base_charge) {
        return false;
    }

    return true;
}

function updatePriorPeriodAdjustmentDetails() {
    axios.get(`/json/services/${props.serviceActivity.service_id}/prior-period-adjustments/create-from-price-change?change_date=${form.actual_date}&adjusted_amount=${form.client_base_charge.amount / 100}`).then(response => {
        props.priorPeriodAdjustment = response.data
    });
}

/**
 * Computed
 */
const serviceHasBin = computed(() => {
    return props.serviceActivity.service.serviceType.enabled_type_fields.includes('bin');
});

const serviceHasBaseCharge = computed(() => {
    return props.serviceActivity.service.serviceType.enabled_type_fields.includes('base_charges');
});

const serviceHasPerUnitCharge = computed(() => {
    return props.serviceActivity.service.serviceType.enabled_type_fields.includes('per_unit_charges');
});

const serviceHasPerOccurrenceCharge = computed(() =>{
    return props.serviceActivity.service.serviceType.enabled_type_fields.includes('per_occurrence_charges');
});

const serviceHasScheduleInfo = computed(() => {
    return props.serviceActivity.service.serviceType.enabled_type_fields.includes('service_schedule_info');
});

const serviceHasUnitsIncludedPerOccurrence = computed(() => {
    return props.serviceActivity.service.serviceType.enabled_type_fields.includes('units_included_per_occurrence');
});

const perTonLabel = computed(() => {
    return props.serviceActivity.service.serviceType.enabled_type_fields.includes('per_unit_charges')
        ? " (Disposal Amount Per Ton)"
        : "";
});

const showPriorPeriodAdjustment = computed(() => {
    return suggestPriorPeriodAdjustment()
        && priorPeriodAdjustment.value?.proratedAmount?.amount
        && priorPeriodAdjustment.value?.proratedAmount?.amount !== 0;
});

/**
 * Watchers
 */
watch(() => form.client_base_charge, () => {
    if (suggestPriorPeriodAdjustment()) {
        updatePriorPeriodAdjustmentDetails();
    }
});

watch(() => form.actual_date, () => {
    if (suggestPriorPeriodAdjustment()) {
        updatePriorPeriodAdjustmentDetails();
    }
});
</script>
