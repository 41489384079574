<template>
    <Head :title="`Edit Vendor Invoice - ${vendorInvoice.invoice_number}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendor-invoices.index')" class="breadcrumb-link">Audit - Vendor Invoices</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="flex flex-wrap justify-between items-center my-4">
        <horizontal-sub-nav
            :permissions="$page.props.permissions"
            current-tab="vendor-invoice"
            :vendor-invoice-id="vendorInvoice.id"
            :ocr-vendor-invoice-id="vendorInvoice.ocr_vendor_invoice_id"
            :gathered-vendor-invoice-file-id="gatheredVendorInvoiceFileId"
        />

        <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1">
            <template v-for="user in otherUsers" :key="user.id">
                <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
                <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                    <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                </span>
            </template>
        </div>
    </div>

    <div class="border border-gray-400 mb-4 px-4 py-2">
        <div class="px-3 py-2 w-full flex flex-wrap justify-between">
            <div class="mb-4 font-semibold text-lg xl:mb-0">
                Invoice #<copy-to-clipboard-button :value="vendorInvoice.invoice_number" class="text-gray-700 font-medium">{{ vendorInvoice.invoice_number }}</copy-to-clipboard-button>
                <div>
                    <span v-if="vendorInvoice.archived_at">
                        Archived at {{ $filters.format_date_time(vendorInvoice.archived_at) }}
                        <span v-if="vendorInvoice.archivedBy"> by {{ vendorInvoice.archivedBy.name }}</span>
                    </span>
                </div>
            </div>

            <div class="flex flex-row-reverse my-4">
                <loading-button v-if="props.invoiceCanBeDeleted" :loading="state === 'deleting'" @click="deleteInvoice" type="button" class="btn btn-red ml-2">Delete</loading-button>

                <loading-button v-if="!vendorInvoice.archived_at" :loading="state === 'archiving'" @click.prevent="archiveVendorInvoice" class="btn btn-gray ml-2">
                    Archive
                </loading-button>

                <loading-button v-else :loading="state === 'unarchiving'" @click.prevent="unarchiveVendorInvoice" class="btn btn-gray ml-2">
                    Unarchive
                </loading-button>

                <a :href="$route('vendor-invoices.pdf', vendorInvoice.id)" class="btn btn-gray ml-2" target="_blank">Download PDF</a>
                <button @click="showEditInvoiceModal" type="button" class="btn btn-gray ml-2" :class="{disabled: vendorInvoice.approved_at}">Edit</button>
            </div>
        </div>

        <div v-if="Object.keys(props.vendorInvoice.auto_approval_errors).length !== 0" class="bg-yellow-100 rounded shadow-md p-4">
            <span class="text-l"><strong>This invoice could not be auto approved due to the following reason(s):</strong></span>
            <li
                v-if="Object.keys(props.vendorInvoice.auto_approval_errors).includes('line_item_errors')
                    || Object.keys(props.vendorInvoice.auto_approval_errors).includes('vendorFeeMissing')"
                class="text-l"
            >
                There is an issue with 1 or more line items.  Please review the line item(s) for more information on the error.
            </li>
            <li v-for="(error, index) in filteredAutoApprovalErrors" :key="index" class="text-l">
                {{ error }}
            </li>
        </div>

        <div v-if="props.vendorInvoice.external_accounting_error" class="bg-yellow-100 rounded shadow-md p-4">
            <span class="text-l"><strong>This invoice has an external accounting error:</strong></span>

            <li class="text-l">
                {{ props.vendorInvoice.external_accounting_error }}
            </li>
        </div>

        <div v-if="alerts.length !== 0" class="rounded-md bg-yellow-100 p-4 my-2">
            <div class="flex">
                <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
                    </svg>
                </div>

                <div class="ml-3">
                    <h3 class="text-lg leading-5 font-medium text-yellow-900">
                        Attention
                    </h3>
                    <div class="mt-2 leading-5 text-yellow-800">
                        <p v-for="(alert, index) in alerts" :key="index">
                            {{ alert }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Error Display -->
        <div v-if="processingErrorMessage" class="bg-red-100 rounded shadow-md p-4 my-4">
            <span class="font-semibold text-xl">
                Processing Errors:
            </span>

            <p> {{ processingErrorMessage }} </p>
        </div>


        <div class="px-3 py-2">
            <div class="my-2 grid grid-cols-2 gap-x-4 gap-y-6">
                <div class="col-span-2 lg:col-span-1">
                    <h3 class="block text-xl font-semibold tracking-wide text-d-blue-500 uppercase">Invoice Details</h3>

                    <hr class="separator">

                    <div class="px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Vendor
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('vendors.edit', invoice.vendorAccount.vendor_id)" class="link">
                                        {{ invoice.vendorAccount.vendor.name ?? '' }}
                                    </inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Account Number
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link :href="$route('vendor-accounts.show', [invoice.vendor_account_id])" class="link">{{ invoice.vendorAccount.account_number }}</inertia-link>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Invoice Date
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_date(invoice.invoice_date) }}
                                </dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Fiscal Period
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_date(invoice.fiscal_period) }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Total Charges
                                </dt>
                                <dd v-if="invoice.total_current_charges.amount === invoice.adjusted_total_current_charges.amount" class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_money(invoice.total_current_charges) }}
                                </dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">
                                    <span class="line-through text-gray-500 mr-2">{{ $filters.format_money(invoice.total_current_charges) }}</span>
                                    <span class="text-green-600">{{ $filters.format_money(invoice.adjusted_total_current_charges) }}</span>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Adjustment Amount
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_money(subtract_money(invoice.adjusted_total_current_charges, invoice.total_current_charges)) }}
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Balance
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ $filters.format_money(invoice.balance) }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <!-- This example requires Tailwind CSS v2.0+ -->
                <div class="col-span-3 lg:col-span-1">
                    <h3 class="block text-xl font-semibold tracking-wide text-d-blue-500 uppercase">
                        Invoice Progress: <span class="text-gray-600 font-medium">{{ progressPercentage }}</span>
                    </h3>

                    <hr class="separator">

                    <nav aria-label="Progress">
                        <ol role="list" class="overflow-hidden">
                            <li v-for="(step, stepIdx) in steps" :key="step.name" :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']">
                                <template v-if="step.state === 'complete'">
                                    <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-d-blue-600" aria-hidden="true" />

                                    <a :href="step.href" class="relative flex items-start group" @click.prevent="transitionTo(step.name)">
                                        <span class="h-9 flex items-center">
                                            <span class="cursor-pointer relative z-10 w-8 h-8 flex items-center justify-center bg-d-blue-600 rounded-full group-hover:bg-d-blue-800">
                                                <icon :name="step.action_state === state ? 'sync-alt' : 'check'" class="w-5 h-5 fill-current text-white" :class="{'animate-spin': step.action_state === state}" aria-hidden="true" />
                                            </span>
                                        </span>
                                        <span class="ml-4 min-w-0 flex flex-col">
                                            <span class="text-xs font-semibold tracking-wide uppercase">{{ step.name }}</span>
                                            <span class="text-sm text-gray-500">{{ step.description }}</span>
                                        </span>
                                    </a>
                                </template>

                                <template v-else-if="step.state === 'current'">
                                    <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />

                                    <a :href="step.href" class="relative flex items-start group" aria-current="step" @click.prevent="transitionTo(step.name)">
                                        <span class="h-9 flex items-center" aria-hidden="true">
                                            <span class="cursor-pointer relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-d-blue-600 rounded-full">
                                                <span v-if="step.action_state !== state" class="h-2.5 w-2.5 bg-d-blue-600 rounded-full" />
                                                <icon v-else name="sync-alt" class="w-5 h-5 fill-current text-d-blue-600 animate-spin" aria-hidden="true" />
                                            </span>
                                        </span>
                                        <span class="ml-4 min-w-0 flex flex-col">
                                            <span class="text-xs font-semibold tracking-wide uppercase text-d-blue-600">{{ step.name }}</span>
                                            <span class="text-sm text-gray-500">{{ step.description }}</span>
                                        </span>
                                    </a>
                                </template>

                                <template v-else-if="step.state === 'current-warning'">
                                    <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />

                                    <a :href="step.href" class="relative flex items-start group" aria-current="step" @click.prevent="transitionTo(step.name)">
                                        <span class="h-9 flex items-center" aria-hidden="true">
                                            <span class="cursor-pointer relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-orange-600 rounded-full">
                                                <span v-if="step.action_state !== state" class="h-2.5 w-2.5 bg-orange-600 rounded-full" />
                                                <icon v-else name="sync-alt" class="w-5 h-5 fill-current text-orange-600 animate-spin" aria-hidden="true" />
                                            </span>
                                        </span>
                                        <span class="ml-4 min-w-0 flex flex-col">
                                            <span class="text-xs font-semibold tracking-wide uppercase text-orange-600">{{ step.name }}</span>
                                            <span class="text-sm text-gray-500">{{ step.description }}</span>
                                        </span>
                                    </a>
                                </template>

                                <template v-else>
                                    <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />

                                    <a :href="step.href" class="relative flex items-start group" @click.prevent="transitionTo(step.name)">
                                        <span class="h-9 flex items-center" aria-hidden="true">
                                            <span class="cursor-pointer relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                                <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                            </span>
                                        </span>
                                        <span class="ml-4 min-w-0 flex flex-col">
                                            <span class="text-xs font-semibold tracking-wide uppercase text-gray-500">{{ step.name }}</span>
                                            <span class="text-sm text-gray-500">{{ step.description }}</span>
                                        </span>
                                    </a>
                                </template>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div class="space-y-6 my-6">
                <section aria-labelledby="work-orders-title">
                    <div class="bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="work-orders-title" class="text-lg leading-6 font-medium text-gray-900">Work Orders</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Work Orders for services on this invoice.</p>
                        </div>

                        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <div v-if="workOrders.length" class="flex flex-col">
                                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Status</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Requested Action</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Location Name</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Service</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Hauler Reference #</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Requested By</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Scheduled For</th>
                                                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Occurred On</th>
                                                    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                        <span class="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="divide-y divide-gray-200 bg-white">
                                                <tr v-for="workOrder in workOrders.slice(0, 12)" :key="workOrder.id">
                                                    <td class="px-2 py-2 text-sm font-medium text-gray-900">{{ workOrder.decorated_status }}</td>
                                                    <td>{{ workOrder.requested_action }}</td>
                                                    <td>
                                                        <inertia-link :href="$route('locations.show', [workOrder.service.location_id])" class="link">
                                                            {{ workOrder.service.location.name }}
                                                        </inertia-link>
                                                    </td>
                                                    <td>
                                                        <inertia-link :href="$route('services.show', [workOrder.service_id])" class="link">
                                                            {{ workOrder.service_id }}
                                                        </inertia-link>
                                                    </td>
                                                    <td>{{ workOrder.hauler_reference_number ?? 'Not Set' }}</td>
                                                    <td>
                                                        <span v-if="workOrder.requestedBy" class="font-medium">{{ workOrder.requestedBy.name }}</span>
                                                        <span v-else class="font-400 italic">{{ workOrder.source }}</span>
                                                    </td>
                                                    <td>{{ $filters.format_date(workOrder.scheduled_with_vendor_date)  }}</td>
                                                    <td>{{ $filters.format_date(workOrder.occurred_at) }}</td>
                                                    <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <div class="flex">
                                                            <inertia-link :href="$route('work-orders.show', [workOrder.id])" class="link mr-2">
                                                                View<span class="sr-only">, {{ workOrder.id }}</span>
                                                            </inertia-link>

                                                            <icon v-if="lineItems.filter(lineItem => lineItem.work_order_id === workOrder.id).length !== 0" name="check" class="ml-2 h-5 w-5 text-green-400 fill-current" />
                                                            <icon v-else name="times" class="ml-2 h-5 w-5 text-red-400 fill-current" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div v-else class="text-center">
                                <icon name="clipboard" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                                <h3 class="mt-2 text-sm font-medium text-gray-900">There are no work orders available for this invoice.</h3>
                            </div>
                        </div>

                        <div v-if="workOrders.length">
                            <inertia-link :href="$route('work-orders.index', {search: vendorInvoice.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                        </div>
                    </div>
                </section>
            </div>

            <div class="my-3">
                <div class="flex justify-between items-center">
                    <span class="text-xl font-semibold tracking-wide text-d-blue-600 uppercase">Line Items</span>
                    <icon :name="expandLineItems ? 'chevron-down' : 'chevron-right'" @click="expandLineItems = !expandLineItems" class="w-6 h-6 fill-current cursor-pointer" />
                </div>

                <transition
                    enter-from-class="opacity-0"
                    enter-active-class="ease-out duration-200"
                    enter-to-class="opacity-100"
                    leave-from-class="opacity-100"
                    leave-active-class="ease-out duration-200"
                    leave-to-class="opacity-0"
                    appear>
                    <div v-show="expandLineItems" class="mb-8">
                        <div v-if="vendorInvoiceIsInPreAuditingStatus" class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
                            <div class="inline-flex items-center gap-2 py-2">
                                <input type="checkbox" :disabled="!lineItems.length" class="h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :checked="lineItems.length && (indeterminate || selectedLineItemIds.length === lineItems.length)" :indeterminate="indeterminate" @change="toggleSelectAllLineItems" />
                                <span class="text-lg">Select All Line Items</span>
                            </div>

                            <div class="justify-self-end">
                                <button v-if="selectedLineItemIds.length > 0" href="" class="btn btn-orange inline-flex items-center text-md font-medium" @click.prevent="showBulkEditLineItemsModal">
                                    Bulk Assign
                                </button>
                            </div>
                        </div>
                        <div v-if="lineItemsByService.length">
                            <div v-for="record in lineItemsByService" :key="record.service.id" class="w-full p-3">
                                <div class="flex flex-wrap my-2">
                                    <div class="w-full text-orange-400 font-semibold mr-2 md:w-auto">
                                        <a :href="$route('services.show', [record.service.id])" target="__blank">{{ record.service.id }}</a>
                                    </div>

                                    <div class="mr-2">
                                        <span class="text-gray-900 font-medium">Effective:</span> {{ $filters.format_date(record.service.effective_date) }}
                                    </div>

                                    <div v-if="record.service.termination_date" class="mr-2">
                                        <span class="text-gray-900 font-medium">Terminating:</span> {{ $filters.format_date(record.service.termination_date) }}
                                    </div>

                                    <div v-if="record.service.vendor_per_unit_charge" class="mr-2">
                                        <span class="text-gray-900 font-medium">Per Unit:</span> {{ $filters.format_money(record.service.vendor_per_unit_charge) }}
                                    </div>

                                    <div v-if="record.service.vendor_per_occurrence_charge" class="mr-2">
                                        <span class="text-gray-900 font-medium">Per Occurrence:</span> {{ $filters.format_money(record.service.vendor_per_occurrence_charge) }}
                                    </div>

                                    <div v-if="record.service.location.name" class="mr-3">
                                        <span class="text-gray-900 font-medium">Location:</span> <inertia-link class="link" :href="$route('locations.show', [record.service.location_id])">{{ record.service.location.name }}</inertia-link>
                                    </div>

                                    <div v-if="record.service.label" class="mr-3">
                                        <span class="text-gray-900 font-medium">Label:</span> {{ record.service.label }}
                                    </div>

                                    <div v-if="record.service.bin && record.service.bin.disposal_method === 'Recycling'" class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                                        <icon name="recycle" class="-ml-1 mr-2 flex-shrink-0 self-center h-5 w-5 text-green-500 fill-current" />

                                        <span class="sr-only">Disposal Method</span> Recycling
                                    </div>
                                </div>
                                <p class="my-2 mb-4 italic font-medium text-xs md:text-base lg:text-lg">{{ record.service.description }}</p>

                                <div v-if="record.regularCharges.length" class="my-2">
                                    <h3 class="px-2 py-1 -mx-2 font-medium bg-green-100 text-green-700">Base Charges</h3>

                                    <div class="overflow-x-auto">
                                        <table class="w-full border-collapse">
                                            <thead>
                                            <tr>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto"></th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Date</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Description</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Qty</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Price</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Total</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Fee</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">W.O</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Actions</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="lineItem in record.regularCharges" class="border border-gray-300 md:border-none">
                                                    <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                                        <input v-if="vendorInvoiceIsInPreAuditingStatus" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" v-model="selectedLineItemIds" :value="lineItem.id" />
                                                    </td>
                                                    <line-item
                                                        :key="lineItem.id"
                                                        :model="lineItem"
                                                        :initialLineItem="invoice.initialLineItems.find(({id}) => id === lineItem.id)"
                                                        :workOrder="workOrders.find(workOrder => workOrder.id === lineItem.work_order_id)"
                                                        :showEditButton="vendorInvoiceIsInPreAuditingStatus"
                                                        :autoApprovalError="autoApprovalError(lineItem)"
                                                        @edit-line-item="showEditLineItemModal"
                                                        @link-to-service-contract="linkToServiceContract"
                                                    />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div v-if="record.haulCharges.length" class="my-2">
                                    <h3 class="px-2 py-1 -mx-2 font-medium bg-green-100 text-green-700">Per Occurrence Charges</h3>

                                    <div class="overflow-x-auto">
                                        <table class="w-full border-collapse">
                                            <thead>
                                            <tr>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto"></th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Date</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Description</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Qty</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Price</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Total</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Fee</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">W.O</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Actions</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="lineItem in record.haulCharges" class="border border-gray-300 md:border-none">
                                                    <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                                        <input v-if="vendorInvoiceIsInPreAuditingStatus" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" v-model="selectedLineItemIds" :value="lineItem.id" />
                                                    </td>
                                                    <line-item
                                                        :key="lineItem.id"
                                                        :model="lineItem"
                                                        :initialLineItem="invoice.initialLineItems.find(({id}) => id === lineItem.id)"
                                                        :workOrder="workOrders.find(workOrder => workOrder.id === lineItem.work_order_id)"
                                                        :showEditButton="vendorInvoiceIsInPreAuditingStatus"
                                                        :autoApprovalError="autoApprovalError(lineItem)"
                                                        @edit-line-item="showEditLineItemModal"
                                                        @link-to-service-contract="linkToServiceContract"
                                                    />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div v-if="record.tonnageCharges.length" class="my-2">
                                    <h3 class="px-2 py-1 -mx-2 font-medium bg-green-100 text-green-700">Per Unit Charges</h3>

                                    <div class="overflow-x-auto">
                                        <table class="w-full border-collapse">
                                            <thead>
                                            <tr>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto"></th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Date</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Description</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Qty</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Price</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Total</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Fee</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">W.O</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Actions</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="lineItem in record.tonnageCharges" class="border border-gray-300 md:border-none">
                                                    <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                                        <input v-if="vendorInvoiceIsInPreAuditingStatus" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" v-model="selectedLineItemIds" :value="lineItem.id" />
                                                    </td>
                                                    <line-item
                                                        :key="lineItem.id"
                                                        :model="lineItem"
                                                        :initialLineItem="invoice.initialLineItems.find(({id}) => id === lineItem.id)"
                                                        :workOrder="workOrders.find(workOrder => workOrder.id === lineItem.work_order_id)"
                                                        :showEditButton="vendorInvoiceIsInPreAuditingStatus"
                                                        :autoApprovalError="autoApprovalError(lineItem)"
                                                        @edit-line-item="showEditLineItemModal"
                                                        @link-to-service-contract="linkToServiceContract"
                                                    />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div v-if="record.extraneousCharges.length" class="my-2">
                                    <h3 class="px-2 py-1 -mx-2 font-medium bg-yellow-100 text-yellow-700">Extraneous Charges</h3>

                                    <div class="overflow-x-auto">
                                        <table class="w-full border-collapse">
                                            <thead>
                                            <tr>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto"></th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Date</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Description</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Qty</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Price</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Total</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Fee</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">W.O</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Actions</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="lineItem in record.extraneousCharges" class="border border-gray-300 md:border-none">
                                                    <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                                        <input v-if="vendorInvoiceIsInPreAuditingStatus" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" v-model="selectedLineItemIds" :value="lineItem.id" />
                                                    </td>
                                                    <line-item
                                                        :key="lineItem.id"
                                                        :model="lineItem"
                                                        :initialLineItem="invoice.initialLineItems.find(({id}) => id === lineItem.id)"
                                                        :workOrder="workOrders.find(workOrder => workOrder.id === lineItem.work_order_id)"
                                                        :showEditButton="vendorInvoiceIsInPreAuditingStatus"
                                                        :autoApprovalError="autoApprovalError(lineItem)"
                                                        @edit-line-item="showEditLineItemModal"
                                                        @link-to-service-contract="linkToServiceContract"
                                                    />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div v-if="record.allocatableServiceFees.length" class="my-2">
                                    <h3 class="px-2 py-1 -mx-2 font-medium bg-d-blue-100 text-d-blue-700">Allocatable Service Fees</h3>

                                    <div class="overflow-x-auto">
                                        <table class="w-full border-collapse">
                                            <thead>
                                            <tr>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto"></th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Date</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Description</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Qty</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Price</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Total</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Fee</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">W.O</th>
                                                <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Actions</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="lineItem in record.allocatableServiceFees" class="border border-gray-300 md:border-none">
                                                    <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                                        <input v-if="vendorInvoiceIsInPreAuditingStatus" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" v-model="selectedLineItemIds" :value="lineItem.id" />
                                                    </td>
                                                    <line-item
                                                        :key="lineItem.id"
                                                        :model="lineItem"
                                                        :initialLineItem="invoice.initialLineItems.find(({id}) => id === lineItem.id)"
                                                        :showEditButton="vendorInvoiceIsInPreAuditingStatus"
                                                        :autoApprovalError="autoApprovalError(lineItem)"
                                                        @edit-line-item="showEditLineItemModal"
                                                        @link-to-service-contract="linkToServiceContract"
                                                    />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="separator" />

                        <div class="w-full p-3">
                            <div v-if="allocatableFees.length">
                                <h3 class="px-2 py-1 -mx-2 font-medium bg-green-100 text-green-700">Allocatable Fees</h3>

                                <div class="overflow-x-auto">
                                    <table class="w-full border-collapse">
                                        <thead>
                                        <tr>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto"></th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Date</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Description</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Qty</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Price</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Total</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Fee</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">W.O</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Actions</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="lineItem in allocatableFees" class="border border-gray-300 md:border-none">
                                                <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                                    <input v-if="vendorInvoiceIsInPreAuditingStatus" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" v-model="selectedLineItemIds" :value="lineItem.id" />
                                                </td>
                                                <line-item
                                                    :key="lineItem.id"
                                                    :model="lineItem"
                                                    :initialLineItem="invoice.initialLineItems.find(({id}) => id === lineItem.id)"
                                                    :showEditButton="vendorInvoiceIsInPreAuditingStatus"
                                                    :autoApprovalError="autoApprovalError(lineItem)"
                                                    @edit-line-item="showEditLineItemModal"
                                                    @link-to-service-contract="linkToServiceContract"
                                                />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div v-if="salesTaxes.length">
                                <h3 class="px-2 py-1 -mx-2 font-medium bg-orange-100 text-orange-700">Sales Taxes</h3>

                                <div class="overflow-x-auto">
                                    <table class="w-full border-collapse">
                                        <thead>
                                        <tr>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto"></th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Date</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Description</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Total</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Type</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Tax</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Actions</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="lineItem in salesTaxes" class="border border-gray-300 md:border-none">
                                                <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                                    <input v-if="vendorInvoiceIsInPreAuditingStatus" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" v-model="selectedLineItemIds" :value="lineItem.id" />
                                                </td>
                                                <line-item
                                                    :key="lineItem.id"
                                                    :model="lineItem"
                                                    :initialLineItem="invoice.initialLineItems.find(({id}) => id === lineItem.id)"
                                                    :showEditButton="vendorInvoiceIsInPreAuditingStatus"
                                                    @edit-line-item="showEditLineItemModal"
                                                />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div v-if="incompleteLineItems.length">
                                <h3 class="px-2 py-1 -mx-2 font-medium bg-yellow-100 text-yellow-700">Incomplete Line Items</h3>

                                <div class="overflow-x-auto">
                                    <table class="w-full border-collapse">
                                        <thead>
                                        <tr>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto"></th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Date</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Description</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Qty</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Price</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Total</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Fee</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">W.O</th>
                                            <th class="hidden w-full px-6 py-3 text-left text-xs text-gray-900 font-semibold uppercase tracking-wide md:table-cell md:w-auto">Actions</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="lineItem in incompleteLineItems" class="border border-gray-300 md:border-none">
                                                <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                                    <input v-if="vendorInvoiceIsInPreAuditingStatus" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" v-model="selectedLineItemIds" :value="lineItem.id" />
                                                </td>
                                                <line-item
                                                    :key="lineItem.id"
                                                    :model="lineItem"
                                                    :initialLineItem="invoice.initialLineItems.find(({id}) => id === lineItem.id)"
                                                    :showEditButton="vendorInvoiceIsInPreAuditingStatus"
                                                    :autoApprovalError="autoApprovalError(lineItem)"
                                                    @edit-line-item="showEditLineItemModal"
                                                    @link-to-service-contract="linkToServiceContract"
                                                />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <div class="flex" v-if="vendorInvoice.vendorAccount.tax_exempt">
                <div class="flex-shrink-0">
                    <icon name="info-circle" class="h-6 w-6 text-blue-400 fill-current" />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-blue-700">This invoice is on an account that is marked as tax exempt.</p>
                    <p class="text-blue-700" v-if="vendorInvoice.status === 'Matched' || vendorInvoice.status === 'Allocated' || vendorInvoice.status === 'Invalid Allocation'">The final tax amount will be automatically audited down to $0.00 once the invoice reaches the Audit step.</p>
                </div>
            </div>

            <div v-if="salesTaxes.length && vendorInvoice.status !== 'Matched'" class="my-4">
                <div class="flex justify-between items-center">
                    <span class="text-xl font-semibold tracking-wide text-d-blue-600 uppercase">Sales Tax Application</span>
                    <icon :name="expandSalesTaxes ? 'chevron-down' : 'chevron-right'" @click="expandSalesTaxes = !expandSalesTaxes" class="w-6 h-6 fill-current cursor-pointer" />
                </div>

                <div v-if="errors['vendor_sales_tax_application']" class="col-span-2 md:col-span-3 font-bold text-red-500">
                    Revision(s) not applied: {{errors.vendor_sales_tax_application}}
                </div>

                <transition
                        enter-active-class="transition ease-out duration-200"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition ease-in duration-200"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                        appear>

                    <div v-show="expandSalesTaxes" class="w-full pt-3">

                        <div v-for="location in locations">
                            <span class="text-xl font-semibold tracking-wide text-black uppercase">{{  location.name}}</span>
                            <div v-for="service in getServicesByLocation(location)" class="container overflow-x-auto pt-4">
                                <h3 class="px-2 py-2 -mx-2 font-bold bg-gray-100 text-gray-700">{{ service.id }}</h3>

                                <table class="table table-condensed mb-0">
                                    <thead class="sticky top-0">
                                    <tr>
                                        <th rowspan="2" class="font-semibold sticky left-0 bg-white bg-clip-padding w-1/5">Charge</th>
                                        <th rowspan="2" class="font-semibold w-1/5">Description</th>
                                        <th rowspan="2" class="font-semibold w-1/5">Cost Basis</th>
                                        <th v-for="(tax, index) in salesTaxes" :key="index" class="font-light">
                                            <template v-if="getSalesTaxLineItemCostBasis(tax).amount == 0">
                                                <div>{{ tax.description }}: <span class="text-red-500">Invalid</span></div>
                                                <div>Total Cost Basis: <span class="text-red-500">{{ $filters.format_money(getSalesTaxLineItemCostBasis(tax)) }}</span></div>
                                            </template>
                                            <template v-else>
                                                <div>{{ tax.description }}: {{ $filters.format_percentage(getPercentageOfCostBasis(tax)) }}</div>
                                                <div>Total Cost Basis: {{ $filters.format_money(getSalesTaxLineItemCostBasis(tax)) }}</div>
                                            </template>
                                        </th>
                                    </tr>
                                    <tr>
                                        <template v-for="tax in salesTaxes">
                                            <th>Taxable</th>
                                        </template>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <applied-sales-tax-line-item
                                            v-for="salesTaxApplication in salesTaxApplications.filter(item => item.service_id === service.id)"
                                            :key="salesTaxApplication.id"
                                            :model="salesTaxApplication"
                                            @appliedSalesTaxWasModified="createAppliedSalesTaxRevision"
                                    />
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="text-right my-2">
                            <loading-button
                                v-if="vendorInvoice.is_writable"
                                href=""
                                class="btn btn-orange inline-flex items-center text-md font-medium"
                                :class="{hidden: Object.keys(pendingSalesTaxRevisions).length === 0}"
                                @click.prevent="applyUserSalesTaxRevisions"
                                :loading="state === 'applying-user-sales-tax-revisions'"
                                :disabled="state === 'applying-user-sales-tax-revisions'"
                            >
                                Save Revisions
                            </loading-button>
                        </div>
                    </div>
                </transition>
            </div>

            <div v-if="vendorInvoice.status !== 'Matched'" class="my-4">
                <div class="flex justify-between items-center">
                    <span class="text-xl font-semibold tracking-wide text-d-blue-600 uppercase">Allocated Values</span>
                    <icon :name="expandAllocatedValues ? 'chevron-down' : 'chevron-right'" @click="expandAllocatedValues = !expandAllocatedValues" class="w-6 h-6 fill-current cursor-pointer" />
                </div>

                <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition ease-in duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                    appear>

                    <div v-show="expandAllocatedValues" class="w-full">

                        <div class="overflow-x-auto my-2">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="font-semibold">Description</th>
                                        <th class="font-semibold">Qty</th>
                                        <th class="font-semibold">Base Charge</th>
                                        <th class="font-semibold">Cost Basis</th>
                                        <th class="font-semibold">Percentage</th>
                                        <th v-for="(fee, index) in allocatableFees" :key="index" class="font-light">
                                            <a v-if="fee.amount.amount != 0 && vendorInvoice.is_writable" href="" class="flex items-center" @click.prevent="strikeAllocatableFeeFromInvoice(fee.id)">
                                                <icon name="ban" class="fill-current text-red-500 w-4 h-4 mr-2" />
                                            </a>

                                            <span :class="{'text-gray-500': fee.amount.amount == 0}">{{ fee.service_description ? fee.service_description : fee.description }}</span>
                                        </th>
                                        <th v-if="salesTaxes.length" class="font-semibold">Sales Taxes</th>
                                        <th class="font-semibold">Total</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <allocation-line-item
                                        v-for="allocation in allocations"
                                        :key="allocation.id"
                                        :model="allocation"
                                        :is-writable="vendorInvoice.is_writable"
                                        :has-sales-taxes="salesTaxes.length > 0"
                                        :total-cost-basis="totalCostBasis"
                                        @allocatable-fee-was-modified="createAllocatableFeeRevision"
                                        @charge-amount-was-modified="createChargeAmountRevision"
                                        @charge-quantity-was-modified="createChargeQuantityRevision"
                                    />
                                </tbody>
                            </table>

                            <div v-if="vendorInvoice.adjusted_total_current_charges.amount == allocatedTotal.amount" class="flex items-center float-right">
                                <icon name="check" class="fill-current text-green-600 w-6 h-6 mr-2" />
                                <span class="text-green-600 text-2xl font-medium">{{ $filters.format_money(allocatedTotal) }}</span>
                            </div>
                            <div v-else class="text-right">
                                <icon namke="exclamation-triangle" class="fill-current text-red-600 w-6 h-6 float-right" />
                                <span class="text-red-600 text-2xl font-medium">{{ $filters.format_money(allocatedTotal) }}</span>
                            </div>
	                        <div class="text-right my-2">
		                        <button v-if="vendorInvoice.is_writable" href="" class="btn btn-orange inline-flex items-center text-md font-medium" :class="{hidden: pendingRevisions.length === 0}" @click.prevent="showLineItemRevisionConfirmationModal">
			                        Save Revisions
		                        </button>
	                        </div>
                        </div>

                    </div>
                </transition>
            </div>
        </div>
    </div>

    <!-- Edit Invoice !-->
    <modal class="max-w-2xl" ref="invoiceModal" role="dialog" @closed="emptyInvoiceModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    <icon name="edit" class="inline fill-current h-6 w-6 mr-2" />
                    Editing Invoice #<span class="text-d-blue-600">{{ invoiceForm.invoice_number }}</span>
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                 <form role="form">
                    <div class="grid grid-cols-2 gap-y-6 gap-x-4">
                        <text-input id="invoice-number" v-model="invoiceForm.invoice_number" class="col-span-1" label="Invoice Number" :errors="invoiceFormErrors.invoice_number" mark-as-required @keyup.enter.prevent="updateInvoice" />
                        <date-input id="invoice-date" v-model="invoiceForm.invoice_date" class="col-span-1" placeholder="select a date" label="Invoice Date" :errors="invoiceFormErrors.invoice_date" mark-as-required @keyup.enter.prevent="updateInvoice" />
                        <date-input id="fiscal-period" v-model="invoiceForm.fiscal_period" class="col-span-1" placeholder="select a date" label="Fiscal Period" :errors="invoiceFormErrors.fiscal_period" mark-as-required @keyup.enter.prevent="updateInvoice" />
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex">
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                <loading-button :loading="state === 'saving'" class="btn btn-orange" @click="updateInvoice">Save Changes</loading-button>
            </div>
        </template>
    </modal>

    <!-- Edit Line Item !-->
    <modal class="max-w-2xl" ref="lineItemModal" tabindex="-1" role="dialog" @closed="emptyLineItemModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    <icon name="edit" class="inline fill-current h-6 w-6 mr-2" />
                    Editing Line Item
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                 <form role="form" class="w-full">
                    <div class="my-3 grid grid-cols-2 gap-y-6 gap-x-4">
                        <div class="col-span-2 sm:col-span-1">
                            <label class="form-label">Start Date</label>
                            <span class="">{{ $filters.format_date(lineItemForm.start_date) }}</span>
                        </div>

                        <div class="col-span-2 sm:col-span-1">
                            <label class="form-label">End Date</label>
                            <span class="">{{ $filters.format_date(lineItemForm.end_date) }}</span>
                        </div>

                        <div class="col-span-2 sm:col-span-1">
                            <label class="form-label">Amount</label>
                            <span class="">{{ $filters.format_money(lineItemForm.amount) }}</span>
                        </div>

                        <div class="col-span-2 sm:col-span-1">
                            <label class="form-label">Quantity.</label>
                            <span class="">{{ lineItemForm.quantity }}</span>
                        </div>

                        <div class="col-span-2 sm:col-span-1">
                            <label class="form-label">Description</label>
                            <span class="">{{ lineItemForm.description ? lineItemForm.description : 'No description available.' }}</span>
                        </div>

                        <div class="col-span-2 sm:col-span-1">
                            <label class="form-label">Service Description</label>
                            <span class="">{{ lineItemForm.service_description ? lineItemForm.service_description : 'No service description available.' }}</span>
                        </div>

                        <div v-if="lineItemTypeHasService(lineItemForm.type)" class="col-span-4 sm:col-span-2 lg:col-span-1">
                            <Combobox as="div" v-model="lineItemForm.service_id">
                                <ComboboxLabel class="form-label" for="service-id">Service</ComboboxLabel>

                                <div class="relative">
                                    <div class="form-input-wrapper">
                                        <ComboboxInput id="service-id" class="form-input" @change="serviceComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" autocomplete="off" />
                                    </div>

                                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                                    </ComboboxButton>

                                    <ComboboxOptions v-if="services.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <ComboboxOption v-for="service in filteredServiceOptions" :key="service.id" :value="service.id" as="template" v-slot="{ active, selected }">
                                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-blue-600 text-white' : 'text-gray-900']">
                                            <p>
                                                <span :class="['block', selected && 'font-semibold']">
                                                    {{ service.id }}
                                                </span>
                                                <span class="block truncate italic">{{ service.description }}</span>
                                            </p>

                                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-blue-600']">
                                                <icon name="heroicon-check" class="size-5 fill-current" />
                                            </span>
                                        </li>
                                        </ComboboxOption>
                                    </ComboboxOptions>
                                </div>
                                <div v-if="lineItemFormErrors.service_id" class="form-error">{{ lineItemFormErrors.service_id }}</div>
                            </Combobox>
                        </div>

                        <select-input v-model="lineItemForm.type" class="col-span-2 sm:col-span-1" label="Type" :errors="lineItemFormErrors.type" @change="clearUnusedFieldsForType" mark-as-required>
                            <option value="Allocatable Fee">Allocatable Fee</option>
                            <option value="Allocatable Service Fee">Allocatable Service Fee</option>
                            <option value="Base Charge">Base Charge</option>
                            <option value="Extraneous Charge">Extraneous Charge</option>
                            <option value="Per Occurrence Charge">Per Occurrence Charge</option>
                            <option value="Per Unit Charge">Per Unit Charge</option>
                            <option value="Sales Tax">Sales Tax</option>
                        </select-input>

                        <select-input v-if="lineItemTypeHasVendorFee(lineItemForm.type)"  v-model="lineItemForm.vendor_fee_id" class="col-span-2 sm:col-span-1" label="Vendor Fee" :errors="lineItemFormErrors.vendor_fee_id" mark-as-required>
                            <option v-for="fee in vendorFees" :key="fee.id" :value="fee.id">
                            {{ fee.label }}
                            </option>
                        </select-input>

                        <select-input v-if="lineItemTypeHasWorkOrder(lineItemForm.type)"  v-model="lineItemForm.work_order_id" class="col-span-2 sm:col-span-1" label="Work Order" :errors="lineItemFormErrors.work_order_id">
                            <optgroup v-for="(workOrders, mostAccurateEventDate) in filteredWorkOrders" :key="mostAccurateEventDate" :label="mostAccurateEventDate">
                                <option v-for="workOrder in workOrders" :key="workOrder.id" :value="workOrder.id">
                                    {{ workOrder.hauler_reference_number ?? workOrder.id }}
                                </option>
                            </optgroup>
                        </select-input>

                        <select-input v-if="lineItemForm.type === 'Allocatable Service Fee'" class="col-span-2 sm:col-span-1" label="Service Charge" v-model="lineItemForm.parent_charge_id" :errors="lineItemFormErrors.parent_charge_id">
                            <optgroup v-if="rolloffServiceCharges.length > 0" label="Rolloff Service Charges">
                                <option v-for="serviceCharge in rolloffServiceCharges" :key="serviceCharge.id" :value="serviceCharge.id">
                                    <span class="font-medium">{{ serviceCharge.service_id }}</span> -
                                    <span class="italic">{{ (serviceCharge.service_description ? serviceCharge.service_description : serviceCharge.description) }}</span>
                                </option>
                            </optgroup>

                            <optgroup label="Base Charges">
                                <option v-for="serviceCharge in commercialServiceCharges" :key="serviceCharge.id" :value="serviceCharge.id">
                                    <span class="font-medium">{{ serviceCharge.service_id }}</span> -
                                    <span class="italic">{{ (serviceCharge.service_description ? serviceCharge.service_description : serviceCharge.description) }}</span>
                                </option>
                            </optgroup>
                        </select-input>

                        <select-input v-if="lineItemForm.type === 'Sales Tax'" v-model="lineItemForm.vendor_sales_tax_type_id" class="col-span-2 sm:col-span-1" label="Sales Tax Type" :errors="lineItemFormErrors.vendor_sales_tax_type_id" mark-as-required>
                            <option v-for="type in vendorSalesTaxTypes" :key="type.id" :value="type.id">
                                {{ type.display_name }}
                            </option>
                        </select-input>
                    </div>
                </form>

                <div v-if="lineItemForm.allocatableServiceFees && lineItemForm.allocatableServiceFees.length > 0">
                    <hr class="separator">
                    <h4>Allocatable Service Fees Associated With This Charge:</h4>

                    <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th class="font-semibold">Service Description</th>
                                <th class="font-semibold">Description</th>
                                <th class="font-semibold">Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="fee in lineItemForm.allocatableServiceFees" :key="fee.id">
                                <td>{{ fee.service_description ? fee.service_description : 'N/A' }}</td>
                                <td>{{ fee.description ? fee.description : 'N/A' }}</td>
                                <td>{{ $filters.format_money(fee.amount) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex" >
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                <loading-button :loading="state === 'saving'" class="btn btn-orange" @click="updateLineItem">Save Changes</loading-button>
            </div>
        </template>
    </modal>

    <!-- Bulk Edit Line Items !-->
    <modal class="max-w-2xl" ref="bulkLineItemsModal" tabindex="-1" role="dialog" @closed="emptyBulkLineItemsModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    <icon name="edit" class="inline fill-current h-6 w-6 mr-2" />
                    Editing {{ selectedLineItemIds.length }} Line Item{{ selectedLineItemIds.length > 1 ? "s" : "" }}: Bulk Assignment
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <form role="form" class="w-full">
                    <div class="my-3 grid grid-cols-2 gap-y-6 gap-x-4">
                        <div v-if="lineItemTypeHasService(bulkLineItemsForm.type)" class="col-span-4 sm:col-span-2 lg:col-span-1">
                            <Combobox as="div" v-model="bulkLineItemsForm.service_id">
                                <ComboboxLabel class="form-label" for="service-id">Service</ComboboxLabel>

                                <div class="relative">
                                    <ComboboxInput id="service-id" class="form-input" @change="bulkLineItemsServiceComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" autocomplete="off" />

                                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <!-- Heroicon name: solid/selector -->
                                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                        </svg>
                                    </ComboboxButton>

                                    <ComboboxOptions v-if="services.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <ComboboxOption v-for="service in filteredBulkLineItemsServiceOptions" :key="service.id" :value="service.id" as="template" v-slot="{ active, selected }">
                                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-blue-600 text-white' : 'text-gray-900']">
                                                <p>
                                                <span :class="['block', selected && 'font-semibold']">
                                                    {{ service.id }}
                                                </span>
                                                    <span class="block truncate italic">{{ service.description }}</span>
                                                </p>

                                                <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-blue-600']">
                                                <!-- Heroicon name: solid/check -->
                                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                            </li>
                                        </ComboboxOption>
                                    </ComboboxOptions>
                                </div>
                                <div v-if="bulkLineItemsFormErrors.service_id" class="form-error">{{ bulkLineItemsFormErrors.service_id }}</div>
                            </Combobox>
                        </div>

                        <select-input v-model="bulkLineItemsForm.type" class="col-span-2 sm:col-span-1" label="Type" :errors="bulkLineItemsFormErrors.type" @change="clearUnusedFieldsForType(bulkLineItemsForm)" mark-as-required>
                            <optgroup label="Incomplete">
                                <option value="Incomplete Allocatable Service Fee">Incomplete Allocatable Service Fee</option>
                                <option value="Incomplete Commercial Service Charge">Incomplete Commercial Service Charge</option>
                                <option value="Incomplete Extraneous Charge">Incomplete Extraneous Charge</option>
                                <option value="Incomplete Rolloff Per Haul">Incomplete Rolloff Per Haul</option>
                                <option value="Incomplete Rolloff Per Ton">Incomplete Rolloff Per Ton</option>
                            </optgroup>

                            <optgroup label="Complete">
                                <option value="Allocatable Fee">Allocatable Fee</option>
                                <option value="Allocatable Service Fee">Allocatable Service Fee</option>
                                <option value="Commercial Service Charge">Commercial Service Charge</option>
                                <option value="Extraneous Charge">Extraneous Charge</option>
                                <option value="Rolloff Per Haul">Rolloff Per Haul</option>
                                <option value="Rolloff Per Ton">Rolloff Per Ton</option>
                                <option value="Sales Tax">Sales Tax</option>
                            </optgroup>
                        </select-input>

                        <select-input v-if="lineItemTypeHasVendorFee(bulkLineItemsForm.type)"  v-model="bulkLineItemsForm.vendor_fee_id" class="col-span-2 sm:col-span-1" label="Vendor Fee" :errors="bulkLineItemsFormErrors.vendor_fee_id" mark-as-required>
                            <option v-for="fee in vendorFees" :key="fee.id" :value="fee.id">
                                {{ fee.label }}
                            </option>
                        </select-input>

                        <select-input v-if="lineItemTypeHasWorkOrder(bulkLineItemsForm.type)"  v-model="bulkLineItemsForm.work_order_id" class="col-span-2 sm:col-span-1" label="Work Order" :errors="bulkLineItemsFormErrors.work_order_id">
                            <optgroup v-for="(workOrders, mostAccurateEventDate) in filteredWorkOrders" :key="mostAccurateEventDate" :label="mostAccurateEventDate">
                                <option v-for="workOrder in workOrders" :key="workOrder.id" :value="workOrder.id">
                                    {{ workOrder.hauler_reference_number ?? workOrder.id }}
                                </option>
                            </optgroup>
                        </select-input>

                        <select-input v-if="bulkLineItemsForm.type === 'Allocatable Service Fee'" class="col-span-2 sm:col-span-1" label="Service Charge" v-model="bulkLineItemsForm.parent_charge_id" :errors="bulkLineItemsFormErrors.parent_charge_id">
                            <optgroup v-if="rolloffServiceCharges.length > 0" label="Rolloff Service Charges">
                                <option v-for="serviceCharge in rolloffServiceCharges" :key="serviceCharge.id" :value="serviceCharge.id">
                                    <span class="font-medium">{{ serviceCharge.service_id }}</span> -
                                    <span class="italic">{{ (serviceCharge.service_description ? serviceCharge.service_description : serviceCharge.description) }}</span>
                                </option>
                            </optgroup>

                            <optgroup label="Commercial Service Charges">
                                <option v-for="serviceCharge in commercialServiceCharges" :key="serviceCharge.id" :value="serviceCharge.id">
                                    <span class="font-medium">{{ serviceCharge.service_id }}</span> -
                                    <span class="italic">{{ (serviceCharge.service_description ? serviceCharge.service_description : serviceCharge.description) }}</span>
                                </option>
                            </optgroup>
                        </select-input>

                        <select-input v-if="bulkLineItemsForm.type === 'Sales Tax'" v-model="bulkLineItemsForm.vendor_sales_tax_type_id" class="col-span-2 sm:col-span-1" label="Sales Tax Type" :errors="bulkLineItemsFormErrors.vendor_sales_tax_type_id" mark-as-required>
                            <option v-for="type in vendorSalesTaxTypes" :key="type.id" :value="type.id">
                                {{ type.display_name }}
                            </option>
                        </select-input>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex" >
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                <loading-button :loading="state === 'saving'" class="btn btn-orange" @click="bulkUpdateLineItems">Save Changes</loading-button>
            </div>
        </template>
    </modal>

    <!-- Line Item Revision Confirmation Modal !-->
    <modal class="max-w-2xl" ref="revisionConfirmationModal" tabindex="-1" role="dialog">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    <icon name="edit" class="inline fill-current h-6 w-6 mr-2" />
                    Confirm Revision
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <form id="revision-confirmation-form" class="p-4" role="form">
                <div v-for="(pendingRevision, index) in pendingRevisions" :key="index">
                    <div class="flex -mx-4 my-3">
                        <label for="description" class="w-1/4 px-4 mr-2 font-semibold">Item Description</label>

                        <div class="w-2/3 px-4">
                            <span class="">{{ pendingRevision.line_item_description }}</span>
                        </div>
                    </div>

                    <div class="flex items-center -mx-4 my-3">
                        <label for="description" class="w-1/4 px-4 mr-2 font-semibold">Field</label>

                        <div class="w-1/4 px-4">
                            <span class="">{{ capitalize(pendingRevision.field_name) }}</span>
                        </div>

                        <div v-if="typeof pendingRevision.previous_value === 'object'" class="w-1/4 px-2 flex items-center">
                            <span class="mr-2">{{ $filters.format_money(pendingRevision.previous_value) }}</span>

                            <icon name="long-arrow-right" class="w-4 h-4 fill-current mr-2" />

                            <span class="">{{ $filters.format_money(pendingRevision.new_value) }}</span>
                        </div>
                        <div v-else class="w-1/4 flex items-center">
                            <span class="mr-2">{{ pendingRevision.previous_value }}</span>

                            <icon name="long-arrow-right" class="w-4 h-4 fill-current mr-2" />

                            <span class="">{{ pendingRevision.new_value }}</span>
                        </div>

                        <div class="w-1/4">
                            <span v-if="props.errors[`pending_revisions.${index}.new_value`]" class="text-red-500 text-sm">
                                {{ errors[`pending_revisions.${index}.new_value`] }}
                            </span>
                        </div>
                    </div>

                    <div class="flex -mx-4 my-3">
                        <label for="category" class="w-1/4 px-4 mr-2 font-semibold">Data Source</label>

                        <div class="w-2/3 px-4">
                            <select-input v-model="pendingRevision.source" @keyup.enter.prevent="applyUserRevisions" :errors="errors[`pending_revisions.${index}.source`]" autofocus>
                                <option v-for="source in revisionSourceList" :key="source" :value="source">{{ source }}</option>
                            </select-input>
                        </div>
                    </div>

                    <div class="flex -mx-4 my-3">
                        <label for="category" class="w-1/4 px-4 mr-2 font-semibold">Reason</label>

                        <div class="w-2/3 px-4">
                            <select-input v-model="pendingRevision.reason" @keyup.enter.prevent="applyUserRevisions" :errors="errors[`pending_revisions.${index}.reason`]" autofocus>
                                <option v-for="reason in revisionReasonList" :key="reason" :value="reason">{{ reason }}</option>
                            </select-input>
                        </div>
                    </div>

                    <hr v-if="index !== (pendingRevisions.length - 1)">
                </div>
            </form>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex">
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>

                <loading-button
                    :loading="state === 'saving'"
                    class="btn btn-orange"
                    :class="{disabled: state === 'applying-user-revisions'}"
                    @click="applyUserRevisions"
                    :disabled="state === 'applying-user-revisions'">Confirm
                </loading-button>
            </div>
        </template>
    </modal>
</template>

<script setup>
    import md5 from 'md5';
    import AllocationLineItem from './Allocation/AllocationLineItem.vue';
    import AppliedSalesTaxLineItem from "./AppliedSalesTax/AppliedSalesTaxLineItem.vue";
    import LineItem from './LineItem.vue';
    import Modal from '@/Shared/Modal.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Icon from '@/Shared/Icon.vue';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';
    import HorizontalSubNav from '@/Shared/Audit/HorizontalSubNav.vue';
    import { filters } from '@/Shared/Utils/Filters.js';
    import { EventBus } from '@/Shared/EventBus.js';
    import { subtract_money } from '@/Shared/Utils/Money.js';
    import { reactive, ref, inject, watch, computed, onMounted, onUnmounted } from 'vue';
    import { Head, router, usePage } from '@inertiajs/vue3';
    import { capitalize } from "lodash-es";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    const emptyLineItemRevision = {
        id: null,
        line_item_id: null,
        line_item_description: null,
        source: null,
        reason: null,
        field_name: null,
        previous_value: null,
        new_value: null,
    }

    const emptyLineItem = {
        type: '',
        services : [],
        start_date: '',
        end_date: '',
        service_id: null,
        amount: {amount: null, currency: null},
        description: '',
        category: null,
        match_to_revenue_errors: [],
        service_description: '',
        parent_id: null,
        quantity: null,
        created_at: null,
        updated_at: null
    };

    const emptyBulkLineItems = {
        type: '',
        services : [],
        service_id: null,
        vendor_fee_id: null,
        parent_charge_id: null,
        work_order_id: null,
        vendor_sales_tax_type_id: null
    };

    const emptyInvoiceErrors = {
        invoice_date: '',
        fiscal_period: '',
        invoice_number: ''
    };

    const emptyLineItemErrors = {
        type: '',
        service_id: '',
        vendor_fee_id: '',
        parent_charge_id: '',
        work_order_id: '',
        vendor_sales_tax_type_id: ''
    };

    const emptyBulkLineItemsErrors = {
        type: '',
        service_id: '',
        vendor_fee_id: '',
        parent_charge_id: '',
        work_order_id: '',
        vendor_sales_tax_type_id: ''
    };

    const route = inject('route');
    const toast = inject('toast');

    /**
     * Props
     */
    const props = defineProps({
        vendorInvoice: {
            type: Object,
            required: true,
        },

        invoiceCanBeDeleted: {
            type: Boolean,
            required: true
        },

        stateMachine: {
            type: Object,
            required: true
        },

        fiscalPeriod: {
            type: Object,
            required: true
        },

        incompleteLineItemTypes: {
            type: Array,
            required: true
        },

        revisionSourceList: {
            type: Array,
            required: true
        },

        revisionReasonList: {
            type: Array,
            required: true
        },

        vendorFees: {
            type: Array,
            required: true
        },

        vendorSalesTaxTypes: {
            type: Array,
            required: true
        },

        services: {
            type: Array,
            required: true
        },

        workOrders: {
            type: Array,
            required: true
        },

        vendorContract: {
            type: [Object, null],
            default: null
        },

        nextInvoiceId: {
            type: [String, null],
            default: null,
        },

        vendorInvoiceIsInPreAuditingStatus: {
            type: Boolean,
            required: true
        },

        gatheredVendorInvoiceFileId: {
            type: String,
            required: true
        },

        isReprocessing: {
            type: Boolean,
            required: true
        },

        errors: {
            type: Object,
            default: () => ({

            }),
        }
    });


    /**
     * State
     */
    const processingErrorMessage = ref(null);
    const otherUsers = ref([]);
    const serviceComboBoxQuery = ref('');
    const bulkLineItemsServiceComboBoxQuery = ref('');
    const lineItemModal = ref(null);
    const bulkLineItemsModal = ref(null);
    const invoiceModal = ref(null);
    const revisionConfirmationModal = ref(null);
    const remember = ref(['expandLineItems', 'expandAllocatedValues', 'expandSalesTaxes']);

    // These are initialized so that we can have side effect free modal edits of our invoice and line items.
    const invoice = reactive({...props.vendorInvoice});
    const invoiceForm = reactive({});
    const lineItemForm = reactive({ ...emptyLineItem });
    const bulkLineItemsForm = reactive({ ...emptyBulkLineItems });
    const invoiceFormErrors = ref(emptyInvoiceErrors);
    const lineItemFormErrors = ref(emptyLineItemErrors);
    const bulkLineItemsFormErrors = ref(emptyBulkLineItemsErrors);
    const allocations = ref([]);
    const currency = ref(props.vendorInvoice.tenant.default_currency);

    const salesTaxApplications = ref([]);

    // These are pure data objects that we initialize so to ensure their reactivity.
    const modalRevision = reactive({created_at: {date: null, timezone: 'UTC', timezone_type: 3}});
    const pendingRevisions = ref([]);
    const pendingSalesTaxRevisions = ref({});
    const state = ref('passive');
    const expandLineItems = ref(true);
    const expandAllocatedValues = ref(props.vendorInvoice.status === 'Invalid Allocation');
    const expandSalesTaxes = ref(props.vendorInvoice.status === 'Invalid Allocation');
    const selectedLineItemIds = ref([]);

    const mounted = ref(false);

    /**
     * Methods
     */
    function transitionTo(stepName) {
        if (props.stateMachine[invoice.status].includes(stepName) && state.value === 'passive' && props.fiscalPeriod.is_open) {
            switch (stepName) {
                case 'Matched':
                    reprocess();
                    break;

                case 'Invalid Allocation':
                case 'Allocated':
                    if (canBeAllocated) {
                        calculateAllocations();
                    }
                    break;

                case 'Audited':
                    auditInvoice();
                    break;

                case 'Approved':
                    approveInvoice();

                    break;

                case 'Posted':
                case 'Revenue Check Failure':
                    postInvoice();

                    break;

                default:
                    break;
            }
        }
    }

    function comboDisplayValue(option) {
        if (Array.isArray(option)) {
            if (option.length > 1) {
                return `${option.length} filters selected`;
            } else if (option.length === 1) {
                return '1 filter selected';
            } else {
                return 'No filters selected';
            }
        }

        return option ?? 'No filters selected';
    }

    function addressSearchUrl(address) {
        return `/vendors/${invoice.vendorAccount.vendor_id}/locations?search=${encodeURIComponent(address)}`;
    }

    function approveInvoice() {
        state.value = 'approving';

        router.put(route('vendor-invoices.approve', invoice.id), {}, {
            preserveScroll: true,
            onFinish: () => state.value = 'passive'
        });
    }

    function showEditLineItemModal(lineItem) {
        if (state.value === 'passive' && props.vendorInvoiceIsInPreAuditingStatus) {
            Object.assign(lineItemForm, lineItem);

            lineItemModal.value.show();
        }
    }

    function showBulkEditLineItemsModal() {
        if (state.value === 'passive' && props.vendorInvoiceIsInPreAuditingStatus) {
            initializeBulkEditLineItemsForm();

            bulkLineItemsModal.value.show();
        }
    }

    function initializeBulkEditLineItemsForm() {
        let selectedLineItems = lineItems.value.filter((lineItem) => selectedLineItemIds.value.includes(lineItem.id));

        let type = null;
        let serviceId = null;
        let vendorFeeId = null;
        let workOrderId = null;
        let parentChargeId = null;
        let vendorSalesTaxTypeId = null;

        if (selectedLineItems.length > 0) {
            if (selectedLineItems.every((lineItem) => lineItem.type === selectedLineItems[0].type)) {
                type = selectedLineItems[0].type;
            }

            if (lineItemTypeHasService(type) && selectedLineItems.every((lineItem) => lineItem.service_id === selectedLineItems[0].service_id)) {
                serviceId = selectedLineItems[0].service_id;
            }

            if (lineItemTypeHasVendorFee(type) && selectedLineItems.every((lineItem) => lineItem.vendor_fee_id === selectedLineItems[0].vendor_fee_id)) {
                vendorFeeId = selectedLineItems[0].vendor_fee_id;
            }

            if (lineItemTypeHasWorkOrder(type) && selectedLineItems.every((lineItem) => lineItem.work_order_id === selectedLineItems[0].work_order_id)) {
                workOrderId = selectedLineItems[0].work_order_id;
            }

            if (type === 'Allocatable Service Fee' && selectedLineItems.every((lineItem) => lineItem.parent_charge_id === selectedLineItems[0].parent_charge_id)) {
                parentChargeId = selectedLineItems[0].parent_charge_id;
            }

            if (type === 'Sales Tax' && selectedLineItems.every((lineItem) => lineItem.vendor_sales_tax_type_id === selectedLineItems[0].vendor_sales_tax_type_id)) {
                vendorSalesTaxTypeId = selectedLineItems[0].vendor_sales_tax_type_id;
            }
        }

        bulkLineItemsForm.type = type;
        bulkLineItemsForm.service_id = serviceId;
        bulkLineItemsForm.vendor_fee_id = vendorFeeId;
        bulkLineItemsForm.work_order_id = workOrderId;
        bulkLineItemsForm.parent_charge_id = parentChargeId;
        bulkLineItemsForm.vendor_sales_tax_type_id = vendorSalesTaxTypeId;
    }

    function showEditInvoiceModal() {
        if (state.value !== 'passive' || invoice.approved_at) {
            return;
        }

        Object.assign(invoiceForm, invoice);

        invoiceModal.value.show();
    }

    function updateLineItem(e) {
        state.value = 'saving';

        router.put(route('vendor-invoices.line-items.update', [invoice.id, lineItemForm.id]), lineItemForm, {
            errorBag: 'lineItem',
            preserveScroll: true,
            onError: (errors) => lineItemFormErrors.value = {...lineItemFormErrors.value, ...errors},
            onSuccess: () => lineItemModal.value.close(),
            onFinish: () => state.value = 'passive'
        });
    }

    function bulkUpdateLineItems(e) {
        state.value = 'saving';

        router.put(route('vendor-invoices.line-items.bulk-update', [invoice.id]), {line_item_ids: selectedLineItemIds.value, ...bulkLineItemsForm}, {
            errorBag: 'bulkLineItems',
            preserveScroll: true,
            onError: (errors) => bulkLineItemsFormErrors.value = {...bulkLineItemsFormErrors.value, ...errors},
            onSuccess: () => bulkLineItemUpdateSucceeded(),
            onFinish: () => state.value = 'passive'
        });
    }

    function updateInvoice(e) {
        state.value = 'saving';

        router.put(route('vendor-invoices.update', invoice.id), invoiceForm, {
            errorBag: 'invoice',
            preserveScroll: true,
            onError: (errors) => invoiceFormErrors.value = {...invoiceFormErrors.value, ...errors},
            onSuccess: () => invoiceModal.value.close(),
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }

    function autoApprovalError(lineItem) {
      let lineItemErrors = invoice.auto_approval_errors.line_item_errors ?? [];
      let lineItemError = lineItemErrors.filter((error) => error.line_item_id === lineItem.id);
      return lineItemError.length > 0 ? lineItemError[0].error_message : null;
    }

    function deleteInvoice() {
        if (state.value === 'passive' && props.invoiceCanBeDeleted) {
            if (window.confirm("Are you sure that you want to delete this invoice? NOTE: This will remove the GATHERED, EXTRACTED and AUDITED vendor invoice!")) {
                state.value = 'deleting';

                router.delete(route('vendor-invoices.destroy', invoice.id), {
                    onFinish: () => {state.value = 'passive'}
                })
            }
        }
    }

    function showLineItemRevisionConfirmationModal() {
        if (pendingRevisions.value.length > 0) {
            revisionConfirmationModal.value.show();
        }
    }

    function applyUserRevisions() {
        let data = {
            allocation_line_items: allocations.value,
            pending_revisions: pendingRevisions.value,
        };

        state.value = 'applying-user-revisions';

        router.put(route('vendor-invoices.apply-user-revisions', invoice.id), data, {
            preserveScroll: true,
            onSuccess: () => {
                revisionConfirmationModal.value.close();
                pendingRevisions.value = [];
                EventBus.emit('userRevisionsWereApplied');
            },
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }

    function applyUserSalesTaxRevisions() {
        let data = {
            pending_sales_tax_revisions: pendingSalesTaxRevisions.value,
        };

        state.value = 'applying-user-sales-tax-revisions';

        router.put(route('vendor-invoices.apply-user-sales-tax-revisions', invoice.id), data, {
            preserveScroll: true,
            onSuccess: () => {
                pendingSalesTaxRevisions.value = {};
                EventBus.emit('userSalesTaxRevisionsWereApplied');
            },
            onFinish: () => {
                state.value = 'passive';
            },
            onError: (errors) => {
                state.value = 'passive';
                pendingSalesTaxRevisions.value = {};
            }
        });
    }

    function reprocess() {
        let confirmed = confirm("This will remove any changes you may have made to this invoice's line items as well as its final allocation. Are you sure you wish to perform this action?");

        if (confirmed) {
            state.value = 'reprocessing';

            router.put(route('vendor-invoices.reprocess', invoice.ocr_vendor_invoice_id), {}, {
                preserveScroll: true,
            });
        }
    }

    function calculateAllocations() {
        let confirmed = confirm("This will remove any changes you may have made to this invoice's allocated values. Are you sure you wish to perform this action?");

        if (confirmed) {
            state.value = 'allocating';

            router.put(route('vendor-invoices.allocate', invoice.id), invoiceForm, {
                preserveScroll: true,
                onFinish: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function auditInvoice() {
        let confirmed = confirm("This will remove any changes you may have made to this invoice's allocated values. Are you sure you wish to perform this action?");

        if (confirmed) {
            state.value = 'auditing';

            router.put(route('vendor-invoices.audit', invoice.id), {}, {
                preserveScroll: true,
                onFinish: () => state.value = 'passive'
            });
        }
    }

    function postInvoice() {
        let confirmed = confirm("Are you sure you wish to perform this action?");

        if (confirmed) {
            state.value = 'posting';

            router.put(route('vendor-invoices.post', invoice.id), {}, {
                preserveScroll: true,
                onFinish: () => state.value = 'passive'
            });
        }
    }

    function toggleSelectAllLineItems(e) {
        selectedLineItemIds.value = e.target.checked ? lineItems.value.map((lineItem) => lineItem.id) : [];
    }

    function emptyLineItemModal() {
        Object.assign(lineItemForm, emptyLineItem);
        Object.assign(lineItemFormErrors.value, emptyLineItemErrors);
    }

    function bulkLineItemUpdateSucceeded() {
        bulkLineItemsModal.value.close()
        selectedLineItemIds.value = [];
        emptyBulkLineItemsModal();
    }

    function emptyBulkLineItemsModal() {
        Object.assign(bulkLineItemsForm, emptyBulkLineItems);
        Object.assign(bulkLineItemsFormErrors.value, emptyBulkLineItemsErrors);
    }

    function emptyInvoiceModal() {
        Object.assign(invoiceForm, {});
        Object.assign(invoiceFormErrors.value, emptyInvoiceErrors);
    }

    function lineItemIsACharge(lineItem) {
        return lineItem.type === 'Base Charge'
            || lineItem.type === 'Per Occurrence Charge'
            || lineItem.type === 'Per Unit Charge'
            || lineItem.type === 'Extraneous Charge';
    }

    function formatLineItems(item) {
        if (item.allocatableServiceFees.length) {
            return [item].concat(item.allocatableServiceFees);
        } else {
            return [item]
        }
    }

    function insertRevision(revision) {
        let existingRevisionIndex = pendingRevisions.value.findIndex((pendingRevision) => pendingRevision.id === revision.id);

        if (existingRevisionIndex !== -1) {
            let lineItem = lineItems.value.find(lineItem => lineItem.id === revision.line_item_id);

            if (lineItem.type === 'Allocatable Fee') {
                // Multiple pending allocatable fee revisions will need to be collapsed into a single revision.
                let existingRevision = pendingRevisions[existingRevisionIndex];
                let existingRevisionDifference = existingRevision.previous_value.amount - existingRevision.new_value.amount;

                revision.new_value = {
                    amount: existingRevision.new_value.amount - existingRevisionDifference,
                    currency: existingRevision.new_value.currency
                }
            }

            // Override the existing revision.
            pendingRevisions.value[existingRevisionIndex] = revision;
        } else {
            // Insert a new revision.
            pendingRevisions.value.push(revision);
        }
    }

    function createChargeAmountRevision(chargeId, updatedValue) {
        let charge = allocations.value.find(allocation => allocation.id === chargeId);

        let revision = Object.assign({}, emptyLineItemRevision, {
            id: md5(`${charge.id}-charge-amount`),
            line_item_id: charge.id,
            line_item_description: charge.service_description ? charge.service_description : charge.description,
            previous_value: {...charge.amount, ...charge.currency},
            new_value: updatedValue,
            field_name: 'amount',
            source: 'Hauler Contract',
        });

        // Cost basis is the sum of the charge amount + plus any allocatable service fees amounts associated with the charge.
        // Sometimes the cost basis is different than the charge amount.
        // If this is the case, we need to dervive a new cost basis and update it on the charge as well.
        // If it's not then we can just update the charge amount and cost basis with the new value.
        if (charge.cost_basis.amount !== charge.amount.amount) {
            let difference = charge.cost_basis.amount - charge.amount.amount;

            charge.cost_basis.amount = updatedValue.amount + difference;
        } else {
            charge.cost_basis = {...updatedValue};
        }

        charge.amount = {...updatedValue};
        insertRevision(revision);
    }

    function createChargeQuantityRevision(chargeId, updatedValue) {
        let charge = allocations.value.find(allocation => allocation.id === chargeId);

        let revision = Object.assign({}, emptyLineItemRevision, {
            id: md5(`${charge.id}-charge-quantity`),
            line_item_id: charge.id,
            line_item_description: charge.service_description ? charge.service_description : charge.description,
            previous_value: charge.quantity,
            new_value: updatedValue,
            field_name: 'quantity',
            source: 'Hauler Contract',
        });

        charge.quantity = updatedValue;
        insertRevision(revision);
    }

    function createAllocatableFeeRevision(allocatedValue, difference, updatedAllocatedValueAmount) {
        let allocatableFee = allocatableFees.value.find(allocatableFee => allocatableFee.id === allocatedValue.line_item_fee_id);
        let updatedValue = {amount: allocatableFee.amount.amount - difference.amount, currency: currency};

        let revision = Object.assign({}, emptyLineItemRevision, {
            id: md5(`${allocatableFee.id}-fee-amount`),
            line_item_id: allocatableFee.id,
            line_item_description: allocatableFee.description ? allocatableFee.description : 'No description available',
            previous_value: allocatableFee.amount,
            new_value: updatedValue,
            field_name: 'amount',
            source: 'Hauler Contract',
        });

        allocatedValue.amount = updatedAllocatedValueAmount;

        insertRevision(revision);
    }

    function createAppliedSalesTaxRevision(salesTax) {
        if (pendingSalesTaxRevisions.value.hasOwnProperty(salesTax.applied_sales_tax_id)) {
            delete pendingSalesTaxRevisions.value[salesTax.applied_sales_tax_id];
        } else {
            pendingSalesTaxRevisions.value[salesTax.applied_sales_tax_id] = {
                id: salesTax.applied_sales_tax_id,
                is_applied: salesTax.is_applied
            };
        }
    }

    function getSalesTaxLineItemCostBasis(salesTaxLineItem) {
        let taxableAmount = salesTaxApplications.value
            .filter(salesTaxApplication => salesTaxApplication.salesTaxes.filter(salesTax=>salesTax.line_item_sales_tax_id == salesTaxLineItem.id && salesTax.is_applied).length>0)
            .reduce((carry, salesTaxApplication) =>carry+parseInt(salesTaxApplication.cost_basis.amount), 0);

        return {amount: taxableAmount, currency: salesTaxLineItem.amount.currency};
    }

    function getPercentageOfCostBasis(salesTaxLineItem) {
        let initialSalesTaxLineItem = invoice.initialLineItems.find(({id}) => id === salesTaxLineItem.id);
        let initialCostBasis = getSalesTaxLineItemCostBasis(salesTaxLineItem);

        return parseInt(initialSalesTaxLineItem.amount.amount) / parseInt(initialCostBasis.amount) * 100;
    }

    // How do we create a consistent UI for this? The strike option needs to call a different backend route, but
    // we're inserting a revision for this and we use the same revision confirmation modal for both operations.
    // The new endpoint (strike-allocatable-fee) should be a simple delete operation.
    // The new endpoint (strike-allocatable-fee) will also need to project a line item revision.
    function strikeAllocatableFeeFromInvoice(allocatableFeeId)
    {
        let allocatableFee = allocatableFees.value.find(allocatableFee => allocatableFee.id === allocatableFeeId);

        if (parseInt(allocatableFee.amount.amount) !== 0) {
            let updatedValue = {amount: 0, currency: allocatableFee.amount.currency};

            allocations.value.forEach(function(allocation) {
                // 1. Update allocated values for the struck fee, setting them to zero dollars (money).
                allocation.allocatedValues
                    .filter(allocatedValue => allocatedValue.line_item_fee_id == allocatableFee.id)
                    .forEach(allocatedValue => allocatedValue.amount = updatedValue);

                // 2.Update the all in cost for the each charge (allocation row) the fee was allocated towards.
                let allocatedTotal = allocation.allocatedValues
                    .reduce((carry, allocatedValue) => {
                        carry = carry + parseInt(allocatedValue.amount.amount);

                        return carry;
                    }, 0);

                allocation.all_in_cost.amount = parseInt(allocation.amount.amount) + allocatedTotal;
            });

            let revision = Object.assign({}, emptyLineItemRevision, {
                id: md5(allocatableFee.id + 'value'),
                line_item_id: allocatableFee.id,
                line_item_description: allocatableFee.description ? allocatableFee.description : 'No description available',
                previous_value: allocatableFee.amount,
                new_value: updatedValue,
                field_name: 'amount',
                source: 'Hauler Contract',
            });

            insertRevision(revision);
        }
    }

    function initializeAllocationLineItems() {
        allocations.value = charges.value.map(charge => ({
            id: charge.id,
            quantity: charge.quantity,
            amount: charge.amount,
            all_in_cost: charge.all_in_cost,
            cost_basis: charge.cost_basis,
            cost_per_unit: charge.cost_per_unit,
            description: charge.description,
            service_description: charge.service_description,
            allocatableServiceFees: charge.allocatableServiceFees.map(fee => ({
                amount: fee.amount,
            })),

            allocatedValues: allocatableFees
                .value
                .map(fee => charge.allocatedValues.find(allocatedValue => allocatedValue.line_item_fee_id === fee.id && allocatedValue.type !== 'sales-tax'))
                .map(allocatedValue => {
                    if (allocatedValue) {
                        return {
                            type: allocatedValue.type,
                            vendor_invoice_id: allocatedValue.vendor_invoice_id,
                            line_item_charge_id: allocatedValue.line_item_charge_id,
                            line_item_fee_id: allocatedValue.line_item_fee_id,
                            amount: {currency: allocatedValue.amount.currency, amount: allocatedValue.amount.amount},
                        }
                    }

                    return null;
                }),

                sales_tax_total: {
                    amount: charge.allocatedValues
                        .filter(allocatedValue => allocatedValue.type === 'sales-tax')
                        .reduce((carry, allocatedValue) => carry += parseInt(allocatedValue.amount.amount), 0),
                    currency: charge.amount.currency
                }
        }))
        .sort((a, b) => (a.service_description ? a.service_description : a.description).localeCompare(b.service_description ? b.service_description : b.description) || a.id.localeCompare(b.id));
    }

    function initializeSalesTaxApplicationLineItems() {
        salesTaxApplications.value = [];

        if (props.vendorInvoice.status === 'Matched') {
            // No allocations yet, so nothing to do
            return;
        }

        charges.value
            .sort((a, b) => {
                let aSort = getLineItemGroupIndex(a)+"_"+a.start_date+"_"+a.id;
                let bSort = getLineItemGroupIndex(b)+"_"+b.start_date+"_"+b.id;

                return aSort.localeCompare(bSort);
            })
            // .sort((a, b) => (a.service_description ? a.service_description : a.description).localeCompare(b.service_description ? b.service_description : b.description) || a.id.localeCompare(b.id))
            .forEach(charge => {
                let initialCharge = invoice.initialLineItems.find(({id}) => id === charge.id);

                salesTaxApplications.value.push({
                    id: charge.id,
                    applicationType: 'charge',
                    type: generateChargeDisplayName(charge),
                    cost_basis: initialCharge.cost_basis,
                    description: charge.description,
                    service_id: charge.service_id,
                    salesTaxes: charge.appliedSalesTaxes.filter(appliedSalesTax => appliedSalesTax.line_item_allocation_id === null)
                        .sort((a, b) => getAppliedSalesTaxSortIndex(a) < getAppliedSalesTaxSortIndex(b) ? -1 : 1)
                        .map(appliedSalesTax => ({
                            applied_sales_tax_id: appliedSalesTax.id,
                            line_item_sales_tax_id: appliedSalesTax.line_item_sales_tax_id,
                            amount: initialCharge.allocatedValues.find(allocatedValue => allocatedValue.line_item_sales_tax_id === appliedSalesTax.line_item_sales_tax_id && allocatedValue.line_item_fee_id === null).amount,
                            is_applied: appliedSalesTax.is_applied
                        })),
                })

                allocatableFees.value.forEach(allocatableFee => {
                    let initialFeeAllocatedValue = initialCharge.allocatedValues.find(allocatedValue => allocatedValue.line_item_fee_id === allocatableFee.id && allocatedValue.type !== 'sales-tax');
                    let feeAllocatedValue = charge.allocatedValues.find(allocatedValue => allocatedValue.line_item_fee_id === allocatableFee.id && allocatedValue.type !== 'sales-tax');

                    salesTaxApplications.value.push({
                        id: feeAllocatedValue.id,
                        applicationType: 'allocation',
                        type: allocatableFee.vendorFee.label,
                        cost_basis: initialFeeAllocatedValue.amount,
                        description: allocatableFee.description,
                        service_id: charge.service_id,
                        salesTaxes: charge.appliedSalesTaxes.filter(appliedSalesTax => appliedSalesTax.line_item_allocation_id === allocatableFee.id)
                            .sort((a, b) => getAppliedSalesTaxSortIndex(a) < getAppliedSalesTaxSortIndex(b) ? -1 : 1)
                            .map(appliedSalesTax => ({
                                applied_sales_tax_id: appliedSalesTax.id,
                                line_item_sales_tax_id: appliedSalesTax.line_item_sales_tax_id,
                                amount: initialCharge.allocatedValues.find(allocatedValue => allocatedValue.line_item_sales_tax_id === appliedSalesTax.line_item_sales_tax_id && allocatedValue.line_item_fee_id === appliedSalesTax.line_item_allocation_id).amount,
                                is_applied: appliedSalesTax.is_applied
                            })),
                    })
                });
        });
    }

    function generateChargeDisplayName(charge) {
        if (charge.type === 'Extraneous Charge') {
            return charge.vendorFee.label;
        }

        return `${charge.service.type} Service Base Charge`;
    }

    function getLineItemGroupIndex(lineItem) {
        let groupIndex;

        switch(lineItem.type) {
            case 'Base Charge':
                groupIndex = 0;
                break;
            case 'Per Occurrence Charge':
                groupIndex = 1;
                break;
            case 'Per Unit Charge':
                groupIndex = 2;
                break;
            case 'Extraneous Charge':
                groupIndex = 3;
                break;
            default:
                groupIndex = 4;
                break;
        }

        return groupIndex;
    }

    function getAppliedSalesTaxSortIndex(appliedSalesTax) {
        return salesTaxes.value.find(({id}) => id === appliedSalesTax.line_item_sales_tax_id).sort_index;
    }

    function clearUnusedFieldsForType(form) {
        if (!lineItemTypeHasService(form.type)) {
            form.service_id = null;
        }

        if (!lineItemTypeHasVendorFee(form.type)) {
            form.vendor_fee_id = null;
        }

        if (!lineItemTypeHasWorkOrder(form.type)) {
            form.work_order_id = null;
        }

        if (form.type !== 'Allocatable Service Fee') {
            form.parent_charge_id = null;
        }

        if (form.type !== 'Sales Tax') {
            form.vendor_sales_tax_type_id = null;
        }
    }

    function archiveVendorInvoice() {
        if (state.value !== 'passive' || props.vendorInvoice.archived_at) {
            return;
        }

        state.value = 'archiving';

        router.post(route('vendor-invoices.archive', props.vendorInvoice.id), {}, {
                preserveScroll: true,
                onFinish: () => {
                    state.value = 'passive';
                }
            });
    }

    function unarchiveVendorInvoice() {
        if (state.value !== 'passive' || !props.vendorInvoice.archived_at) {
            return;
        }

        state.value = 'unarchiving';

        router.post(route('vendor-invoices.unarchive', props.vendorInvoice.id), {}, {
            preserveScroll: true,
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }

    function linkToServiceContract(lineItem) {
        if (lineItem.service?.vendor_contract_id) {
            window.open(route('vendor-contracts.show', lineItem.service.vendor_contract_id), '_blank');
        }
    }

    function getServicesByLocation(location) {
        let chargesByService = getChargesByLocation(location);
        return Object.values(chargesByService.reduce((carry, charge) => {
            if (charge.service && !Object.keys(carry).includes(charge.service.id)) {
                carry[charge.service.id] = charge.service;
            }

            return carry;
        }, {})).sort((a, b) => a.id.localeCompare(b.id));
    }

    function getChargesByLocation(location) {
        return charges.value.filter(charge => charge.service?.location_id === location.id);
    }

    function invoiceCrossesStateLines() {
        let locations = charges.value.filter(location => location !== null).map(charge => charge.service?.location);

        let uniqueStates = locations.reduce((carry, location) => {
            if (!carry.includes(`${location.address.country}_${location.address.state}`)) {
                carry.push(`${location.address.country}_${location.address.state}`);
            }

            return carry;
        }, [])

        return uniqueStates.length > 1;
    }

    function lineItemTypeHasService(type) {
        return type !== null
            && type !== 'Allocatable Fee'
            && type !== 'Allocatable Service Fee'
            && type !== 'Incomplete Allocatable Service Fee'
            && type !== 'Sales Tax';
    }

    function lineItemTypeHasVendorFee(type) {
        return ['Allocatable Fee', 'Extraneous Charge', 'Allocatable Service Fee'].includes(type);
    }

    function lineItemTypeHasWorkOrder(type) {
        return ['Commercial Service Charge', 'Rolloff Per Haul', 'Rolloff Per Ton', 'Extraneous Charge'].includes(type);
    }

    /**
     * Computed properties
     */
    const steps = computed(() => {
        let matchedSteps = [
            { name: 'Matched', description: 'Line items have been processed and matched to services.', state: 'current', action_state: 'reprocessing' },
            { name: 'Allocated', description: 'Fees and taxes have been allocated across charges.', state: 'upcoming', action_state: 'allocating' },
            { name: 'Audited', description: 'Line items have been audited against their contract.', state: 'upcoming', action_state: 'auditing' },
            { name: 'Approved', description: 'Invoice has been approved.', state: 'upcoming', action_state: 'approving' },
            { name: 'Posted', description: 'Invoice has been posted to accounting.', state: 'upcoming', action_state: 'posting' },
        ];

        let invalidAllocationSteps = [
            { name: 'Matched', description: 'Line items have been processed and matched to services.', state: 'complete', action_state: 'reprocessing' },
            { name: 'Invalid Allocation', description: 'Fees and taxes have been allocated across charges, but the invoice totals do not match.', state: 'current-warning', action_state: 'allocating' },
            { name: 'Audited', description: 'Line items have been audited against their contract.', state: 'upcoming', action_state: 'auditing' },
            { name: 'Approved', description: 'Invoice has been approved.', state: 'upcoming', action_state: 'approving' },
            { name: 'Posted', description: 'Invoice has been posted to accounting.', state: 'upcoming', action_state: 'posting' },
        ];

        let allocatedSteps = [
            { name: 'Matched', description: 'Line items have been processed and matched to services.', state: 'complete', action_state: 'reprocessing' },
            { name: 'Allocated', description: 'Fees and taxes have been allocated across charges.', state: 'current', action_state: 'allocating' },
            { name: 'Audited', description: 'Line items have been audited against their contract.', state: 'upcoming', action_state: 'auditing' },
            { name: 'Approved', description: 'Invoice has been approved.', state: 'upcoming', action_state: 'approving' },
            { name: 'Posted', description: 'Invoice has been posted to accounting.', state: 'upcoming', action_state: 'posting' },
        ];

        let auditedSteps = [
            { name: 'Matched', description: 'Line items have been processed and matched to services.', state: 'complete', action_state: 'reprocessing' },
            { name: 'Allocated', description: 'Fees and taxes have been allocated across charges.', state: 'complete', action_state: 'allocating' },
            { name: 'Audited', description: 'Line items have been audited against their contract.', state: 'current', action_state: 'auditing' },
            { name: 'Approved', description: 'Invoice has been approved.', state: 'upcoming', action_state: 'approving' },
            { name: 'Posted', description: 'Invoice has been posted to accounting.', state: 'upcoming', action_state: 'posting' },
        ];

        let approvedSteps = [
            { name: 'Matched', description: 'Line items have been processed and matched to services.', state: 'complete', action_state: 'reprocessing' },
            { name: 'Allocated', description: 'Fees and taxes have been allocated across charges.', state: 'complete', action_state: 'allocating' },
            { name: 'Audited', description: 'Line items have been audited against their contract.', state: 'complete', action_state: 'auditing' },
            { name: 'Approved', description: props.vendorInvoice.auto_approved ? 'Invoice has been auto approved.' : 'Invoice has been approved.', state: 'current', action_state: 'approving' },
            { name: 'Posted', description: 'Invoice has been posted to accounting.', state: 'upcoming', action_state: 'posting' },
        ];

        let revenueCheckFailureSteps = [
            { name: 'Matched', description: 'Line items have been processed and matched to services.', state: 'complete', action_state: 'reprocessing' },
            { name: 'Allocated', description: 'Fees and taxes have been allocated across charges.', state: 'complete', action_state: 'allocating' },
            { name: 'Audited', description: 'Line items have been audited against their contract.', state: 'complete', action_state: 'auditing' },
            { name: 'Approved', description: props.vendorInvoice.auto_approved ? 'Invoice has been auto approved.' : 'Invoice has been approved.', state: 'complete', action_state: 'approving' },
            { name: 'Revenue Check Failure', description: 'Unable to post invoice to accounting; No matching revenue was found.', state: 'current-warning', action_state: 'posting' },
        ];

        let postedSteps = [
            { name: 'Matched', description: 'Line items have been processed and matched to services.', state: 'complete', action_state: 'reprocessing' },
            { name: 'Allocated', description: 'Fees and taxes have been allocated across charges.', state: 'complete', action_state: 'allocating' },
            { name: 'Audited', description: 'Line items have been audited against their contract.', state: 'complete', action_state: 'auditing' },
            { name: 'Approved', description: props.vendorInvoice.auto_approved ? 'Invoice has been auto approved.' : 'Invoice has been approved.', state: 'complete', action_state: 'approving' },
            { name: 'Posted', description: 'Invoice has been posted to accounting.', state: 'complete', action_state: 'posting' },
        ];

        let paidSteps = [
            { name: 'Matched', description: 'Line items have been processed and matched to services.', state: 'complete', action_state: 'reprocessing' },
            { name: 'Allocated', description: 'Fees and taxes have been allocated across charges.', state: 'complete', action_state: 'allocating' },
            { name: 'Audited', description: 'Line items have been audited against their contract.', state: 'complete', action_state: 'auditing' },
            { name: 'Approved', description: props.vendorInvoice.auto_approved ? 'Invoice has been auto approved.' : 'Invoice has been approved.', state: 'complete', action_state: 'approving' },
            { name: 'Posted', description: 'Invoice has been posted to accounting.', state: 'complete', action_state: 'posting' },
            { name: 'Paid', description: 'Invoice has been paid.', state: 'complete', action_state: '' },
        ];

        let steps = {
            Matched: matchedSteps,
            'Invalid Allocation': invalidAllocationSteps,
            Allocated: allocatedSteps,
            Audited: auditedSteps,
            Approved: approvedSteps,
            'Revenue Check Failure': revenueCheckFailureSteps,
            Posted: postedSteps,
            Paid: paidSteps
        }

        if (usePage().props.permissions.trackVendorInvoicePaymentStatuses !== true) {
            steps = {
                Matched: matchedSteps.filter(step => !['Posted', 'Paid'].includes(step.name)),
                'Invalid Allocation': invalidAllocationSteps.filter(step => !['Posted', 'Paid'].includes(step.name)),
                Allocated: allocatedSteps.filter(step => !['Posted', 'Paid'].includes(step.name)),
                Audited: auditedSteps.filter(step => !['Posted', 'Paid'].includes(step.name)),
                Approved: approvedSteps.filter(step => !['Posted', 'Paid'].includes(step.name)),
                'Revenue Check Failure': revenueCheckFailureSteps.filter(step => !['Posted', 'Paid'].includes(step.name)),
            }
        }

        return steps[invoice.status];
    });

    const alerts = computed(() => {
        let alerts = [];

        if (props.services.length === 0) {
            alerts.push("Discovery can't find any active or recently terminated services for this account.");
        }

        if (parseInt(lineItemTotal.value.amount) !== parseInt(invoice.adjusted_total_current_charges.amount)) {
            alerts.push(`The sum of the line items on this invoice: ${filters.format_money(lineItemTotal.value)} doesn't equal the total current charges: ${filters.format_money(invoice.adjusted_total_current_charges)}`);
        }

        if (['Allocated', 'Invalid Allocation', 'Audited'].includes(props.vendorInvoice.status) &&
            lineItems.value.filter(lineItem => lineItem.type === 'Sales Tax').length > 0 &&
            invoiceCrossesStateLines()) {
            alerts.push('The line items on this invoice belong to locations in multiple states or provinces. Line item taxability will need to be manually adjusted.')
        }

        return alerts;
    });

    const lineItems = computed(() => {
        return invoice.lineItems
            .map((lineItem) => {
                lineItem.sort_index = lineItem.start_date + '_' + lineItem.id;
                return lineItem
            })
            .sort((a, b) => {
                if (a.sort_index > b.sort_index) {
                    return 1;
                } else if (a.sort_index < b.sort_index) {
                    return -1;
                }

                return 0;
            });
    });

    const filteredServiceOptions = computed(() => {
        return serviceComboBoxQuery.value === ''
            ? props.services
            : props.services.filter((service) => {
                return service.id.toLowerCase().includes(serviceComboBoxQuery.value.toLowerCase());
            });
    });

    const filteredBulkLineItemsServiceOptions = computed(() => {
        return bulkLineItemsServiceComboBoxQuery.value === ''
            ? props.services
            : props.services.filter((service) => {
                return service.id.toLowerCase().includes(bulkLineItemsServiceComboBoxQuery.value.toLowerCase());
            });
    });

    const lineItemRevisions = computed(() => {
        return lineItems
            .value
            .reduce((carry, lineItem) => [...carry, ...lineItem.revisions], []);
    });

    const progressPercentage = computed(() => {
        let registry = {
            Matched: '20%',
            Allocated: '40%',
            Audited: '60%',
            Approved: '80%',
            Posted: '90%',
            Paid: '100%',
        };

        if (usePage().props.permissions.trackVendorInvoicePaymentStatuses !== true) {
            registry = {
                Matched: '20%',
                Allocated: '40%',
                Audited: '70%',
                Approved: '100%',
            }
        }

        return registry[invoice.status];
    });

    const filteredWorkOrders = computed(() => {
        // return all workOrders filtered by the lineItemForm.servicel_id value
        let workOrders = props.workOrders.filter(workOrder => workOrder.service_id === lineItemForm.service_id);

        let mostAccurateEventDates = workOrders
            .map(workOrder => filters.format_date(workOrder.occurred_at ?? workOrder.scheduled_with_vendor_date ?? workOrder.requested_completion_date))
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => {
                return new Date(a) - new Date(b);
            });

        return mostAccurateEventDates.reduce((carry, date) => {
            carry[date] = workOrders.filter(workOrder => filters.format_date(workOrder.occurred_at ?? workOrder.scheduled_with_vendor_date ?? workOrder.requested_completion_date) === date)

            return carry;
        }, {});
    });

    const lineItemsByService = computed(() => {
        return lineItems
            .value
            .filter(lineItem => lineItem.service)
            .reduce((carry, serviceCharge) => {
                if (!carry.map(commercialService => commercialService.id).includes(serviceCharge.service_id)) {
                    carry.push(serviceCharge.service);
                }

                return carry;
            }, [])
            .map(service => {
                let regularCharges = lineItems.value
                    .filter(charge => charge.service_id === service.id && charge.type === 'Base Charge');

                let haulCharges = lineItems.value
                    .filter(charge => charge.service_id === service.id && ['Per Occurrence Charge'].includes(charge.type));

                let tonnageCharges = lineItems.value
                    .filter(charge => charge.service_id === service.id && ['Per Unit Charge'].includes(charge.type));

                let extraneousCharges = lineItems.value
                    .filter(charge => charge.service_id === service.id && charge.type === 'Extraneous Charge');

                let allocatableServiceFees = regularCharges.reduce((carry, charge) => {
                    carry = [...carry, ...charge.allocatableServiceFees];

                    return carry;
                }, []);

                return {service, regularCharges, haulCharges, tonnageCharges, extraneousCharges, allocatableServiceFees}
            })
            .sort((a, b) => a.service.id.localeCompare(b.service.id));
    });

    const incompleteLineItems = computed(() => {
        return lineItems
            .value
            .filter(lineItem => props.incompleteLineItemTypes.includes(lineItem.type))
            .filter(function (lineItem) {
                if (lineItem.type === 'Allocatable Service Fee') {
                    return !lineItem.parent_charge_id;
                } else {
                    return !lineItem.service_id;
                }
            });
    });

    const canBeAllocated = computed(() => {
        return lineItems.value.filter(lineItem => props.incompleteLineItemTypes.includes(lineItem.type)).length === 0
            && lineItems.value.filter(function (lineItem) {
                if (lineItem.type === 'Allocatable Service Fee') {
                    return lineItem.parent_charge_id === null;
                } else {
                    return lineItem.service_id === null;
                }
            }).length === 0
            && serviceCharges.value.filter(item => item.category === null).length === 0
            && extraneousCharges.value.filter(item => item.category === null).length === 0
            && hasValidInvoiceTotal.value
            && state.value === 'passive';
    });

    const canBeApproved = computed(() => {
        return invoice.adjusted_total_current_charges.amount === lineItemTotal && state.value === 'passive';
    });

    const lineItemTotal = computed(() => {
        let filteredLineItems = invoice.lineItems.filter(lineItem => lineItem.type !== 'Extra Data');

        return {
            amount: filteredLineItems.reduce((carry, lineItem) => carry + parseInt(lineItem.amount.amount), 0),
            currency: invoice.total_current_charges.currency,
        };
    });

    const hasValidInvoiceTotal = computed(() => {
        return lineItemTotal === parseInt(invoice.adjusted_total_current_charges.amount);
    });

    const lineItemHasService = computed(() => {
        return lineItemForm.type !== 'Allocatable Fee'
            && lineItemForm.type !== 'Allocatable Service Fee'
            && lineItemForm.type !== 'Sales Tax';
    });

    const lineItemHasVendorFee = computed(() => {
        return ['Allocatable Fee', 'Extraneous Charge', 'Allocatable Service Fee'].includes(lineItemForm.type);
    });

    const lineItemHasWorkOrder = computed(() => {
        return ['Base Charge', 'Per Occurrence Charge', 'Per Unit Charge', 'Extraneous Charge'].includes(lineItemForm.type);
    });


    const allocatableFees = computed(() => {
        return lineItems
            .value
            .filter(lineItem => lineItem.type === 'Allocatable Fee');
    });

    const salesTaxes = computed(() => {
        return lineItems
            .value
            .filter(lineItem => lineItem.type === 'Sales Tax');
    });

    const serviceCharges = computed(() => {
        return lineItems
            .value
            .filter(lineItem => ['Base Charge', 'Per Occurrence Charge', 'Per Unit Charge'].includes(lineItem.type));
    });

    const commercialServiceCharges = computed(() => {
        return lineItems
            .value
            .filter(lineItem => lineItem.type === 'Base Charge');
    });

    const rolloffServiceCharges = computed(() => {
        return lineItems
            .value
            .filter(lineItem => ['Per Occurrence Charge', 'Per Unit Charge'].includes(lineItem.type));
    });

    const extraneousCharges = computed(() => {
        return lineItems
            .value
            .filter(lineItem => lineItem.type === 'Extraneous Charge');
    });

    const charges = computed(() => {
        return lineItems
            .value
            .filter(lineItem => ['Extraneous Charge', 'Base Charge', 'Per Occurrence Charge', 'Per Unit Charge'].includes(lineItem.type));
    });

    const locations = computed(() => {
        return Object.values(charges.value.reduce((carry, charge) => {
            if (charge.service?.location && !Object.keys(carry).includes(charge.service.location.id)) {
                carry[charge.service.location.id] = charge.service.location;
            }

            return carry;
        }, {})).sort((a, b) => a.name.localeCompare(b.name));
    });

    const allocatedTotal = computed(() => {
        return {
            amount: allocations.value.reduce((carry, allocation) => carry + parseInt(allocation.all_in_cost.amount), 0),
            currency: invoice.total_current_charges.currency,
        }
    });

    const totalCostBasis = computed(() => {
        return {
            amount: charges.value.reduce((carry, charge) => carry + parseInt(charge.cost_basis.amount, 10), 0),
            currency: invoice.total_current_charges.currency,
        };
    });

    const filteredAutoApprovalErrors = computed(() => {
        if (Object.keys(props.vendorInvoice.auto_approval_errors).length !== 0) {
            // vendorFeeMissing is technical debt that we got rid of but may remain in
            // older invoices, we can remove this in a year as they should no longer be around after that long.
            const { line_item_errors, vendorFeeMissing, ...filteredErrors } = props.vendorInvoice.auto_approval_errors;

            return filteredErrors;
        }
        return [];
    });

    const indeterminate = computed(() => {
        return selectedLineItemIds.value.length > 0 && selectedLineItemIds.value.length < lineItems.value.length;
    });

    /**
     * Watchers
     */
    watch(() => props.vendorInvoice, (newValue, oldValue) => {
        Object.assign(invoice, newValue);

        initializeAllocationLineItems();
        initializeSalesTaxApplicationLineItems();
    });

    /**
     * Initializers
     */
    initializeAllocationLineItems();
    initializeSalesTaxApplicationLineItems();

    if (props.isReprocessing) {
        state.value = 'reprocessing';
    }

    Echo.join(`vendor_invoices.${invoice.id}`)
        // Presence
        .here((users) => {
            otherUsers.value = users;
        })
        .joining((user) => {
            otherUsers.value.push(user);
        })
        .leaving((user) => {
            otherUsers.value = otherUsers.value.filter(otherUser => otherUser.id !== user.id);
        })

        // Reprocessing
        .listen('.App\\Events\\OCRVendorInvoice\\ReprocessOCRVendorInvoiceJobHasStarted', (e) => {
            state.value = 'reprocessing';
        })
        .listen('.App\\Events\\OCRVendorInvoice\\ReprocessOCRVendorInvoiceJobHasFinished', (e) => {
            router.reload({
                preserveScroll: true,
                only: ['vendorInvoice'],
                onSuccess: () => {
                    state.value = 'passive';
                    toast.success('Invoice has been successfully reprocessed.');
                },
            })
        })
        .listen('.App\\Events\\OCRVendorInvoice\\ReprocessOCRVendorInvoiceJobHasFailed', (e) => {
            state.value = 'passive';
            processingErrorMessage.value = e.error_message;
            toast.error('Invoice reprocessing has failed.');
        });

    onMounted(() => {
        mounted.value = true;
    });

    onUnmounted(() => {
        Echo.leave(`vendor_invoices.${invoice.id}`);
    });
</script>
