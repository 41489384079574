<template>
    <Head title="Create Service Type" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="data.mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.service-types.index')" class="breadcrumb-link">Service Types</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="mx-auto max-w-screen-xl pb-16">
        <back-link :route="route('tenant-settings.service-types.index')" label="Back to Service Types" />

        <form @submit.prevent="submitForm" id="edit-service-type-form" class="mt-10">
            <div>
                <h2 class="text-xl/7 font-semibold text-gray-900">Service Type</h2>
                <p class="mt-1 text-lg/6 text-gray-500">Define how this service type will be displayed and identified.</p>
            </div>

            <hr class="border-1 my-6" />

            <div class="grid grid-cols-2 gap-6">
                <text-input
                    v-model="form.name"
                    class="col-span-2 md:col-span-1"
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Residential Trash Pickup"
                    :errors="props.errors.name"
                    mark-as-required
                />

                <text-input
                    v-model="form.type_description"
                    class="col-span-2 md:col-span-1"
                    id="type_description"
                    name="type_description"
                    label="Description"
                    placeholder="Normal trash pickup service for residential customers"
                    :errors="props.errors.type_description"
                />
            </div>

            <div class="mt-16">
                <h2 class="text-xl/7 font-semibold text-gray-900">Service Details</h2>
                <p class="mt-1 text-lg/6 text-gray-500">Specify some details regarding services belonging to this type.</p>
            </div>

            <hr class="border-1 my-8" />

            <div class="grid grid-cols-2 gap-6">
                <text-input
                    v-model="form.service_id_prefix"
                    class="col-span-2 md:col-span-1"
                    id="service_id_prefix"
                    name="service_id_prefix"
                    label="Service ID Prefix"
                    placeholder="RTP-"
                    tooltip="This value will be used to prefix the generated Service ID. Entering a value like 'RTP-' will produce Service IDs like 'RTP-1234'."
                    help-text="Maximum of 11 alphanumeric characters, can include hyphens."
                    :errors="props.errors.service_id_prefix"
                    mark-as-required
                />

                <select-input v-model="form.line_item_category_id" class="col-span-2 md:col-span-1" id="line_item_category_id" name="line_item_category_id" label="Line Item Category" :errors="props.errors.line_item_category_id" mark-as-required >
                    <option value="">-- Select a category --</option>
                    <option v-for="category in props.lineItemCategories" :key="category.id" :value="category.id">
                        {{ category.display_name }}
                    </option>
                </select-input>

                <text-input-with-variables
                    v-model="form.service_description.value"
                    class="col-span-2"
                    label="Service Description"
                    tooltip="This format will be used when generating the service description for the service everywhere it is displayed."
                    :variables="descriptionVariables"
                    :help-text="data.previews.service_description"
                    :errors="props.errors.service_description"
                    mark-as-required
                />

                <select-input v-model="form.waste_generation_type" class="col-span-1" id="waste_generation_type" name="waste_generation_type" label="Waste Generation Type" :errors="props.errors.waste_generation_type" mark-as-required>
                    <option value="">-- Select a waste generation type --</option>
                    <option v-for="(decorated, type) in props.wasteGenerationTypes" :key="type" :value="type">{{ decorated }}</option>
                </select-input>

                <ul class="col-span-2 divide-y divide-gray-200 sm:grid sm:grid-cols-2 sm:divide-y-0 sm:gap-x-12">
                    <li class="flex items-center justify-between py-4">
                        <div class="flex flex-col" :class="{'opacity-50': isGeneratingWaste()}">
                            <span class="text-base/6 font-medium text-gray-900">Services will use a Bin</span>
                            <p class="mt-1 text-base/6 text-gray-500">Enable collection metrics and equipment details.</p>
                        </div>
                        <div>
                            <toggle-switch-input class="col-span-2" v-model="form.bin" :disabled="isGeneratingWaste()" />
                        </div>
                    </li>

                    <li class="flex items-center justify-between py-4">
                        <div class="flex flex-col" :class="{'opacity-50': isGeneratingWaste()}">
                            <span class="text-base/6 font-medium text-gray-900">Services will be on a schedule</span>
                            <p class="mt-1 text-base/6 text-gray-500">Allows scheduling of the service.</p>
                        </div>
                        <div>
                            <toggle-switch-input class="col-span-2" v-model="form.service_schedule_info" :disabled="isGeneratingWaste()" />
                        </div>
                    </li>

                    <li class="flex items-center justify-between py-4">
                        <div class="flex flex-col">
                            <span class="text-base/6 font-medium text-gray-900">Include a base/flat charge</span>
                            <p class="mt-1 text-base/6 text-gray-500">Services can have a standard fee for each invoice.</p>
                        </div>
                        <div>
                            <toggle-switch-input class="md:col-span-3 col-span-6" v-model="form.base_charges" />
                        </div>
                    </li>

                    <li class="flex items-center justify-between py-4">
                        <div class="flex flex-col">
                            <span class="text-base/6 font-medium text-gray-900">Include per unit charges</span>
                            <p class="mt-1 text-base/6 text-gray-500">Services can have a per unit charge.</p>
                        </div>
                        <div>
                            <toggle-switch-input class="col-span-2" v-model="form.per_unit_charges" />
                        </div>
                    </li>

                    <li class="flex items-center justify-between py-4">
                        <div class="flex flex-col">
                            <span class="text-base/6 font-medium text-gray-900">Include per occurrence charges</span>
                            <p class="mt-1 text-base/6 text-gray-500">Services can have a per occurrence charge.</p>
                        </div>
                        <div>
                            <toggle-switch-input class="col-span-2" v-model="form.per_occurrence_charges" />
                        </div>
                    </li>
                </ul>
            </div>

            <div class="mt-16" v-if="$can('accessRevenueManagement')">
                <h2 class="text-xl/7 font-semibold text-gray-900">Client Invoicing Details</h2>
                <p class="mt-1 text-lg/6 text-gray-500">Specify some details regarding client invoice line items on services of this type.</p>

                <hr class="border-1 my-8" />

                <div class="flex flex-col gap-6">
                    <text-input-with-variables
                        v-model="form.client_line_item_header_label.value"
                        class="col-span-2"
                        label="Client Invoice Service Description"
                        tooltip="This format will be used when grouping line items on the client invoice."
                        :variables="descriptionVariables"
                        :errors="props.errors.client_line_item_header_label"
                        :help-text="data.previews.client_line_item_header_label"
                        :mark-as-required="form.client_line_item_header_label.required"
                    />

                    <text-input-with-variables
                        v-if="form.base_charges"
                        v-model="form.client_line_item_description.value"
                        label="Client Invoice Base Charge Description"
                        :variables="descriptionVariables"
                        :errors="props.errors.client_line_item_description"
                        :help-text="data.previews.client_line_item_description"
                        mark-as-required
                    />
                </div>
            </div>

            <div class="flex flex-row justify-end mt-16">
                <loading-button :loading="form.state === 'saving'" class="btn btn-orange">
                    Submit
                </loading-button>
            </div>
        </form>
    </div>
</template>

<script setup>
    // Vue/Inertia Imports
    import { router, Head } from '@inertiajs/vue3';
    import { reactive, inject, onMounted, watch } from 'vue';

    // Package Imports
    import axios from 'axios';
    import { debounce } from 'lodash-es';

    // Components
    import Icon from '@/Shared/Icon.vue';
    import BackLink from '@/Shared/BackLink.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ToggleSwitchInput from "@/Shared/ToggleSwitchInput.vue";
    import TextInputWithVariables from "@/Shared/TextInputWithVariables.vue";

    // Inject
    const route = inject('route');

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },
        descriptionVariables: {
            type: Array,
            required: true
        },
        lineItemCategories: {
            type: Array,
            required: true
        },
        wasteGenerationTypes: {
            type: Object,
            required: true
        }
    });

    const fieldValues = {
        bin: false,
        base_charges: false,
        per_unit_charges: false,
        service_schedule_info: false,
        per_occurrence_charges: false,
    };

    // State
    const form = reactive({
        state: 'idle',
        name: '',
        type_description: '',
        service_id_prefix: '',
        line_item_category_id: '',
        waste_generation_type: '',
        service_description: {
            value: '({{bin.quantity}}) {{bin.size}} {{bin.size_metric}} {{bin.type}} {{bin.material_type}} picked up {{pickup.schedule}} {{pickup.by}}',
            required: true
        },
        client_line_item_description: {
            value: '',
            required: false
        },
        client_line_item_header_label: {
            value: '({{bin.quantity}}) {{bin.size}} {{bin.size_metric}} {{bin.type}} {{bin.material_type}} picked up {{pickup.schedule}} {{pickup.by}}',
            required: false
        },
        ...fieldValues
    });

    const data = reactive({
        mounted: false,
        previews: {
            service_description: '(3) 4 yards Front Load MSW picked up weekly on Monday, Wednesday and Friday by Waste Management',
            client_line_item_header_label: '(3) 4 yards Front Load MSW picked up weekly on Monday, Wednesday and Friday by Waste Management',
            client_line_item_description: '',
        },
        generatableHeaderLabelWords: {
            base_charges: 'Standard',
            service_schedule_info: 'Scheduled',
            bin: 'Disposal',
            default: 'Service',
        }
    });

    // Mount
    onMounted(() => {
        data.mounted = true;
    });

    // Methods
    function submitForm() {
        if (form.state !== 'idle') {
            console.info('Form cannot be resubmitted');
            return;
        }

        form.state = 'saving';

        // Verify the user wants to use the example description if the service description is empty
        if(form.service_description.value === '' && !window.confirm('The service description cannot be empty. Would you like to use the example description?')) {
            console.info('User declined use of the example description. Form submission cancelled.');
            form.state = 'idle';
            return;
        }

        // Service description will be assigned if the user accepted the example description prompt
        form.service_description.value = form.service_description.value === ''
            ? '({{bin.quantity}}) {{bin.size}} {{bin.size_metric}} {{bin.type}} {{bin.material_type}} picked up {{pickup.schedule}} {{pickup.by}}'
            : form.service_description.value;

        // Because we can have a base charge description, and then they can toggle it off, we need to set the descriptiont to null
        form.client_line_item_description.value = form.base_charges
            ? form.client_line_item_description.value
            : null;

        // because we don't want to send the server an object, override the object value with the value property
        let formData = {
            ...form,
            service_description: form.service_description.value,
            client_line_item_description: form.client_line_item_description.value,
            client_line_item_header_label: form.client_line_item_header_label.value,
        };

        router.post(route('tenant-settings.service-types.store'), formData, {
            preserveScroll: true,
            onFinish: () => {
                form.state = 'idle'
            },
        });
    }

    function isGeneratingWaste() {
        return ['metered', 'unmetered'].includes(form.waste_generation_type);
    }

    function baseChargeDescriptionIsCustom() {
        let label = form.client_line_item_description.value.trim();
        let labelWords = label.split(' ');

        let wordCountIsWithinGeneratableRange = labelWords.length <= Object.values(data.generatableHeaderLabelWords).length;
        let containsOnlyGeneratableWords = labelWords.every(word => Object.values(data.generatableHeaderLabelWords).includes(word));

        // If the label is not empty AND...
        // the word count is less than or equal to 4 AND does not contain only words from the generatableHeaderLabelWords object
        // OR the word count is greater than 4.
        return label.length > 0 && (
            (wordCountIsWithinGeneratableRange && !containsOnlyGeneratableWords) ||
            !wordCountIsWithinGeneratableRange
        );
    }

    function buildBaseChargeDescription() {
        if(baseChargeDescriptionIsCustom()) { // don't override a user's custom base charge description
            console.info('Base Charge Description is custom, skipping generation');
            return;
        }

        let headerLabel = form.client_line_item_description.value;
        let headerLabelWords = headerLabel.split(' ');
        let currentLabel = headerLabel.length > 0
            ? headerLabelWords
            : [];

        // Gather the words we'll use based on what toggles are enabled
        let enabledLabels = [
            form.base_charges ? data.generatableHeaderLabelWords.base_charges : null,
            form.service_schedule_info ? data.generatableHeaderLabelWords.service_schedule_info : null,
            form.bin ? data.generatableHeaderLabelWords.bin : null,
        ].filter(word => word !== null);

        // Add the default word to the end of the list if it isn't empty
        if(enabledLabels.length > 0) {
            enabledLabels.push(data.generatableHeaderLabelWords.default);
        }

        form.client_line_item_description.value = enabledLabels.join(' ');
    }

    // Watchers
    watch(() => form.waste_generation_type, (newValue) => {
        form.bin = isGeneratingWaste();
        form.service_schedule_info = isGeneratingWaste();
    });


    watch(() => form.service_description.value, debounce((newValue) => {
       axios.get(route('tenant-settings.json.service-types.preview', {'template': newValue}))
            .then((response) => {
                data.previews.service_description = response.data.value;
                form.client_line_item_header_label.value = form.service_description.value;
            });
    }, 300));

    watch(() => form.client_line_item_header_label.value, debounce((newValue) => {
        axios.get(route('tenant-settings.json.service-types.preview', {'template': newValue}))
            .then((response) => {
                data.previews.client_line_item_header_label = response.data.value;
            });
    }, 300));

    watch(() => form.client_line_item_description.value, debounce((newValue) => {
        axios.get(route('tenant-settings.json.service-types.preview', {'template': newValue}))
            .then((response) => {
                data.previews.client_line_item_description = response.data.value;
            });
    }, 300));

    watch(() => form.base_charges, (newValue) => {
        form.client_line_item_description.required = newValue;
        buildBaseChargeDescription();
    });

    watch(() => form.bin, (newValue) => {
        buildBaseChargeDescription();
    });

    watch(() => form.service_schedule_info, (newValue) => {
        buildBaseChargeDescription();
    });
</script>
