<template>
    <Head :title="`Edit Service Creation - ${serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav aria-label="breadcrumbs" class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit Service Creation</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg">
        <div class="md:px-12 max-w-screen-xl mx-auto">
            <div class="flex justify-between items-center my-6">
                <h1 class="text-2xl font-bold">Edit Service Creation</h1>

                <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="btn btn-gray gap-x-2">
                    Cancel Edit
                </inertia-link>
            </div>

            <div class="rounded-md bg-blue-50 p-4 text-base my-6">
                <div class="flex items-center">
                    <div class="flex-shrink-0">
                        <icon name="info-circle" class="size-5 text-blue-400 fill-current" />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-blue-700">Please Note: Service creation will be backdated to the effective date if the effective date is in the past.</p>
                    </div>
                </div>
            </div>

            <div class="rounded-md bg-gray-100 p-4 my-3" v-if="hasApprovedInvoiceLineItems">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <icon name="info-circle" class="size-5 text-gray-400 fill-current" />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-sm text-gray-700">This service has vendor invoices attached to it that have already been approved. Some fields are unable to be edited.</p>
                    </div>
                </div>
            </div>

            <fieldset class="border-t border-gray-300">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Service
                </legend>

                <div v-if="hasApprovedInvoiceLineItems" class="text-gray-500 text-center space-y-2 p-4 bg-gray-50 rounded-lg my-2">
                    <icon name="info-circle" class="mx-auto size-12 text-gray-400 fill-current"/>
                    <p class="text-gray-500">This service has vendor invoices attached to it that have already been approved. The location can no longer be edited.</p>
                </div>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                    <select-input class="md:col-span-3" v-model="form.type" label="Service Type" disabled markAsRequired>
                        <option :value="serviceActivity.serviceSnapshot.type" :key="serviceActivity.serviceSnapshot.type" selected>{{ serviceActivity.serviceSnapshot.type }}</option>
                    </select-input>

                    <revert-input class="md:col-span-3" label="Location" v-model="form.location_id" mark-as-required :disabled="hasApprovedInvoiceLineItems">
                        <search-input
                            v-model="form.location_id"
                            display-property="name"
                            route="json.locations.index"
                            placeholder="Search"
                            :route-params="{always_include: form.vendor_id, sort_by: 'name', sort_direction: 'asc', per_page: 50}"
                            :default-value="form.location"
                            :errors="errors['location_id']"
                            :force-valid="true"
                            @selected=locationSelected
                            markAsRequired
                            :disabled="hasApprovedInvoiceLineItems"
                        >
                            <template #searchResult="obj">
                                <span class="block mb-1">{{ obj.result.name }}</span>
                                <span class="block text-sm font-bold">{{ obj.result.clientCompany.name }}</span>
                            </template>
                        </search-input>
                    </revert-input>

                    <revert-input class="md:col-span-3" label="Effective Date" v-model="form.effective_date">
                        <date-input v-model="form.effective_date" :max="nextServiceActivity?.actual_date ? nextServiceActivity.actual_date : ''" placeholder="Effective Date" help-text="Service creation date" :errors="errors['effective_date']" @blur="syncEffectiveDateWithPickupStartDate" />
                    </revert-input>

                    <revert-input class="md:col-span-3" label="External Reference Id" v-model="form.external_reference_id">
                        <text-input v-model="form.external_reference_id" :errors="errors.external_reference_id" />
                    </revert-input>
                </div>
            </fieldset>

            <fieldset class="mt-12 border-t border-gray-300">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Vendor
                </legend>

                <div v-if="hasApprovedInvoiceLineItems" class="text-gray-500 text-center space-y-2 p-4 bg-gray-50 rounded-lg my-2">
                    <icon name="info-circle" class="mx-auto size-12 text-gray-400 fill-current"/>
                    <p class="text-gray-500">This service has vendor invoices attached to it that have already been approved. The vendor information can no longer be edited.</p>
                </div>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                    <revert-input class="col-span-1 md:col-span-2" label="Vendor" v-model="form.vendor_id" mark-as-required :disabled="hasApprovedInvoiceLineItems">
                        <search-input
                            v-model="form.vendor_id"
                            display-property="name"
                            route="json.vendors.index"
                            placeholder="Search"
                            :errors="errors['vendor_id']"
                            :default-value="form.vendor"
                            @selected="(vendor) => form.vendor = vendor"
                            :disabled="hasApprovedInvoiceLineItems"
                        >
                            <template #searchResult="obj">
                                <span class="block mb-1">{{ obj.result.name }}</span>
                            </template>
                        </search-input>
                    </revert-input>

                    <revert-input class="col-span-1 md:col-span-2" label="Vendor Account" v-model="form.vendor_account_id" mark-as-required :disabled="hasApprovedInvoiceLineItems">
                        <div v-if="form.vendor_id && !vendorAccounts.length" class="py-2 text-gray-700">No accounts available for this vendor</div>
                        <select-input-2 v-else v-model="form.vendor_account_id" :options="vendorAccounts" :errors="errors['vendor_account_id']" :disabled="hasApprovedInvoiceLineItems">
                            <template #option-search="vendorAccount">{{ vendorAccount.account_number }}</template>
                            <template #option-label="vendorAccount">{{ vendorAccount.account_number }}{{ vendorAccount.status ? ' - ' + vendorAccount.status : '' }}</template>
                            <template #option-display="{option, selected}">
                                <div class="flex justify-between items-center">
                                    <div class="flex items-center justify-between flex-1 pr-4">
                                        <span class="font-medium">{{ option.account_number }}</span>
                                        <span class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                            {{ option.status }}
                                        </span>
                                    </div>
                                    <div v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-d-orange-500">
                                        <icon name="check" class="size-5 inline fill-current" />
                                    </div>
                                </div>
                            </template>
                        </select-input-2>
                    </revert-input>

                    <revert-input class="col-span-1 md:col-span-2" label="Vendor Contract" v-model="form.vendor_contract_selection" :disabled="hasApprovedInvoiceLineItems">
                        <select-input-2 v-model="form.vendor_contract_selection" :options="vendorContracts" :errors="errors['vendor_contract_selection']" :disabled="hasApprovedInvoiceLineItems">
                            <template #option-search="contract">{{ contract.display_id }}</template>
                            <template #option-label="contract">{{ contract.display_id }}{{ contract.status ? ' - ' + contract.status : '' }}</template>
                            <template #option-display="{option, selected}">
                                <div class="flex justify-between items-center">
                                    <div class="flex items-center justify-between flex-1 pr-4">
                                        <template v-if="!option.id">
                                            <div class="flex flex-col">
                                                <span class="font-medium">{{ option.display_id }}</span>
                                                <span class="text-sm text-gray-600">There is no contract for this vendor.</span>
                                            </div>
                                        </template>
                                        <template v-else-if="option.id === 'missing-contract'">
                                            <div class="flex flex-col">
                                                <span class="font-medium">{{ option.display_id }}</span>
                                                <span class="text-sm text-gray-600">Contract is unknown or missing.</span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="flex flex-col">
                                                <span class="font-medium">{{ option.display_id }}</span>
                                                <span class="text-sm text-gray-600">{{ option.status }}</span>
                                            </div>
                                            <div class="flex-shrink-0 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                {{ option.effective_date }}
                                            </div>
                                        </template>
                                    </div>
                                    <div v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-d-orange-500">
                                        <icon name="check" class="size-5 inline fill-current" />
                                    </div>
                                </div>
                            </template>
                        </select-input-2>
                    </revert-input>

                    <revert-input
                        v-if="serviceType.enabled_type_fields.includes('base_charges')"
                        class="col-span-1 md:col-span-2"
                        label="Vendor Base Charge"
                        v-model="form.vendor_base_charge"
                    >
                        <money-input
                            v-model="form.vendor_base_charge"
                            placeholder="Vendor Base Charge"
                            :defaultCurrency="$page.props.currency"
                            :errors="errors['vendor_base_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="serviceType.enabled_type_fields.includes('per_unit_charges')"
                        class="col-span-1 md:col-span-2"
                        label="Vendor Per Unit Charge"
                        v-model="form.vendor_per_unit_charge"
                    >
                        <money-input
                            v-model="form.vendor_per_unit_charge"
                            placeholder="Vendor Per Unit Charge"
                            :help-text="perTonHelpText"
                            :defaultCurrency="$page.props.currency"
                            :errors="errors['vendor_per_unit_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="serviceType.enabled_type_fields.includes('per_occurrence_charges')"
                        class="col-span-1 md:col-span-2"
                        label="Vendor Per Occurrence Charge"
                        v-model="form.vendor_per_occurrence_charge"
                    >
                        <money-input
                            v-model="form.vendor_per_occurrence_charge"
                            placeholder="Vendor Per Occurrence Charge"
                            help-text="(Haul Amount)"
                            :defaultCurrency="$page.props.currency"
                            :errors="errors['vendor_per_occurrence_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="serviceType.enabled_type_fields.includes('units_included_per_occurrence')"
                        class="col-span-1 md:col-span-2"
                        label="Units Included Per Occurrence"
                        v-model="form.units_included_per_occurrence"
                    >
                        <text-input
                            v-model="form.units_included_per_occurrence"
                            type="number"
                            min="0"
                            help-text="(Tons Included Per Haul)"
                            :errors="errors['units_included_per_occurrence']"
                        />
                    </revert-input>
                </div>

                <div class="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                    <revert-input
                        v-model="form.service_account_type"
                        class="col-span-1 md:col-span-3"
                        label="Service Account Type"
                        mark-as-required
                    >
                        <select-input v-model="form.service_account_type" :errors="errors['service_account_type']">
                            <option>Permanent</option>
                            <option>Temporary</option>
                        </select-input>
                    </revert-input>

                    <revert-input
                        v-if="serviceType.enabled_type_fields.includes('service_schedule_info')"
                        v-model="form.service_schedule_type"
                        class="col-span-1 md:col-span-3"
                        label="Service Schedule Type"
                        mark-as-required
                    >
                        <select-input v-model="form.service_schedule_type" :errors="errors['service_schedule_type']" markAsRequired>
                            <option>Scheduled</option>
                            <option>On Call</option>
                            <option v-if="form.service_account_type==='Temporary'">One Time</option>
                        </select-input>
                    </revert-input>

                    <revert-input
                        v-if="serviceType.enabled_type_fields.includes('service_schedule_info') && form.service_schedule_type === 'Scheduled'"
                        v-model="form.pickup_rrule"
                        class="col-span-1 md:col-span-6"
                        label="Schedule"
                        mark-as-required
                    >
                        <div class="flex flex-col items-start gap-6 lg:gap-12 mt-2 md:flex-row">
                            <div class="md:flex-shrink-0">
                                <div class="flex flex-col gap-3">
                                    <span>{{ rrule_description }}</span>
                                    <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500">
                                        The pickup schedule is invalid
                                    </div>
                                    <a class="link cursor-pointer text-base inline-flex items-center gap-1" @click.prevent="showUpdatePickupRRuleModal">
                                        Edit Schedule <icon name="edit" class="inline fill-current w-5 h-6"></icon>
                                    </a>
                                </div>
                            </div>

                            <div class="w-full md:flex-1" v-if="form?.pickup_rrule?.freq === 'WEEKLY'">
                                <div class="w-full overflow-hidden rounded-lg grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                                    <div v-for="(day, id) in daysOfWeek" :key="id" class="w-full group relative text-center px-2">
                                        <div class="text-base border-b pb-1">{{ day }}</div>
                                        <div class="flex justify-center items-center p-4 pb-8 lg:pb-0">
                                            <icon name="check" class="text-green-500 fill-current size-4" v-if="form?.pickup_rrule?.byday?.includes(id)"/>
                                            <icon name="times" class="text-gray-300 fill-current size-4" v-else />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </revert-input>
                </div>
            </fieldset>

	        <fieldset v-if="serviceType.enabled_type_fields.includes('bin')" class="mt-12 border-t border-gray-300">
		        <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Bin
                </legend>

		        <div class="w-full mt-4 px-4">
			        <revert-input class="md:w-1/3" label="Equipment Label" v-model="form.label">
				        <text-input v-model="form.label" maxlength="50" :errors="errors['label']"/>
			        </revert-input>
		        </div>

                <div class="px-4">
                    <revertible-bin
                        :property-type="serviceActivity.serviceSnapshot.location?.property_type"
                        :bin-types="binTypes"
                        :material-types="materialTypes"
                        :asset-owners="assetOwners"
                        v-model="form.bin"
                        :errors="errors"
                        :fields-are-required="serviceActivity.serviceSnapshot.status === 'Active'"
                    />
                </div>
            </fieldset>

            <fieldset v-if="$page.props.permissions.accessRevenueManagement" class="mt-12 border-t border-gray-300">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Client
                </legend>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                    <revert-input class="md:col-span-3" label="Client Contract" v-model="form.client_contract_id">
                        <select-input-2 v-model="form.client_contract_id" :options="clientContracts" :errors="errors['client_contract_id']">
                            <template #option-label="option">{{ option.id }}</template>
                            <template #option-display="{option, selected}">
                                <div :class="{'font-semibold': selected}" class="block">
                                    <div>{{ option.id }}</div>
                                    <div class="text-xs">Integration: {{ option.integration_date }}</div>
                                </div>
                                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                    <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                                </span>
                            </template>
                        </select-input-2>
                    </revert-input>

                    <revert-input class="md:col-span-3" label="Is Pass Through?" v-model="form.is_pass_through" mark-as-required>
                        <select-input v-model="form.is_pass_through" :errors="errors['is_pass_through']">
                            <option :value="null">Unsure</option>
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select-input>
                    </revert-input>

                    <revert-input
                        v-if="form.is_pass_through === false"
                        v-model="form.client_billing_frequency"
                        class="md:col-span-3"
                        label="Client Billing Frequency"
                        mark-as-required
                    >
                        <select-input-2
                            v-model="form.client_billing_frequency"
                            :options="clientBillingFrequencies"
                            :errors="errors['client_billing_frequency']"
                        ></select-input-2>
                    </revert-input>

                    <revert-input
                        v-if="this.serviceType.enabled_type_fields.includes('base_charges') && form.is_pass_through === false && form.client_billing_frequency !== 'Not Billable'"
                        class="md:col-span-3"
                        label="Client Base Charge"
                        v-model="form.client_base_charge"
                    >
                        <money-input
                            v-model="form.client_base_charge"
                            placeholder="Client Base Charge"
                            :defaultCurrency="$page.props.currency"
                            :errors="errors['client_base_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="this.serviceType.enabled_type_fields.includes('per_unit_charges') && form.is_pass_through === false && form.client_billing_frequency !== 'Not Billable'"
                        class="md:col-span-3"
                        label="Client Per Unit Charge"
                        v-model="form.client_per_unit_charge"
                    >
                        <money-input
                            v-model="form.client_per_unit_charge"
                            placeholder="Client Per Unit Charge"
                            :help-text="perTonHelpText"
                            :defaultCurrency="$page.props.currency"
                            :errors="errors['client_per_unit_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="this.serviceType.enabled_type_fields.includes('per_occurrence_charges') && form.is_pass_through === false && form.client_billing_frequency !== 'Not Billable'"
                        class="md:col-span-3"
                        label="Client Per Occurrence Charge"
                        v-model="form.client_per_occurrence_charge"
                    >
                        <money-input
                            v-model="form.client_per_occurrence_charge"
                            placeholder="Client Per Occurrence Charge"
                            help-text="(Haul Amount)"
                            :defaultCurrency="clientCurrency"
                            :errors="errors['client_per_occurrence_charge']"
                        />
                    </revert-input>
                </div>
            </fieldset>

            <template v-if="showPriorPeriodAdjustment">
                <div class="my-10 px-4">
                    <div class="rounded-md bg-yellow-50 p-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <icon name="exclamation-triangle" class="size-5 text-yellow-400 fill-current" />
                            </div>
                            <div class="ml-3 flex-1 text-base">
                                <h3 class="font-medium text-yellow-800">Prior Period Adjustment</h3>
                                <div class="mt-2 text-yellow-700">
                                    <div class="pr-2">
                                        You are changing the price from
                                        <span class="font-bold">{{ $filters.format_money(serviceActivity.serviceSnapshot.client_base_charge) }}</span> to
                                        <span class="font-bold">{{ $filters.format_money(form.client_base_charge) }}</span> effective
                                        <span class="font-bold">{{ $filters.format_date(form.effective_date) }}</span>.
                                        <span v-if="priorPeriodAdjustment.lastBilledThru !== null">
                                            This service has already been billed through the end of
                                            <span class="font-bold">{{ priorPeriodAdjustment.lastBilledThru }}</span>.
                                        </span>
                                        <p class="mt-1">If you do not wish to bill a prior period adjustment for this change, please enter in $0.00 below and no charge will be made.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="my-4">
                        <money-input v-model="priorPeriodAdjustment.proratedAmount" label="Prorated Amount" :defaultCurrency="$page.props.currency" />
                    </div>

                    <div class="my-4">
                        <textarea-input v-model="priorPeriodAdjustment.description" label="Line Item Description" />
                    </div>
                </div>
            </template>

            <div class="relative mt-12">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
                </div>
            </div>

            <div class="px-4 mt-4 mb-8">
                <textarea-input class="w-full" rows="4" :errors="errors['internal_change_notes']" label="Internal Change Notes" v-model="internal_change_notes" markAsRequired />
            </div>

            <loading-button :loading="saving" class="btn btn-orange ml-3" @click="replaceServiceCreation">
                Submit Change
            </loading-button>
        </div>

        <!-- Pickup RRule Modal -->
        <modal v-if="serviceType.enabled_type_fields.includes('service_schedule_info')" class="max-w-2xl text-base" ref="updatePickupRRuleModal" tabindex="-1" role="dialog">
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-400 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Schedule
                    </div>

                    <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                        <icon name="times" class="fill-current inline w-6 h-6" />
                    </button>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Avg. Pickups Per Week"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    import axios from "axios";

    import Icon from '@/Shared/Icon.vue';
    import Modal from '@/Shared/Modal.vue';
    import { Head } from '@inertiajs/vue3';
    import DateInput from '@/Shared/DateInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import RRuleInput from '@/Shared/RRuleInput.vue';
    import RevertInput from '@/Shared/RevertInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import SelectInput2 from '@/Shared/SelectInput2.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import RevertibleBin from "@/Shared/Services/RevertibleBin.vue";
    import {watch} from "vue";

    export default {
        components: {
            Icon,
            Head,
            Modal,
            DateInput,
            TextInput,
            MoneyInput,
            Pagination,
            RRuleInput,
            RevertInput,
            SearchInput,
            SelectInput,
            SelectInput2,
            LoadingButton,
            RevertibleBin,
            TextareaInput,
            ToggleSwitchInput,
        },

        props: {
            assetOwners: {
                type: Array,
                required: true
            },

            binTypes: {
                type: Array,
                required: true
            },

            clientBillingFrequencies: {
                type: Array,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },

            initialClientContracts: {
                type: Array,
                default: []
            },

            initialVendorAccounts: {
                type: Array,
                default: []
            },

            initialVendorContracts: {
                type: Array,
                default: []
            },

            materialTypes: {
                type: Array,
                required: true
            },

            nextServiceActivity: {
                type: Object,
                default: null
            },

            serviceActivity: {
                type: Object,
                required: true
            },

            serviceType: {
                type: Object,
                required: true
            },

            hasApprovedInvoiceLineItems: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                form: {...this.serviceActivity.serviceSnapshot, bin: {...this.serviceActivity.serviceSnapshot.bin}, location: {...this.serviceActivity.serviceSnapshot.location}, vendor: {...this.serviceActivity.serviceSnapshot.vendor}},
                saving: false,
                steps: [
                    { id: 1, name: 'Service Type and Location', href: '#', status: 'current' },
                    { id: 2, name: 'Service Details', href: '#', status: 'upcoming' },
                ],
                activeStep: 1,
                clientContracts: [],
                internal_change_notes: null,
                vendorAccounts: [],
                vendorContracts: [],
                mounted: false,
                missingVendorContract: {id: 'missing-contract', display_id: "Missing contract"},
                noVendorContract: {id: null, display_id: 'No contract'},
                rrule_description: null,
                priorPeriodAdjustment: null,
                daysOfWeek: {
                    'SU': 'Sunday',
                    'MO': 'Monday',
                    'TU': 'Tuesday',
                    'WE': 'Wednesday',
                    'TH': 'Thursday',
                    'FR': 'Friday',
                    'SA': 'Saturday',
                },
            }
        },

        methods: {
            showUpdatePickupRRuleModal() {
                this.$refs.updatePickupRRuleModal.show();
            },

            replaceServiceCreation() {
                this.saving = true;

                let route = this.$route('services.service-creation-changes.update', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id});

                this.$inertia
                    .put(route, {
                        ...this.form,
                        internal_change_notes: this.internal_change_notes,
                        ppa_amount: this.showPriorPeriodAdjustment ? this.priorPeriodAdjustment.value?.proratedAmount : null,
                        ppa_description: this.showPriorPeriodAdjustment ? this.priorPeriodAdjustment.value?.description : null,
                    }, {
                        onFinish: () => this.saving = false
                    });
            },

            syncEffectiveDateWithPickupStartDate() {
                this.$refs.rRuleInput.setStartDate(this.form.effective_date);
            },

            locationSelected(location) {
                this.form.location = location;

                if (!location) {
                    this.clientContracts = [];

                    return;
                }

                // this is for reverting the search box
                if (location.id === this.serviceActivity.serviceSnapshot.location_id) {
                    this.form.location = {...this.serviceActivity.serviceSnapshot.location}
                }

                if (this.$page.props.permissions.accessRevenueManagement) {
                    axios.get(this.$route('json.client-contracts.index', {client_company_id: this.form.location.client_company_id})).then(response => {
                        this.clientContracts = response.data.data;
                    })
                } else {
                    this.clientContracts = [];
                }
            },

            suggestPriorPeriodAdjustment() {
                const conditions = [
                    this.$page.props.permissions.accessRevenueManagement,
                    !this.form.is_pass_through,
                    ['Monthly', 'Quarterly', 'Yearly'].includes(this.form.client_billing_frequency),
                    this.form.service_schedule_type !== 'One Time',
                    this.serviceType.enabled_type_fields.includes('base_charges'),
                    this.form.client_base_charge !== this.serviceActivity.serviceSnapshot.client_base_charge,
                ];

                return conditions.every(condition => condition === true);
            },

            updatePriorPeriodAdjustmentDetails() {
                const adjusted_amount = this.form.client_base_charge.amount / 100;
                const change_date = this.form.effective_date;
                const service_id = this.serviceActivity.service_id;

                let url = `/json/services/${service_id}/prior-period-adjustments/create-from-price-change?change_date=${change_date}&adjusted_amount=${adjusted_amount}`;
                axios.get(url).then(response => {
                    this.priorPeriodAdjustment = response.data;
                });
            },
        },

        watch: {
			'form.vendor_contract_selection'(newContract, oldContract) {
                this.form.vendor_contract_id = newContract === 'missing-contract'
                    ? null
                    : newContract;
			},

            'form.location_id'(newLocation, oldLocation) {
                this.form.client_contract_id = null;
                this.form.clientContract = null;

                if (!newLocation) {
                    this.locationSelected(null);
                }
            },

            'form.vendor_id'() {
                this.form.vendor_account_id = null;
                this.form.vendor_contract_id = null;

                if (this.form.vendor_id) {
                    axios.get(this.$route('json.vendor-accounts.index', {vendor_id: this.form.vendor_id, per_page: 500})).then(response => {
                        this.vendorAccounts = response.data.data;
                    })

                    axios.get(this.$route('json.vendor-contracts.index', {vendor_id: this.form.vendor_id, per_page: 500})).then(response => {
                        this.vendorContracts = [this.noVendorContract, this.missingVendorContract, ...response.data.data];
                    })
                } else {
                    this.vendorAccounts = [];
                    this.vendorContracts = [];
                }

                // this is for reverting the search box
                if (this.form.vendor_id === this.serviceActivity.serviceSnapshot.vendor_id) {
                    this.form.vendor = {...this.serviceActivity.serviceSnapshot.vendor}
                }
            },

	        'form.is_pass_through'(newValue, oldValue) {
                if (newValue === true) {
                    this.form.client_billing_frequency = null;
                    this.form.client_base_charge = null;
                    this.form.client_per_unit_charge = null;
                    this.form.client_per_occurrence_charge = null;

                    return;
                }

                if (! this.serviceType.enabled_type_fields.includes('base_charges')) {
                    this.form.client_billing_frequency = 'Per Vendor Invoice';
                }
	        },

            'form.client_billing_frequency' (newValue, oldValue) {
                if (newValue === 'Not Billable') {
                    this.form.client_base_charge = null;
                    this.form.client_per_unit_charge = null;
                    this.form.client_per_occurrence_charge = null;
                }
            },

            'form': {
                handler() {
                    if (this.suggestPriorPeriodAdjustment()) {
                        this.updatePriorPeriodAdjustmentDetails();
                    }
                },
                deep: true
            },
        },

        mounted() {
            this.clientContracts = [...this.initialClientContracts];
            this.vendorAccounts = [...this.initialVendorAccounts];
            this.vendorContracts = [this.noVendorContract, this.missingVendorContract, ...this.initialVendorContracts];
            this.mounted = true;
        },

        computed: {
            perTonHelpText() {
                return this.serviceType.enabled_type_fields.includes('per_unit_charges') ?
                    " (Disposal Amount Per Ton)" :
                    "";
            },

            showPriorPeriodAdjustment() {
                return this.suggestPriorPeriodAdjustment()
                    && this.priorPeriodAdjustment?.proratedAmount?.amount
                    && this.priorPeriodAdjustment?.proratedAmount?.amount != 0;
            }
        }
    }
</script>
